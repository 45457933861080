import {observer} from "mobx-react";
import {useRootStore} from "../../../../../core/stores/root-store";
import {
    MediaLibraryDialogs,
    useMediaLibraryStore
} from "../../../../../core/stores/media-library-store";
import MediaLibraryApi from "../../../../../core/api/media-library-api";
import {useCallback, useEffect, useReducer, useState} from "react";
import {BusyStates} from "../../../../../core/utilities/busy-states";
import {
    DeletedMediaDirectoryTreeResult,
    DirectoryBreadcrumb,
    MediaItemResult
} from "@ews-turing-models/clientmodels/dist";
import {ApiAsset, ClassNames, SanitizeHtml} from "../../../../../core/utilities/react-utils";
import {Alert, Button, Classes, Colors, Dialog, Intent, Tree, TreeNodeInfo} from "@ewstheme/core";
import {BiTrash} from "react-icons/bi";
import EwsGrid from "../../../../shared/ews-grid/components/ews-grid";
import EwsGridItem from "../../../../shared/ews-grid/components/ews-grid-item";
import styles from "../../styles.module.scss";
import {getIconClassFromExtension} from "../../helpers/file-icon-helpers";
import {cloneDeep} from "lodash";
import mediaLibraryApi from "../../../../../core/api/media-library-api";
import If from "../../../../shared/utilities/if-component";
import EwsHeading from "../../../../shared/ews-typography/ews-heading";
import {EwsText} from "../../../../shared/ews-typography/ews-text";
import ApiUtil from "../../../../../core/utilities/api-util";
import {SortByProperty} from "../../../../../core/utilities/sorting-util";

type NodePath = number[];

type TreeAction =
    | { type: "SET_IS_EXPANDED"; payload: { path: NodePath; isExpanded: boolean } }
    | { type: "DESELECT_ALL" }
    | { type: "REFRESH"; payload: { nodes: TreeNodeInfo[] } }
    | { type: "SET_SELECTED"; payload: { path: NodePath } };

function forEachNode(nodes: TreeNodeInfo[] | undefined, callback: (node: TreeNodeInfo) => void) {
    if (nodes === undefined) {
        return;
    }

    for (const node of nodes) {
        callback(node);
        forEachNode(node.childNodes, callback);
    }
}

function forNodeAtPath(nodes: TreeNodeInfo[], path: NodePath, callback: (node: TreeNodeInfo) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

function treeReducer(state: TreeNodeInfo[], action: TreeAction) {
    switch (action.type) {
        case "DESELECT_ALL":
            const newState1 = cloneDeep(state);
            forEachNode(newState1, node => (node.isSelected = false));
            return newState1;
        case "SET_IS_EXPANDED":
            const newState2 = cloneDeep(state);
            forNodeAtPath(newState2, action.payload.path, node => (
                node.isExpanded = action.payload.isExpanded,
                    node.icon = <span className={ClassNames([
                        "fiv-ext",
                        `fiv-icon-folder-classic${action.payload.isExpanded ? '-open' : ''}`,
                        styles.DirectoryTreeIcon
                    ])}></span>
            ));
            return newState2;
        case "REFRESH":
            return action.payload.nodes;
        case "SET_SELECTED":
            const newState3 = cloneDeep(state);
            forEachNode(newState3, node => node.isSelected = false);
            forNodeAtPath(newState3, action.payload.path, node => (node.isSelected = true))
            return newState3
        default:
            return state;
    }
}

const DeletedFolderInfoDialog: React.FC = () => {
    const {busyStore, toastStore} = useRootStore();
    const {store} = useMediaLibraryStore();
    const [data, setData] = useState<DeletedMediaDirectoryTreeResult | undefined>(undefined);
    const [selectedTree, setSelectedTree] = useState<string | undefined>(undefined);
    const [previewData, setPreviewData] = useState<MediaItemResult | undefined>(undefined);
    const [breadcrumb, setBreadcrumb] = useState<DirectoryBreadcrumb[] | undefined>(undefined);
    const [permanentDeleteDialog, setPermanentDeleteDialog] = useState(false);
    
    const handleFetch = () => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        let promises: any[] = [];
        const folderTree = MediaLibraryApi.getDeletedFolderTree({directoryId: store.deletedFolderView!})
            .then(result => {
                setData(result.model);
            });
        promises.push(folderTree);

        const folderData = MediaLibraryApi.getDeletedFolder({directoryId: store.deletedFolderView!})
            .then(result => {
                setBreadcrumb(result.model.breadcrumb)
            });
        promises.push(folderData);

        Promise.all(promises)
            .then(() => {
                busyStore.removeBusyState(BusyStates.MediaLibrary);
            })
    }

    const handleClose = () => {
        store.clearDeletedFolderView();
        store.closeDialog();
    }

    useEffect(() => {
        if (store.currentDialog === MediaLibraryDialogs.DeletedFolderInfo && store.deletedFolderView) {
            handleFetch();
        }
    }, [store.deletedFolderView, store.currentDialog]);

    const mapDirectoryTreeItem = (item: DeletedMediaDirectoryTreeResult) => {
        const output: TreeNodeInfo = {
            id: `${item.directoryId};Directory`,
            label: <span className={ClassNames([
                item.isHidden ? styles.Hidden : "",
            ])}>{item.directoryName.replace("ROOT", "Home")}</span>,
            hasCaret: item.childDirectories.length > 0,
            icon: <span className={ClassNames([
                "fiv-ext",
                `fiv-icon-folder-classic${item.directoryName === "ROOT" ? "-open" : ""}`,
                styles.DirectoryTreeIcon,
                item.isHidden ? styles.Hidden : ''
            ])}></span>,
            childNodes: item.childDirectories.map((subItem: DeletedMediaDirectoryTreeResult) => {
                return mapDirectoryTreeItem(subItem)
            }).concat(item.mediaItems.map(subItem => {
                return mapFileTreeItem(subItem)
            })),
            isExpanded: item.directoryId === data?.directoryId,
            isSelected: item.directoryId === selectedTree?.split(";")[0] && selectedTree?.split(";")[1] === "Directory",
        }

        return output;
    }

    const mapFileTreeItem = (item: MediaItemResult) => {
        const output: TreeNodeInfo = {
            id: `${item.mediaItemID};File`,
            label: <span>{item.title}</span>,
            hasCaret: false,
            icon: <span className={ClassNames([
                "fiv-cla",
                getIconClassFromExtension(item.mediaType === "EmbeddedObject" ? "html" : item.fileExtensions?.toLowerCase() ?? ""),
                styles.DirectoryTreeIcon,
            ])}></span>,
            isSelected: item.mediaItemID === selectedTree?.split(";")[0] && selectedTree?.split(";")[1] === "File"
        }

        return output;
    }

    const initialState: TreeNodeInfo[] = data !== undefined ? [mapDirectoryTreeItem(data)] : [];

    const [nodes, dispatch] = useReducer(treeReducer, initialState);

    const handleTreeUpdate = () => {
        if (data) {
            dispatch({
                payload: {nodes: [mapDirectoryTreeItem(data)]},
                type: "REFRESH"
            });
        }
    }

    useEffect(() => {
        if (data) {
            handleTreeUpdate();
            setSelectedTree(undefined);
        }
    }, [data]);

    const loadMediaItemPreview = async (mediaId: string) => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        mediaLibraryApi.getMediaItem({mediaId: mediaId})
            .then(result => {
                setPreviewData(result.model);
            }).finally(() => {
            busyStore.removeBusyState(BusyStates.MediaLibrary);
        });
    }

    useEffect(() => {
        if (selectedTree && selectedTree.split(";")[1] === "File") {
            loadMediaItemPreview(selectedTree.split(";")[0])
                .then(() => {
                })
        } else {
            setPreviewData(undefined);
        }
    }, [selectedTree])

    const handleNodeClick = useCallback(
        (node: TreeNodeInfo, nodePath: NodePath, e: React.MouseEvent<HTMLElement>) => {

            if (store.currentDirectory?.directoryId !== node.id.toString()) {
                setSelectedTree(node.id.toString());
                dispatch({
                    payload: {path: nodePath},
                    type: "SET_SELECTED"
                });
            }
        },
        [],
    );

    const handleNodeCollapse = useCallback((_node: TreeNodeInfo, nodePath: NodePath) => {
        dispatch({
            payload: {path: nodePath, isExpanded: false},
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleNodeExpand = useCallback((_node: TreeNodeInfo, nodePath: NodePath) => {
        dispatch({
            payload: {path: nodePath, isExpanded: true},
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleDownload = () => {
        switch (previewData?.mediaType) {
            case "Document":
                ApiUtil.getDownload(
                    `/files/${previewData.mediaItemID}`,
                    `${previewData.title}-${previewData.mediaItemID}.${previewData.fileExtensions}`,
                    false);
                break;
            case "Image":
                ApiUtil.getDownload(
                    `/images/original/${previewData.mediaItemID}`,
                    `${previewData.title}-${previewData.mediaItemID}.${previewData.fileExtensions}`,
                    false);
                break;
        }
    }

    const handleRestore = () => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        MediaLibraryApi.restoreDirectories({
            mediaItemIds: [data!.directoryId]
        }).then(() => {
            handleClose();
            if (store.currentDirectory) {
                store.setDirectory(store.currentDirectory.directoryId);
            }
            toastStore.showToast({
                message: "Folder successfully restored",
                intent: "success",
                icon: "tick"
            });
        }).finally(() => {
            busyStore.removeBusyState(BusyStates.MediaLibrary);
        })
    }
    
    const handlePermanentDeletion = () => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        MediaLibraryApi.permanentlyDeleteFolder({
            itemId: data!.directoryId
        }).then(() => {
            handleClose();
            if (store.currentDirectory) {
                store.setDirectory(store.currentDirectory.directoryId);
            }
            toastStore.showToast({
                message: "Folder permanently deleted successfully",
                intent: "success",
                icon: "tick"
            });
        }).finally(() => {
            busyStore.removeBusyState(BusyStates.MediaLibrary);
        })
    }

    if (!data) return null;

    return (
        <>
            <Dialog
                className={ClassNames(["dialog-form", "dialog-form--primary", "dialog-form-medium"])}
                isOpen={store.currentDialog === MediaLibraryDialogs.DeletedFolderInfo}
                icon={<BiTrash className={ClassNames(["box-icon", "box-icon-dialog"])}/>}
                title={"Deleted Folder Info"}
                onClose={handleClose}
            >
                <div className={Classes.DIALOG_BODY}>
                    <EwsGrid>
                        <EwsGridItem span={12}>
                            <div style={{display: "flex", flexDirection: "row", gap: "0.5rem", alignItems: "center"}}>
                                <div>
                                    <EwsHeading
                                        variant={"h5"}
                                        intent={"primary"}
                                        text={"Folder Location"}
                                        style={{margin: 0}}
                                    />
                                </div>
                                <div>
                                    /{breadcrumb?.sort(SortByProperty("-levelFromChild")).filter(x => x.directoryId !== data.directoryId).map(x => x.directoryName.replace("ROOT", "Home")).join("/")}
                                </div>
                            </div>
                        </EwsGridItem>
                        <EwsGridItem span={4}>
                            <div className={styles.DirectoryTreeView}>
                                <Tree
                                    className={styles.DirectoryTreeContainer}
                                    contents={nodes}
                                    onNodeClick={handleNodeClick}
                                    onNodeExpand={handleNodeExpand}
                                    onNodeCollapse={handleNodeCollapse}
                                />
                            </div>
                        </EwsGridItem>
                        <EwsGridItem span={8}>
                            <If condition={previewData !== undefined}>
                                <div style={{display: "flex", flexDirection: "column", gap: "0.5rem", height: "100%"}}>
                                    <div>
                                        <EwsGrid>
                                            <EwsGridItem span={6}>
                                                <EwsHeading variant={"h5"} intent={"primary"} text={"Title"}/>
                                                <EwsText>
                                                    <>{previewData?.title}</>
                                                </EwsText>
                                            </EwsGridItem>
                                            <EwsGridItem span={6}>
                                                <EwsHeading variant={"h5"} intent={"primary"} text={"Description"}/>
                                                <EwsText>
                                                    <>{previewData?.description}</>
                                                </EwsText>
                                            </EwsGridItem>
                                        </EwsGrid>
                                    </div>
                                    <div style={{flexGrow: 1}}>
                                        <div className={ClassNames(
                                            [
                                                styles.DeletedMediaPreview,
                                                previewData?.mediaType === "EmbeddedObject" ? styles.Embedded : ""
                                            ])}>
                                            <div className={styles.DeletedMediaPreviewWrapper}>
                                                <If condition={previewData?.mediaType === "EmbeddedObject"}>
                                                    {SanitizeHtml(previewData?.embedCode ?? "")}
                                                </If>
                                                <If condition={previewData?.mediaType === "Image"}>
                                                    <img src={ApiAsset(`/images/original/${previewData?.mediaItemID}`)}
                                                         loading={"lazy"} alt={previewData?.title}/>
                                                    <div className={styles.ImageDownload}>
                                                        <Button
                                                            icon={"download"}
                                                            text={"Download"}
                                                            onClick={handleDownload}
                                                        />
                                                    </div>
                                                </If>
                                                <If condition={previewData?.mediaType === "Document"}>
                                                    <div>
                                                        <div>
                                           <span className={ClassNames(
                                               [
                                                   styles.MediaViewPreviewIcon,
                                                   "fiv-cla",
                                                   getIconClassFromExtension(previewData?.fileExtensions?.toLowerCase() ?? "")
                                               ])}
                                           />
                                                        </div>
                                                        <div style={{marginTop: "0.5rem", textAlign: "center"}}>
                                                            <Button
                                                                icon={"download"}
                                                                text={"Download"}
                                                                onClick={handleDownload}
                                                            />
                                                        </div>
                                                    </div>
                                                </If>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </If>
                        </EwsGridItem>
                    </EwsGrid>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={"form-action-container"}>
                        <Button
                            type={"button"}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            type={"button"}
                            intent={"primary"}
                            onClick={handleRestore}
                        >
                            Restore
                        </Button>
                        <Button
                            type={"button"}
                            intent={"danger"}
                            onClick={() => setPermanentDeleteDialog(true)}
                        >
                            Permanantly Delete
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Alert
                cancelButtonText={"Cancel"}
                confirmButtonText={"Confirm permanent deletion"}
                icon={"trash"}
                intent={Intent.DANGER}
                isOpen={permanentDeleteDialog}
                onCancel={() => setPermanentDeleteDialog(false)}
                onConfirm={handlePermanentDeletion}
            >
                <EwsHeading variant={"h4"} intent={"danger"} text={"Are you sure you want to permanently delete this folder?"} />
                <EwsText textType={"running"}>
                    <p>All files and folders within this folder will be permanently deleted and any links to the files will become broken.</p>
                    <p style={{color: Colors.RED3, textTransform: "uppercase", fontWeight: 600, textDecoration: "underline"}}>
                        This action cannot be undone!
                    </p>
                </EwsText>
            </Alert>
        </>
    )
}

export default observer(DeletedFolderInfoDialog);