export const AppPaths: any ={
    Home: {
        Path: "/",
        Url: '/'
    },
    MediaLibrary: {
        Path: "/media-library",
        Url: "/media-library"
    },
    Login:{
        Path: "/login",
        Url: '/login',
        ResetPassword: {
            Path: "reset-password",
            Url: "/login/reset-password"
        }
    },
    System:{
        Path: "/system",
        Url: "/system",
        RestrictedRoles: ["SysAdmin","UserMgmt"],
        Users: {
            Path: "users",
            Url: "/system/users",
            RestrictedRoles: ["SysAdmin","UserMgmt"]
        },
        MediaStorageInfo:{
            Path: "media-info",
            Url: "/system/media-info",
            RestrictedRoles: ["SysAdmin"]
        }
    },
    AccessDenied: {
        Path: "/access-denied",
        Url: "/access-denied"
    },
    DisabledFeature: {
        Path: "/feature-unavailable",
        Url: "/feature-unavailable"
    },
    ServerError: {
        Path: "/server-error",
        Url: "/server-error"
    },
    NotFound: {
        Path: "/not-found",
        Url: "/not-found"
    }
}