import { MediaItemResult, UpdateMediaItemRequest } from "@ews-turing-models/clientmodels/dist";
import {observer} from "mobx-react";
import * as Yup from "yup";
import {FormikHelpers, FormikValues} from "formik";
import {BusyStates} from "../../../../../core/utilities/busy-states";
import mediaLibraryApi from "../../../../../core/api/media-library-api";
import {BusyStore} from "../../../../../core/stores/busy-store";
import {useRootStore} from "../../../../../core/stores/root-store";
import {EwsFormErrorHandler} from "../../../../shared/ews-form/handlers/EwsFormErrorHandler";
import EwsForm from "../../../../shared/ews-form";
import EwsGrid from "../../../../shared/ews-grid/components/ews-grid";
import EwsGridItem from "../../../../shared/ews-grid/components/ews-grid-item";
import EwsInput from "../../../../shared/ews-form/components/ews-input";
import EwsTextarea from "../../../../shared/ews-form/components/ews-textarea";
import {Button, Intent} from "@ewstheme/core";
import If from "../../../../shared/utilities/if-component";

interface UpdateMediaItemFormProps {
    mediaItem: MediaItemResult;
    updatedCallback: (result: MediaItemResult) => void;
}

const UpdateMediaItemForm : React.FC<UpdateMediaItemFormProps> = props => {
    const {busyStore, toastStore} = useRootStore();
    
    const model: UpdateMediaItemRequest = {
        mediaItemId: props.mediaItem.mediaItemID,
        title: props.mediaItem.title,
        description: props.mediaItem.description ?? "",
        embedCode: props.mediaItem.mediaType === "EmbeddedObject" ? props.mediaItem.embedCode : ""
    }
    
    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        embedCode: Yup.string().test('validation-check','Embed code is required',
            (value, context) => {
                if(props.mediaItem.mediaType === "EmbeddedObject" && !value){
                    return false;
                }
                return true;
            })
    });

    const onSubmit = (values: (FormikValues & UpdateMediaItemRequest), actions: FormikHelpers<FormikValues & UpdateMediaItemRequest>) => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        mediaLibraryApi.updateMediaItem(values)
            .then(result => {
                props.updatedCallback(result.model);
                busyStore.removeBusyState(BusyStates.MediaLibrary);
                toastStore.showToast({
                    message: "Media item was create successfully",
                    icon: "tick",
                    intent: "success"
                });
            })
            .catch(error => {
                busyStore.removeBusyState(BusyStates.MediaLibrary);
                EwsFormErrorHandler(error);
            })
    }
    
    return (
        <EwsForm<UpdateMediaItemRequest>
            initialValues={model}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            fullHeight={true}
        >
            {formProps => (
                <div style={{height: "100%", display:"flex", flexDirection:"column"}}>
                        <div>
                            <EwsInput
                                label={"Title"}
                                placeholder={"Media title"}
                                required={true}
                                formikName={"title"}
                            />
                        </div>
                        <div >
                            <EwsTextarea
                                label={"Description"}
                                placeholder={"Description about the media"}
                                formikName={"description"}
                                rows={4}
                            />
                        </div>
                        <If condition={props.mediaItem.mediaType === "EmbeddedObject"}>
                            <div>
                                <EwsTextarea
                                    label={"Embed Code"}
                                    placeholder={"<!--Your embed code -->"}
                                    formikName={"embedCode"}
                                    rows={8}
                                />
                            </div>
                        </If>
                    <div style={{textAlign: "right", marginTop: "auto"}}>
                        <Button
                            type={"submit"}
                            intent={Intent.PRIMARY}
                            disabled={!formProps.isValid}>
                            Update
                        </Button>
                    </div>
                </div>
            )}
        </EwsForm>
    )
}

export default observer(UpdateMediaItemForm);