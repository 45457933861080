import {observer} from "mobx-react";
import {useRootStore} from "../../../../../core/stores/root-store";
import {MediaLibraryDialogs, useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import {ApiAsset, ClassNames, SanitizeHtml} from "../../../../../core/utilities/react-utils";
import {BiTrash} from "react-icons/bi";
import {Alert, Button, Classes, Colors, Dialog, Intent} from "@ewstheme/core";
import {useEffect, useState} from "react";
import {MediaItemResult} from "@ews-turing-models/clientmodels/dist";
import {BusyStates} from "../../../../../core/utilities/busy-states";
import mediaLibraryApi from "../../../../../core/api/media-library-api";
import EwsGrid from "../../../../shared/ews-grid/components/ews-grid";
import EwsGridItem from "../../../../shared/ews-grid/components/ews-grid-item";
import EwsHeading from "../../../../shared/ews-typography/ews-heading";
import {SortByProperty} from "../../../../../core/utilities/sorting-util";
import {EwsText} from "../../../../shared/ews-typography/ews-text";
import styles from "../../styles.module.scss";
import If from "../../../../shared/utilities/if-component";
import {getIconClassFromExtension} from "../../helpers/file-icon-helpers";
import ApiUtil from "../../../../../core/utilities/api-util";
import MediaLibraryApi from "../../../../../core/api/media-library-api";

const DeletedFileInfoDialog : React.FC = props => {
    const {busyStore, toastStore} = useRootStore();
    const {store} = useMediaLibraryStore();

    const [previewData, setPreviewData] = useState<MediaItemResult | undefined>(undefined);
    const [permanentDeleteDialog, setPermanentDeleteDialog] = useState(false);

    const loadMediaItem = async() => {
        if(store.deletedFileView && store.currentDialog === MediaLibraryDialogs.DeletedFileInfo){
            busyStore.setBusyState(BusyStates.MediaLibrary);
            
            mediaLibraryApi.getMediaItem({mediaId: store.deletedFileView})
                .then(result => {
                    setPreviewData(result.model);
                }).finally(() => {
                busyStore.removeBusyState(BusyStates.MediaLibrary);
            });
        }
    }
    
    useEffect(() => {
        loadMediaItem();
    },[store.deletedFileView, store.currentDialog])
    
    const handleClose = () => {
        store.clearDeletedFileView();
        store.closeDialog();
        setPreviewData(undefined);
    }

    const handleDownload = () => {
        switch (previewData?.mediaType) {
            case "Document":
                ApiUtil.getDownload(
                    `/files/${previewData.mediaItemID}`,
                    `${previewData.title}-${previewData.mediaItemID}.${previewData.fileExtensions}`,
                    false);
                break;
            case "Image":
                ApiUtil.getDownload(
                    `/images/original/${previewData.mediaItemID}`,
                    `${previewData.title}-${previewData.mediaItemID}.${previewData.fileExtensions}`,
                    false);
                break;
        }
    }

    const handleRestore = () => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        MediaLibraryApi.restoreMediaItems({
            mediaItemIds: [previewData!.mediaItemID]
        }).then(() => {
            handleClose();
            if (store.currentDirectory) {
                store.setDirectory(store.currentDirectory.directoryId);
            }
            toastStore.showToast({
                message: "File successfully restored",
                intent: "success",
                icon: "tick"
            });
        }).finally(() => {
            busyStore.removeBusyState(BusyStates.MediaLibrary);
        })
    }

    const handlePermanentDeletion = () => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        MediaLibraryApi.permanentlyDeleteFile({
            itemId: previewData!.mediaItemID
        }).then(() => {
            handleClose();
            if (store.currentDirectory) {
                store.setDirectory(store.currentDirectory.directoryId);
            }
            toastStore.showToast({
                message: "File permanently deleted successfully",
                intent: "success",
                icon: "tick"
            });
        }).finally(() => {
            busyStore.removeBusyState(BusyStates.MediaLibrary);
        })
    }
    
    if(!previewData) return null;
    
    return (
        <>
            <Dialog
                className={ClassNames(["dialog-form", "dialog-form--primary"])}
                isOpen={store.currentDialog === MediaLibraryDialogs.DeletedFileInfo}
                icon={<BiTrash className={ClassNames(["box-icon", "box-icon-dialog"])}/>}
                title={"Deleted File Info"}
                onClose={handleClose}
            >
                <div className={Classes.DIALOG_BODY}>
                    <EwsGrid>
                        <EwsGridItem span={12}>
                            <div style={{display: "flex", flexDirection: "row", gap: "0.5rem", alignItems: "center"}}>
                                <div>
                                    <EwsHeading
                                        variant={"h5"}
                                        intent={"primary"}
                                        text={"Folder Location"}
                                        style={{margin: 0}}
                                    />
                                </div>
                                <div>
                                    /{previewData.breadcrumb?.sort(SortByProperty("-levelFromChild")).map(x => x.directoryName.replace("ROOT", "Home")).join("/")}
                                </div>
                            </div>
                        </EwsGridItem>
                        <EwsGridItem span={12}>
                            <div style={{display: "flex", flexDirection: "column", gap: "0.5rem", height: "100%"}}>
                                <div>
                                    <EwsGrid>
                                        <EwsGridItem span={6}>
                                            <EwsHeading variant={"h5"} intent={"primary"} text={"Title"}/>
                                            <EwsText>
                                                <>{previewData?.title}</>
                                            </EwsText>
                                        </EwsGridItem>
                                        <EwsGridItem span={6}>
                                            <EwsHeading variant={"h5"} intent={"primary"} text={"Description"}/>
                                            <EwsText>
                                                <>{previewData?.description}</>
                                            </EwsText>
                                        </EwsGridItem>
                                    </EwsGrid>
                                </div>
                                <div style={{flexGrow: 1}}>
                                    <div className={ClassNames(
                                        [
                                            styles.DeletedMediaPreview,
                                            previewData?.mediaType === "EmbeddedObject" ? styles.Embedded : ""
                                        ])}
                                         style={{height: 400}}
                                    >
                                        <div className={styles.DeletedMediaPreviewWrapper}>
                                            <If condition={previewData?.mediaType === "EmbeddedObject"}>
                                                {SanitizeHtml(previewData?.embedCode ?? "")}
                                            </If>
                                            <If condition={previewData?.mediaType === "Image"}>
                                                <img src={ApiAsset(`/images/original/${previewData?.mediaItemID}`)}
                                                     loading={"lazy"} alt={previewData?.title}/>
                                                <div className={styles.ImageDownload}>
                                                    <Button
                                                        icon={"download"}
                                                        text={"Download"}
                                                        onClick={handleDownload}
                                                    />
                                                </div>
                                            </If>
                                            <If condition={previewData?.mediaType === "Document"}>
                                                <div>
                                                    <div>
                                           <span className={ClassNames(
                                               [
                                                   styles.MediaViewPreviewIcon,
                                                   "fiv-cla",
                                                   getIconClassFromExtension(previewData?.fileExtensions?.toLowerCase() ?? "")
                                               ])}
                                           />
                                                    </div>
                                                    <div style={{marginTop: "0.5rem", textAlign: "center"}}>
                                                        <Button
                                                            icon={"download"}
                                                            text={"Download"}
                                                            onClick={handleDownload}
                                                        />
                                                    </div>
                                                </div>
                                            </If>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </EwsGridItem>
                    </EwsGrid>
                </div>
                <div className={Classes.DIALOG_FOOTER}>
                    <div className={"form-action-container"}>
                        <Button
                            type={"button"}
                            onClick={handleClose}
                        >
                            Close
                        </Button>
                        <Button
                            type={"button"}
                            intent={"primary"}
                            onClick={handleRestore}
                        >
                            Restore
                        </Button>
                        <Button
                            type={"button"}
                            intent={"danger"}
                            onClick={() => setPermanentDeleteDialog(true)}
                        >
                            Permanantly Delete
                        </Button>
                    </div>
                </div>
            </Dialog>
            <Alert
                cancelButtonText={"Cancel"}
                confirmButtonText={"Confirm permanent deletion"}
                icon={"trash"}
                intent={Intent.DANGER}
                isOpen={permanentDeleteDialog}
                onCancel={() => setPermanentDeleteDialog(false)}
                onConfirm={handlePermanentDeletion}
            >
                <EwsHeading variant={"h4"} intent={"danger"} text={"Are you sure you want to permanently delete this file?"} />
                <EwsText textType={"running"}>
                    <p>Any links to the files will become broken.</p>
                    <p style={{color: Colors.RED3, textTransform: "uppercase", fontWeight: 600, textDecoration: "underline"}}>
                        This action cannot be undone!
                    </p>
                </EwsText>
            </Alert>
        </>
    )
}

export default observer(DeletedFileInfoDialog);