import {observer} from "mobx-react";
import {Alignment, Intent} from "@ewstheme/core";
import EwsDialog, {EwsDialogForwardedRef} from "../../../shared/ews-dialog";
import EwsForm from "../../../shared/ews-form";
import {ChangePasswordRequest} from "@ews-turing-models/clientmodels/dist";
import * as Yup from 'yup';
import {FormikHelpers, FormikValues, FormikProps} from "formik";
import EwsPasswordInput from "../../../shared/ews-form/components/ews-password-input";
import {useRef, useState} from "react";
import ProfileApi from "../../../../core/api/profile-api";
import BusyIndicator from "../../../shared/busy-indicator";
import {useRootStore} from "../../../../core/stores/root-store";
import {EwsFormErrorHandler} from "../../../shared/ews-form/handlers/EwsFormErrorHandler";
import {BusyStates} from "../../../../core/utilities/busy-states";

const ChangePasswordDialog : React.FC = () => {
    const [isFormValid, setIsFormValid] = useState<boolean>(false);
    const {busyStore, toastStore} = useRootStore();
    const formRef = useRef<FormikProps<FormikValues & ChangePasswordRequest>>(null);
    const dialogRef = useRef<EwsDialogForwardedRef>(null);
    
    const changePasswordModel : ChangePasswordRequest = {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    }

    const validationSchema = Yup.object().shape({
        oldPassword: Yup.string().required("Old password is required"),
        newPassword: Yup.string().required("New password is required"),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], "Password must match")
    });
    
    const handleSubmit = (values: (FormikValues & ChangePasswordRequest), actions: FormikHelpers<FormikValues & ChangePasswordRequest>) => {
        busyStore.setBusyState(BusyStates.ChangePasswordForm);
        ProfileApi.changePassword(values)
            .then(() => {
                if(dialogRef.current){
                    dialogRef.current.closeDialog();
                    toastStore.showToast({
                        message: "Password changed successfully!",
                        icon: "tick",
                        intent: Intent.SUCCESS
                    });
                }
            })
            .catch(error => {
                EwsFormErrorHandler({error, actions});
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.ChangePasswordForm);
            })
        
    }
    
    const handleSaveClicked = () => {
        if(formRef.current && formRef.current.isValid){
            formRef.current.submitForm();
        }
    }
    
    return (
        <EwsDialog
            ref={dialogRef}
            title="Change Password"
            icon="key"
            buttonText="Change Password"
            intent={Intent.PRIMARY}
            headingColor={Intent.PRIMARY}
            minimalButton
            fillButton
            buttonAlignText={Alignment.LEFT}
            closeButtonText="Cancel"
            actionButtonText="Save Password"
            actionButtonColor={Intent.PRIMARY}
            actionButtonEvent={handleSaveClicked}
            actionButtonDisabled={!isFormValid}
            shouldCloseOnAction={false}
        >
            <BusyIndicator keyName={BusyStates.ChangePasswordForm}>
                <EwsForm<ChangePasswordRequest>
                    innerRef={formRef}
                    initialValues={changePasswordModel}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                    validationCallback={(isValid) => setIsFormValid(isValid)}
                >
                    {props => (
                        <>
                            <EwsPasswordInput
                                label='Old Password'
                                required={true}
                                showSecurityLevelBar={false}
                                showSecurityLevelDescription={false}
                                skipStrengthValidation
                                formikName='oldPassword'
                            />
                            <EwsPasswordInput
                                label='New Password'
                                required={true}
                                showSecurityLevelBar={true}
                                showSecurityLevelDescription={true}
                                formikName='newPassword'
                            />
                            <EwsPasswordInput
                                label='Confirm New Password'
                                required={true}
                                showSecurityLevelBar={false}
                                showSecurityLevelDescription={false}
                                skipStrengthValidation
                                formikName='confirmNewPassword'
                            />
                        </>
                    )}
                </EwsForm>
            </BusyIndicator>
        </EwsDialog>
    )
}

export default observer(ChangePasswordDialog);