import {Button, Intent} from "@ewstheme/core";
import { Classes, Popover2 } from "@ewstheme/popover2";
import {observer} from "mobx-react";
import EwsHeading from "../ews-typography/ews-heading";
import {EwsText} from "../ews-typography/ews-text";

interface EwsPopoverProps {
    title: string;
    text: string;
    intent?: Intent | undefined;
    children: JSX.Element | JSX.Element[]
}

const EwsPopover : React.FC<EwsPopoverProps> = props => {
    return (
        <Popover2
            popoverClassName={Classes.POPOVER2_CONTENT_SIZING}
            position="top"
            interactionKind="click"
            content={
                <>
                    <EwsHeading variant="h5" intent={props.intent} text={props.title}/>
                    <EwsText>
                        <>{props.text}</>
                    </EwsText>
                    <div className={"ews-popover--buttons"}>
                        <Button intent={props.intent} small className={Classes.POPOVER2_DISMISS}>
                            Close
                        </Button>
                    </div>
                </>
            }
        >
            {props.children}
        </Popover2>
    )
}

export default observer(EwsPopover);