import {Form, Formik, FormikHelpers, FormikProps, FormikValues, useFormikContext} from "formik";
import {observer} from "mobx-react";
import * as React from "react";

interface EwsFormProps<T> {
    innerRef?: React.Ref<FormikProps<FormikValues & T>>
    initialValues: FormikValues & T;
    onSubmit: (values: FormikValues & T, actions: FormikHelpers<FormikValues & T>) => void;
    children: ( props: FormikProps<FormikValues & T>) => React.ReactNode
    validationSchema?: any;
    validationCallback?: (isValid: boolean) => void;
    fullHeight?: boolean
}

function EwsForm<T> (props: EwsFormProps<T>) {
    
    const ValidationCallBack = () => {
        const {isValid} = useFormikContext();
        
        React.useEffect(() => {
            if(props.validationCallback){
                props.validationCallback(isValid);
            }
        }, [isValid])
        return null;
    }
    
    return (
        <Formik
            innerRef={props.innerRef}
            initialValues={props.initialValues}
            validationSchema={props.validationSchema}
            onSubmit={props.onSubmit}
            auto
        >
            {(formikProps: FormikProps<FormikValues & T>) => (
                <>
                    <Form style={{width: "100%", height: props.fullHeight ? "100%" : undefined}} autoComplete="off">
                        {formikProps.errors.error ?
                            <div className={"ews-form--error-container"}>{formikProps.errors.error.toString()}</div> : null}
                        {props.children(formikProps)}
                        <ValidationCallBack />
                    </Form>
                </>
            )}
        </Formik>
    );
}

export default observer(EwsForm);