import {Colors, H1, H2, H3, H4, H5, H6, Icon, Intent, MaybeElement} from "@ewstheme/core";
import {ColorFromIntent} from "../../../core/utilities/react-utils";
import {observer} from "mobx-react";
import {useRootStore} from "../../../core/stores/root-store";
import {PreferenceSettings} from "../../../core/utilities/preference-settings";
import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import If from "../utilities/if-component";
import {CSSProperties} from "react";

interface EwsHeadingProps {
    variant: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
    intent?: Intent | undefined;
    text: string;
    icon?: BlueprintIcons_16Id | MaybeElement;
    style?: CSSProperties;
}

const EwsHeading: React.FC<EwsHeadingProps> = props => {
    const {sitePersistenceStore} = useRootStore();
    const intentProp = ColorFromIntent(
        props.intent,
        !sitePersistenceStore.getPreference(PreferenceSettings.DarkMode) ? Colors.PURPLE1 : Colors.PURPLE3, 
        !sitePersistenceStore.getPreference(PreferenceSettings.DarkMode));

    switch(props.variant) {
        default:
        case "h1":
            return (<H1 style={{color: intentProp, ...props.style}}>
                <If condition={props.icon !== undefined}>
                    <Icon icon={props.icon} size={36} style={{paddingBottom: "5px", marginRight: "10px"}} />
                </If>
                {props.text}</H1>);
        case "h2":
            return (<H2 style={{color: intentProp, ...props.style}}>
                <If condition={props.icon !== undefined}>
                    <Icon icon={props.icon} size={28} style={{paddingBottom: "4px", marginRight: "8px"}}  />
                </If>
                {props.text}</H2>);
        case "h3":
            return (<H3 style={{color: intentProp, ...props.style}}>
                <If condition={props.icon !== undefined}>
                    <Icon icon={props.icon} size={22} style={{paddingBottom: "4px", marginRight: "6px"}}  />
                </If>
                {props.text}</H3>);
        case "h4":
            return (<H4 style={{color: intentProp, ...props.style}}>
                <If condition={props.icon !== undefined}>
                    <Icon icon={props.icon} size={18} style={{paddingBottom: "3px", marginRight: "4px"}}  />
                </If>
                {props.text}</H4>);
        case "h5":
            return (<H5 style={{color: intentProp, ...props.style}}>
                <If condition={props.icon !== undefined}>
                    <Icon icon={props.icon} size={16} style={{paddingBottom: "2px", marginRight: "4px"}}  />
                </If>
                {props.text}</H5>);
        case "h6":
            return (<H6 style={{color: intentProp, ...props.style}}>
                <If condition={props.icon !== undefined}>
                    <Icon icon={props.icon} size={14} style={{paddingBottom: "2px", marginRight: "4px"}}  />
                </If>
                {props.text}</H6>);
    }
}

export default observer(EwsHeading);