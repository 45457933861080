import {ApiResult, MediaDirectoryListItemResult, MediaDirectoryTreeResult, MediaItemResult} from "@ews-turing-models/clientmodels/dist";
import {makeAutoObservable, reaction, runInAction} from "mobx";
import {createContext, useContext} from "react";
import MediaLibraryApi from "../api/media-library-api";
import {PreferenceSettings, TempPreferenceSettings} from "../utilities/preference-settings";
import {rootStore} from "./root-store";

export interface ISelectedItem {
    type: SelectedItemType,
    id: string
}

export enum SelectedItemType {
    Folder,
    File
}

export enum MediaLibraryDialogs {
    NewFolder,
    RenameFolder,
    MoveItems,
    DeleteItems,
    DeletedFolderInfo,
    AddFiles,
    ViewContent,
    DeletedFileInfo,
    StorageUsage,
    Search
}

class MediaLibraryContext {
    hasErrored: boolean = false;
    loadingInitial: boolean = false;
    directoryTree: MediaDirectoryTreeResult | undefined = undefined;
    currentDirectory: MediaDirectoryListItemResult | undefined = undefined;
    requestedDirectory: string | undefined = rootStore.sitePersistenceStore.getTempPreference(TempPreferenceSettings.MediaLibraryCurrentDirectory);
    selectedItems: ISelectedItem[] = [];
    showHiddenItems: boolean = false;
    viewMode: "grid-view" | "list" =
        rootStore.sitePersistenceStore.getPreference(PreferenceSettings.MediaLibraryViewMode)
        ?? "grid-view";
    currentDialog: MediaLibraryDialogs | undefined = undefined;
    deletedFolderView: string | undefined = undefined;
    deletedFileView: string | undefined = undefined;

    constructor() {
        makeAutoObservable(this);

        reaction(
            () => this.requestedDirectory,
            directory => {
                rootStore.sitePersistenceStore.setTempPreference(
                    TempPreferenceSettings.MediaLibraryCurrentDirectory,
                    directory,
                    120
                );
            }
        )
    }

    get anySelectedItems() {
        return this.selectedItems.length > 0;
    }

    get anySelectedFiles() {
        return this.selectedItems.some(x => x.type === SelectedItemType.File)
    }

    get anySelectedFolders() {
        return this.selectedItems.some(x => x.type === SelectedItemType.Folder)
    }

    get allSelected() {
        if (this.currentDirectory && this.anySelectedItems) {
            const itemsCount = this.currentDirectory.childDirectories.length + this.currentDirectory.folderContents.length;
            const selectedCount = this.selectedItems.length;
            return itemsCount === selectedCount;
        }
        return false;
    }

    get isViewingRecyclingBin() {
        return this.currentDirectory?.directoryId === "RecyclingBin";
    }
    
    resetRequestedDirectory = () => {
        this.requestedDirectory = undefined;
        rootStore.sitePersistenceStore.resetTempPreference();
        this.clearSelection();
        this.loadingInitial = false;
    }

    setDeletedFolderView = (directoryId: string) => {
        this.deletedFolderView = directoryId;
    }

    clearDeletedFolderView = () => {
        this.deletedFolderView = undefined;
    }

    setDeletedFileView = (mediaId: string) => {
        this.deletedFileView = mediaId;
    }

    clearDeletedFileView = () => {
        this.deletedFileView = undefined;
    }

    loadMediaLibrary = async () => {
        runInAction(() => {
            this.clearSelection();
            this.hasErrored = false;
        })
        let promises = [];

        if (this.requestedDirectory === "RecyclingBin") {
            var loadDirectory = MediaLibraryApi.loadRecyclingBin()
                .then(results => {
                    runInAction(() => {
                        this.directoryTree = results.directoryTree.model;
                        this.currentDirectory = results.directory.model;
                    });
                }).catch(error => {
                    console.log(error);
                    runInAction(() => {
                        this.hasErrored = true
                    })
                });
            promises.push(loadDirectory);
        } else {
            var loadDirectory = MediaLibraryApi.loadDirectory({
                directoryId: this.requestedDirectory,
                showHidden: this.showHiddenItems
            }).then(results => {
                runInAction(() => {
                    this.directoryTree = results.directoryTree.model;
                    this.currentDirectory = results.directory.model;
                });
            }).catch(error => {
                console.log(error);
                runInAction(() => {
                    this.hasErrored = true
                })
            });

            promises.push(loadDirectory);
        }


        await Promise.all(promises);

        runInAction(() => {
            this.loadingInitial = true;
        });
    }

    setDirectory = (directoryId: string) => {
        this.requestedDirectory = directoryId;
        this.clearSelection();
        this.loadingInitial = false;
    }

    updateView = async (directoryData: MediaDirectoryListItemResult) => {
        runInAction(() => {
            this.currentDirectory = directoryData;
        });

        let promises = [];

        const directoryTree = MediaLibraryApi.getDirectoryTree()
            .then(result => {
                runInAction(() => {
                    this.directoryTree = result.model;
                })
            })
            .catch(error => {
                console.log(error);
                runInAction(() => {
                    this.hasErrored = true
                })
            });

        promises.push(directoryTree);

        await Promise.all(promises);
    }

    setViewMode = (viewMode: "grid-view" | "list") => {
        this.viewMode = viewMode;
        rootStore.sitePersistenceStore.setPreference(PreferenceSettings.MediaLibraryViewMode, viewMode);
    }

    addSelectedItem = (item: ISelectedItem) => {
        this.selectedItems.push(item);
    }

    removeSelectedItem = (item: ISelectedItem) => {
        const filtered = this.selectedItems.filter(x =>
            !(x.id === item.id &&
                x.type === item.type));
        this.selectedItems = filtered;
    }

    clearSelection = () => {
        this.selectedItems = [];
    }

    selectAllItems = () => {
        this.clearSelection();
        if (this.currentDirectory) {
            const allFolders = this.currentDirectory.childDirectories.map(item => {
                const mappedItem: ISelectedItem = {id: item.directoryId, type: SelectedItemType.Folder};
                return mappedItem;
            });
            const allFiles = this.currentDirectory.folderContents.map(item => {
                const mappedItem: ISelectedItem = {id: item.mediaId, type: SelectedItemType.File};
                return mappedItem;
            });
            this.selectedItems = allFolders.concat(allFiles);
        }

    }

    setCurrentDialog = (dialog: MediaLibraryDialogs) => {
        this.currentDialog = dialog;
    }

    closeDialog = () => {
        this.currentDialog = undefined;
    }

    moveSelectedItems = async (directoryId: string) => {
        let promises: any[] = [];

        this.selectedItems.forEach((item) => {
            switch (item.type) {
                case SelectedItemType.Folder:
                    const folderItem = MediaLibraryApi.moveDirectory({
                        directoryId: item.id,
                        parentDirectoryId: directoryId
                    });
                    promises.push(folderItem);
                    break;
                case SelectedItemType.File:
                    const fileItem = MediaLibraryApi.moveFile({
                        mediaItemId: item.id,
                        directoryId: directoryId
                    });
                    promises.push(fileItem);
                    break;
            }
        });

        if (promises.length > 0) {
            await Promise.all(promises);
        }
    }

    deleteSelectedItems = async () => {
        let promises: any[] = [];

        const folderids = this.selectedItems.filter(x => x.type === SelectedItemType.Folder).map(x => x.id);
        const mediaitemids = this.selectedItems.filter(x => x.type === SelectedItemType.File).map(x => x.id);

        if (folderids.length > 0) {
            const folderItems = MediaLibraryApi.deleteDirectories({mediaItemIds: folderids});
            promises.push(folderItems);
        }

        if (mediaitemids.length > 0) {
            const fileItems = MediaLibraryApi.deleteMediaItems({mediaItemIds: mediaitemids});
            promises.push(fileItems);
        }

        if (promises.length > 0) {
            await Promise.all(promises);
        }
    }

    downloadSelectedFiles = async () => {
        const mediaIds = this.selectedItems.filter(x => x.type === SelectedItemType.File).map(x => x.id);
        if (mediaIds.length > 0) {
            await MediaLibraryApi.bulkFiles({mediaIds: mediaIds});
        }
    }
    
    restoreSelected = async () => {
        let promises: any[] = [];

        const folderids = this.selectedItems.filter(x => x.type === SelectedItemType.Folder).map(x => x.id);
        const mediaitemids = this.selectedItems.filter(x => x.type === SelectedItemType.File).map(x => x.id);
        
        if(folderids.length > 0){
            const folderItems = MediaLibraryApi.restoreDirectories({mediaItemIds: folderids});
            promises.push(folderItems);
        }
        
        if (mediaitemids.length > 0){
            const fileItems = MediaLibraryApi.restoreMediaItems({mediaItemIds: mediaitemids});
            promises.push(fileItems);
        }
        
        if(promises.length > 0){
            await Promise.all(promises);
        }
    }

    navigateTo = async (itemId: string) => {
        const mediaItem : ApiResult<MediaItemResult> = await MediaLibraryApi.getMediaItem({mediaId: itemId});
        const directory = mediaItem.model.breadcrumb.find(x => x.levelFromChild === 0);
        
        this.setDirectory(directory?.directoryId ?? "");
    }
}

interface MediaLibraryStore {
    store: MediaLibraryContext;
}

export const mediaLibraryStore: MediaLibraryStore = {
    store: new MediaLibraryContext()
}

export const MediaLibraryStoreContext = createContext(mediaLibraryStore);

export function useMediaLibraryStore() {
    return useContext(MediaLibraryStoreContext);
}
