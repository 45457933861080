import {observer} from "mobx-react";
import {Button, Classes, Dialog, Intent} from "@ewstheme/core";
import {ClassNames} from "../../../../../core/utilities/react-utils";
import {MediaLibraryDialogs, useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import {useRootStore} from "../../../../../core/stores/root-store";
import {BiTrash} from "react-icons/bi";
import {EwsText} from "../../../../shared/ews-typography/ews-text";
import {BusyStates} from "../../../../../core/utilities/busy-states";

const DeleteItemsDialog : React.FC = () => {
    const {busyStore, toastStore} = useRootStore();
    const {store} = useMediaLibraryStore();

    const handleClose = () => {
        store.closeDialog();
    }
    
    const handleSubmit = () => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        store.deleteSelectedItems()
            .then(() => {
                handleClose();
                busyStore.removeBusyState(BusyStates.MediaLibrary);
                toastStore.showToast({
                    message: `Successfully deleted selected items`,
                    icon: 'tick',
                    intent: 'success'
                });
                store.setDirectory(store.currentDirectory!.directoryId);
            })
            .catch(error => {
                busyStore.removeBusyState(BusyStates.MediaLibrary);
                console.log(error);
                toastStore.showToast({
                    message: `An error occured whilst deleting selected items`,
                    icon: 'cross',
                    intent: 'danger'
                });
            });
    }
        
    return (
        <Dialog
            className={ClassNames(["ews-dialog", "ews-dialog--danger"])}
            isOpen={store.currentDialog === MediaLibraryDialogs.DeleteItems}
            icon={<BiTrash className={ClassNames(["box-icon", "box-icon-dialog"])}/>}
            title={"Delete"}
            onClose={handleClose}
        >
            <div className={Classes.DIALOG_BODY}>
                <EwsText>Are you sure you want to delete the selected items?</EwsText>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className='form-action-container'>
                    <Button
                        type='button'
                        onClick={handleClose}
                    >
                        No
                    </Button>
                    <Button
                        intent={Intent.DANGER}
                        type='button'
                        onClick={handleSubmit}
                    >{'Yes'}</Button>
                </div>
            </div>
        </Dialog>
    )
}

export default observer(DeleteItemsDialog);