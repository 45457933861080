import {MediaStorageInfoResult} from "@ews-turing-models/clientmodels/dist";
import {useEffect, useState} from "react";
import {Colors} from "@ewstheme/core";
import {Cell, Legend, Pie, PieChart, ResponsiveContainer} from "recharts";
import {FormatBytes} from "../../../../../core/utilities/react-utils";
import {observer} from "mobx-react";
import "./styles.scss";

interface StorageSummaryChartProps {
    data?: MediaStorageInfoResult;
}

interface ChartData {
    name: string;
    value: number;
}

enum StorageWarningLevel {
    Danger,
    Warning,
    OK
}

const StorageSummaryChart: React.FC<StorageSummaryChartProps> = props => {
    const [chartData, setChartData] = useState<ChartData[]>();
    const [storageHealth, setStorageHealth] = useState(StorageWarningLevel.OK);

    useEffect(() => {
        const used = props.data?.used ?? 0;
        const quota = props.data?.quota ?? 0;
        const free = quota - used;

        setChartData([
            {name: "Used", value: used},
            {name: "Free", value: free}
        ]);

        if (used / quota >= 0.95) setStorageHealth(StorageWarningLevel.Danger);
        if (used / quota >= 0.85) setStorageHealth(StorageWarningLevel.Warning);
    }, [props.data]);

    const chartColors = () => {
        switch (storageHealth) {
            case StorageWarningLevel.Danger:
                return [Colors.RED3, Colors.GRAY4];
            case StorageWarningLevel.Warning:
                return [Colors.ORANGE3, Colors.GRAY4];
            default:
                return [Colors.GREEN3, Colors.GRAY4];
        }
    }

    if (!props.data) return null;

    return (
        <div className="media-storage-info--container">
            <div className="media-storage-info--chart-item">
                <div style={{height: "300px", marginBottom: "1rem"}}>
                    <ResponsiveContainer width="100%" height="100%">
                        <PieChart>
                            <Legend
                                verticalAlign="middle"
                                layout="vertical"
                                iconType="circle"
                            />
                            <Pie
                                data={chartData}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                innerRadius={100}
                                outerRadius={150}
                                fill={Colors.PURPLE5}
                                dataKey="value"
                                startAngle={90}
                                endAngle={-270}
                            >
                                {chartData?.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={chartColors()[index % chartColors().length]}/>
                                ))}
                            </Pie>
                        </PieChart>
                    </ResponsiveContainer>
                </div>
                <span
                    className="chart-details">{`${FormatBytes(props.data?.used ?? 0)} of ${FormatBytes(props.data?.quota ?? 0)} used`}</span>
                <span
                    className="chart-details small">{props.data?.numberOfItems} file{props.data?.numberOfItems !== 1 ? "s" : ""}</span>
            </div>
        </div>
    )
}

export default observer(StorageSummaryChart);