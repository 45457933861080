import {observer} from "mobx-react";
import {Menu, MenuItem} from "@ewstheme/core";
import {BiRename, BiTrash, BiUndo} from "react-icons/bi";
import {
    MediaLibraryDialogs,
    SelectedItemType,
    useMediaLibraryStore
} from "../../../../../core/stores/media-library-store";
import If from "../../../../shared/utilities/if-component";
import EwsActionsItem from "../../../../shared/ews-workarea/components/ews-actions-item";
import {BusyStates} from "../../../../../core/utilities/busy-states";
import {useRootStore} from "../../../../../core/stores/root-store";

interface DirectoryContextMenuProps {
    directoryId: string
}

const DirectoryContextMenu: React.FC<DirectoryContextMenuProps> = props => {
    const {busyStore, toastStore} = useRootStore();
    const {store} = useMediaLibraryStore();

    const onRenameClicked = () => {
        store.clearSelection();
        store.addSelectedItem({
            id: props.directoryId,
            type: SelectedItemType.Folder
        });
        store.setCurrentDialog(MediaLibraryDialogs.RenameFolder);
    }

    const onMoveClicked = () => {
        if (!store.selectedItems.find(x => x.id === props.directoryId && x.type === SelectedItemType.Folder)) {
            store.addSelectedItem({
                id: props.directoryId,
                type: SelectedItemType.Folder
            });
        }
        store.setCurrentDialog(MediaLibraryDialogs.MoveItems);
    }

    const onDeleteClicked = () => {
        if (!store.selectedItems.find(x => x.id === props.directoryId && x.type === SelectedItemType.Folder)) {
            store.addSelectedItem({
                id: props.directoryId,
                type: SelectedItemType.Folder
            });
        }
        store.setCurrentDialog(MediaLibraryDialogs.DeleteItems);
    }

    const onRestoreClicked = () => {
        if (!store.selectedItems.find(x => x.id === props.directoryId && x.type === SelectedItemType.Folder)) {
            store.addSelectedItem({
                id: props.directoryId,
                type: SelectedItemType.Folder
            });
        }

        busyStore.setBusyState(BusyStates.MediaLibrary);
        store.restoreSelected()
            .then(() => {
                if (store.currentDirectory) {
                    store.setDirectory(store.currentDirectory.directoryId);
                }
                toastStore.showToast({
                    message: "Selected files and folders restored successfully",
                    intent: "success",
                    icon: "tick"
                });
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.MediaLibrary);
            });
    }

    return (
        <Menu>
            <If condition={store.currentDirectory?.directoryId !== "RecyclingBin"}>
                <MenuItem
                    icon={<BiRename className={"box-icon"}/>}
                    text={"Rename"}
                    disabled={store.selectedItems.length > 1}
                    onClick={onRenameClicked}
                />
                <MenuItem
                    icon={"add-to-folder"}
                    text={store.selectedItems.length > 1 ? "Move selected" : "Move"}
                    onClick={onMoveClicked}
                />
                <MenuItem
                    icon={<BiTrash className={"box-icon"}/>}
                    text={store.selectedItems.length > 1 ? "Delete selected" : "Delete"}
                    onClick={onDeleteClicked}
                />
            </If>
            <If condition={store.currentDirectory?.directoryId === "RecyclingBin"}>
                <MenuItem
                    icon={<BiUndo className={"box-icon"}/>}
                    text={store.selectedItems.length > 1 ? "Restore selected" : "Restore"}
                    onClick={onRestoreClicked}
                />
            </If>
        </Menu>
    )
}

export default observer(DirectoryContextMenu);