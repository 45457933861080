import {observer} from "mobx-react";
import {useRootStore} from "../../../../core/stores/root-store";
import {Button, InputGroup} from "@ewstheme/core";
import {useState} from "react";

const SignalrDemo : React.FC = () => {
    const {signalrStore} = useRootStore();
    const [message, setMessage] = useState<string>("");
    return (
        <>
            <InputGroup
                placeholder={"Message..."}
                onChange={event => {setMessage(event.target.value);}}
                value={message}
            />
            <Button
                text={"Send"}
                onClick={() => {
                    signalrStore.sendMessage(message);
                    setMessage("");
                }}
            />
            <div>
                {signalrStore.messages.map((message, index) => (
                    <div
                        key={`signalrDemo-${index}`}
                    >
                        <div
                            style={{fontWeight: 600, marginBottom: "0.5rem"}}
                        >
                            {message.usersName}
                        </div>
                        <div>{message.message}</div>
                    </div>
                ))}
            </div>
        </>
    )
}

export default observer(SignalrDemo);