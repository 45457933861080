import {observer} from "mobx-react";

export interface EwsTrProps extends React.HTMLAttributes<HTMLElement> {
    children: JSX.Element | JSX.Element[]
}

const EwsTr : React.FC<EwsTrProps> = ({children, ...rest}) => {
    return (
        <tr {...rest}>
            {children}
        </tr>
    )
}

export default observer(EwsTr);