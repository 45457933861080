import {MediaStorageInfoByCategoryResult, MediaStorageInfoResult} from "@ews-turing-models/clientmodels/dist";
import {Colors} from "@ewstheme/core";
import {FormatBytes} from "../../../../../core/utilities/react-utils";
import {observer} from "mobx-react";
import "./styles.scss";

interface FileCategoriesChartProps {
    data?: MediaStorageInfoByCategoryResult[];
    storageData?: MediaStorageInfoResult;
}

const FileCategoriesChart: React.FC<FileCategoriesChartProps> = props => {

    if (!props.data || !props.storageData) return null;

    const calculatePercentage = (amount: number) => {
        return ((amount / (props.storageData?.quota ?? 0)) * 100).toFixed(4);
    }

    const colorFromCategory = (category: string) => {
        switch (category) {
            case "Images":
                return Colors.CERULEAN5;
            case "Documents":
                return Colors.FOREST5;
            case "Audios":
                return Colors.VERMILION5;
            case "Videos":
                return Colors.VIOLET5;
            default:
                return Colors.SEPIA5
        }
    }

    const renderLabels = () => {
        let items = [];
        for (let i = 0; i < 10; i++) {
            const amount = ((props.storageData?.quota ?? 0) / 10) * (i + 1);
            const item = (
                <div className="label-part" key={`charLabels_${i}`}>
                    <span>{FormatBytes(amount)}</span>
                    {i === 0 && (<span>{FormatBytes(0)}</span>)}
                </div>
            );
            items.push(item);
        }
        return items;
    }

    return (
        <div className="media-storage-info--container" style={{height: "400px"}}>
            <div className="media-storage-info--chart-item">
                <div className='ews-file-categories-container'>
                    <div className='ews-file-categories-chart'>
                        <div className='ews-file-category-chart-wrapper'>
                            {props.data?.map((item, index) => (
                                <div
                                    key={`fileCategoryChartPart_${index}`}
                                    className="chart-part"
                                    style={{
                                        flex: `0 0 ${calculatePercentage(item.datasize)}%`,
                                        height: `${calculatePercentage(item.datasize)}%`,
                                        backgroundColor: colorFromCategory(item.fileCategory)
                                    }}
                                />
                            ))}
                        </div>
                    </div>
                    <div className='ews-file-category-chart-labels'>
                        {renderLabels()}
                    </div>
                    <div className='ews-file-category-chart-summary'>
                        <div className='summary-wrapper'>
                            {props.data.map((item, index) => (
                                <div key={`summaryChart_${index}`}>
                                    <span className="summary-point"
                                          style={{backgroundColor: colorFromCategory(item.fileCategory)}}></span>
                                    <span
                                        className="summary-text">{item.fileCategory} ({FormatBytes(item.datasize)})</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default observer(FileCategoriesChart);