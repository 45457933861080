import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import {FormGroup, Icon, MaybeElement, TextArea} from "@ewstheme/core";
import {IEwsInputFieldDialogProps, IEwsInputFieldPopoverProps} from "../interfaces";
import {CSSProperties} from "react";
import {observer} from "mobx-react";
import {useField} from "formik";
import uuid from "react-uuid";
import If from "../../utilities/if-component";
import EwsDialog from "../../ews-dialog";
import EwsPopover from "../../ews-popover";
import {IsNullOrWhiteSpace} from "../../../../core/utilities/react-utils";

interface EwsTextareaProps {
    label: string;
    placeholder?: string;
    subLabel?: string;
    subLabelPlacement? : 'top' | 'bottom';
    size?: "small" | "regular" | "large";
    required?: boolean;
    disabled?: boolean;
    formikName: string;
    withDialog?: boolean;
    dialogProps?: IEwsInputFieldDialogProps;
    withPopover?: boolean;
    popoverProps?: IEwsInputFieldPopoverProps;
    style?: CSSProperties;
    rows?: number;
}

const EwsTextarea : React.FC<EwsTextareaProps> = ({subLabelPlacement = 'top', ...props}) => {
    const [field, meta, helpers] = useField(
        {
            name: props.formikName
        });
    
    const componentUUID = uuid();
    
    return (
        <FormGroup
            className={"ews-textarea-field--container"}
            label={props.label}
            subLabel={subLabelPlacement === 'top' ? props.subLabel : undefined}
            helperText={meta.touched && meta.error ? meta.error : undefined}
            labelFor={componentUUID}
            labelInfo={props.required && "(required)"}
            intent={meta.touched && meta.error ? 'danger' : undefined}
            disabled={props.disabled}
            style={props.style}
        >
            <If condition={props.withDialog === true && props.dialogProps !== undefined}>
                <div className={"ews-textarea-field--helper-dialog"}>
                    <EwsDialog
                        icon='info-sign'
                        showIconOnButton={true}
                        title={props.dialogProps?.title ?? ""}
                        intent='primary'
                        minimalButton={true}
                        buttonSize='small'
                        closeButtonText='Close'
                        closeButtonColor='primary'
                        headingColor='primary'
                    >
                        {props.dialogProps?.dialogBody ?? <></>}
                    </EwsDialog>
                </div>
            </If>
            <If condition={props.withPopover === true && props.popoverProps !== undefined}>
                <div className={"ews-textarea-field--helper-dialog"}>
                    <EwsPopover
                        title={props.popoverProps?.title ?? ""}
                        text={props.popoverProps?.popoverText ?? ""}>
                        <Icon intent='primary' icon="info-sign"/>
                    </EwsPopover>
                </div>
            </If>
            
            <TextArea
                className={"ews-textarea-field"}
                id={componentUUID}
                placeholder={props.placeholder}
                intent={meta.touched && meta.error ? 'danger' : undefined}
                small={props.size === 'small'}
                large={props.size === 'large'}
                disabled={props.disabled}
                fill
                {...field}
                rows={props.rows ?? 5}
            />
            <If condition={IsNullOrWhiteSpace(props.subLabel) && subLabelPlacement === 'bottom'}>
                <div className="bp4-form-group-sub-label">{props.subLabel}</div>
            </If>
        </FormGroup>
    )
}

export default observer(EwsTextarea);