import {observer} from "mobx-react";
import {useMediaLibraryStore} from "../../../core/stores/media-library-store";
import {useCallback, useEffect} from "react";
import {useRootStore} from "../../../core/stores/root-store";
import {BusyStates} from "../../../core/utilities/busy-states";
import MediaLibraryExplorer from "./components/media-library-explorer";

const Main : React.FC = () => {
    const {busyStore} = useRootStore();
    const {store} = useMediaLibraryStore();
    
    const initStore = useCallback(async () => {
        if(!store.loadingInitial) {
            await store.loadMediaLibrary();
        }
    },[]);
    
    useEffect(() => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        initStore().then(() => {
            busyStore.removeBusyState(BusyStates.MediaLibrary);
        })
    }, [initStore, store.loadingInitial])
    
    return (
        <>
            <MediaLibraryExplorer showInlineControls />            
        </>
    )
}

export default observer(Main);