import {useRootStore} from "../../../core/stores/root-store";
import BusyIndicator from "../../shared/busy-indicator";
import {BusyStates} from "../../../core/utilities/busy-states";
import EwsWorkarea from "../../shared/ews-workarea";
import Main from "./main";
import Actions from "./actions";
import {observer} from "mobx-react";
import {mediaLibraryStore, MediaLibraryStoreContext} from "../../../core/stores/media-library-store";

const MediaLibrary : React.FC = () => {
    
    return (
        <BusyIndicator keyName={BusyStates.MediaLibrary}>
            <MediaLibraryStoreContext.Provider value={mediaLibraryStore}>
                <EwsWorkarea title={"Media Library"} icon={"media"} main={<Main />} actions={<Actions />} />
            </MediaLibraryStoreContext.Provider>
        </BusyIndicator> 
    )
}

export default observer(MediaLibrary);