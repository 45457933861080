import { Observer } from "mobx-react";
import React from "react";
import {makeAutoObservable} from "mobx";

export enum EwsMobxEditModes  {
    Readonly,
    Create,
    Edit,
    Delete
};

export class EwsMobxStore<T, F> {
    data: T[] = [];
    selectedItem?: T = undefined;
    loadedInitial = false;
    editMode = EwsMobxEditModes.Readonly;
    filter?: F = undefined;

    constructor() { makeAutoObservable(this); }

    loadData = (data: T[]) => { this.data = data; }
    makeSelection = (predicate: (value: T, index: number, obj: T[]) => unknown) => {
        var item = this.data.find(predicate);
        this.selectedItem = item;
    }
    setFilter = (data: F) => { this.filter = data }
    clearSelection = () => { this.selectedItem = undefined; }
    setLoadedInitial = (value: boolean) => { this.loadedInitial = value; }
    setEditMode = (mode: EwsMobxEditModes) => { this.editMode = mode; }
}

export const EwsMobxStoreInit = <T, F,>() => new EwsMobxStore<T, F>();

export const EwsMobxContext = React.createContext<EwsMobxStore<unknown, unknown>>({} as EwsMobxStore<unknown, unknown>);

export const useEwsMobxContext = <T, F = any,>() => React.useContext<EwsMobxStore<T, F>>(EwsMobxContext as React.Context<EwsMobxStore<T, F>>);

type EwsMobxProviderProps<T, F> = {
    children: JSX.Element;
}

export function EwsMobxProvider<T, F>({children}: EwsMobxProviderProps<T, F>){
    const Context = EwsMobxContext as React.Context<EwsMobxStore<T, F>>;
    const store = EwsMobxStoreInit<T, F>();
    return (
        <Context.Provider value={store}>
            {children}
            </Context.Provider>
    )
}

type EwsMobxConsumerProps<T, F> = {
    children: JSX.Element;
}

export function EwsMobxConsumer<T, F>({children}: EwsMobxConsumerProps<T, F>) {
    const Context = EwsMobxContext as React.Context<EwsMobxStore<T, F>>;
    return (
        <Context.Consumer>
            {context => (
        <Observer>
            {() =>
    <>{children}</>
}
    </Observer>
)}
    </Context.Consumer>
)
}