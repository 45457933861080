import {observer} from "mobx-react";
import EwsHeading from "../ews-typography/ews-heading";
import {useRootStore} from "../../../core/stores/root-store";
import If from "../utilities/if-component";
import {PreferenceSettings} from "../../../core/utilities/preference-settings";
import {ClassNames} from "../../../core/utilities/react-utils";
import {Icon, IconSize, MaybeElement} from "@ewstheme/core";
import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";

interface EwsWorkareaProps {
    title: string;
    icon?: BlueprintIcons_16Id | MaybeElement;
    main: JSX.Element | JSX.Element[] | string;
    actions?: JSX.Element | JSX.Element[] | string;
}

const EwsWorkarea : React.FC<EwsWorkareaProps> = props => {
    const {sitePersistenceStore} = useRootStore();
    return (
        <div className="ews-workarea--container">
            <div className="ews-workarea--header">
                <EwsHeading text={props.title} variant="h1" icon={props.icon} />
            </div>
            <div className="ews-workarea--wrapper">
                <div className="ews-workarea--main">
                    {props.main}
                </div>
                <If condition={props.actions !== undefined}>
                    <div className="ews-workarea--actions">
                        <div
                            className="ews-workarea--actions-toggle"
                            onClick={() => sitePersistenceStore.togglePreference(PreferenceSettings.ActionsPanelCollapsed)}
                        >
                            <Icon 
                                icon={sitePersistenceStore.getPreference(PreferenceSettings.ActionsPanelCollapsed) ?
                                    "chevron-left" :
                                    "chevron-right"}
                                size={IconSize.LARGE}
                            />
                        </div>
                        <div 
                            className={ClassNames(
                            [
                                    "ews-workarea--actions-content",
                                    sitePersistenceStore.getPreference(PreferenceSettings.ActionsPanelCollapsed) ? "collapsed" : ""
                                ]
                            )}
                        >
                            <EwsHeading text="Actions" variant="h4" />
                            {props.actions}
                        </div>
                    </div>
                </If>
            </div>
        </div>
    )
}

export default observer(EwsWorkarea);