import {BaseIndexResultItem, SearchRequest, SearchResult} from "@ews-turing-models/clientmodels/dist";
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import mediaLibraryApi from "../../../../core/api/media-library-api";
import styles from "../styles.module.scss";
import If from "../../../shared/utilities/if-component";
import {Button, Icon, Intent, MaybeElement} from "@ewstheme/core";
import {EwsText} from "../../../shared/ews-typography/ews-text";
import EwsHeading from "../../../shared/ews-typography/ews-heading";
import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import {useMediaLibraryStore} from "../../../../core/stores/media-library-store";
import {history} from "../../../../index";
import {AppPaths} from "../../../../AppPaths";

interface TenantSearchProps {
    query: string;
    callback?: () => void;
}

const TenantSearch: React.FC<TenantSearchProps> = props => {
    const [searchQuery, setSearchQuery] = useState<SearchRequest>({
        term: "",
        pageNumber: 1,
        pageSize: 20,
        sortBy: "_score",
        asc: false
    });

    const [canPage, setCanPage] = useState(false);
    const [searchResults, setSearchResults] = useState<BaseIndexResultItem[]>([]);
    const [currentResults, setCurrentResults] = useState<SearchResult<BaseIndexResultItem> | undefined>(undefined);
    
    const {store} = useMediaLibraryStore();

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            setSearchQuery({
                term: props.query,
                pageNumber: 1,
                pageSize: 20,
                sortBy: "_score",
                asc: false
            })
        }, 1000);
        return () => clearTimeout(timeOutId);
    }, [props.query]);

    useEffect(() => {
        if (searchQuery !== undefined && searchQuery.term !== "" && searchQuery.term.length >= 0) {
            performSearch(searchQuery, true);
        }
    }, [searchQuery.term]);

    const performSearch = (query?: SearchRequest, clearResults?: boolean) => {
        if (clearResults === true) setSearchResults([]);
        mediaLibraryApi.search(query ?? searchQuery)
            .then(result => {
                loadSearchResults(result, query, clearResults);
            });
    }

    const loadSearchResults = (result: SearchResult<BaseIndexResultItem>, query?: SearchRequest, clearResults?: boolean) => {
        if (result.pageNumber < result.pageCount) {
            let newSearchQuery = Object.assign({}, query ?? searchQuery);
            newSearchQuery.pageNumber = result.pageNumber + 1;
            setSearchQuery(newSearchQuery);
            setCanPage(true);
        } else {
            setCanPage(false);
        }

        let newResultList = Object.assign([], clearResults === true ? [] : searchResults).concat(result.data);
        setSearchResults(newResultList);
        setCurrentResults(result);
    }
    
    const iconFromIndexType = (indexType: string) => {
        switch(indexType){
            case "MediaIndex":
                return "media";
            default: 
                return "blank";
        }
    }
    
    const handleSearchItemClick = (searchItem: BaseIndexResultItem) => {
        switch(searchItem.indexType){
            case "MediaIndex":
                history.push(AppPaths.MediaLibrary.Url);
                store.navigateTo(searchItem.id).then();
                break;
        }

        if(props.callback){
            props.callback();
        }
    }

    return (
        <div className={styles.menuSearchContainer}>
            <If condition={currentResults === undefined}>
                <div style={{textAlign: "center"}}>
                    <Icon
                        icon={"search"}
                        size={60}
                        intent={Intent.PRIMARY}
                    />
                </div>
                <div style={{textAlign: "center"}}>
                    Begin typing to search EWS.
                </div>
            </If>
            <If condition={currentResults !== undefined}>
                <If condition={(currentResults?.count ?? 0) < 1}>
                    <div style={{textAlign: "center"}}>
                        No results found.
                    </div>
                </If>
                <If condition={(currentResults?.count ?? 0) > 0}>
                    <div style={{textAlign: "right", paddingBottom: "0.5rem"}}>
                        {currentResults?.count} results found.
                    </div>
                    <div className={styles.menuSearchWrapper}>
                        {searchResults.map(item => (
                            <div
                                className={styles.menuSearchResultItem}
                                key={`ewsMenuSearch_${item.indexType}_${item.id}`}
                                onClick={() => handleSearchItemClick(item)}
                            >
                                <EwsHeading
                                    intent={Intent.PRIMARY} 
                                    variant={"h5"}
                                    text={item.title}
                                    icon={iconFromIndexType(item.indexType)}
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        marginBottom: ".25rem",
                                        marginTop: ".25rem"
                                    }}
                                />
                                <div className={styles.menuSearchItemDescription}>
                                    <div className={styles.wrapper}>
                                        {item.description}
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{paddingTop: "0.5rem", paddingBottom: "0.5rem", textAlign: "center"}}>
                        <If condition={canPage}>
                            <Button
                                type={"button"}
                                intent={Intent.PRIMARY}
                                text={"Load more"}
                                onClick={() => {
                                    performSearch();
                                }}
                            />
                        </If>
                    </div>
                </If>
            </If>
        </div>
    )
}

export default observer(TenantSearch);