import {observer} from "mobx-react";
import {Classes, Dialog} from "@ewstheme/core";
import {ClassNames} from "../../../../../core/utilities/react-utils";
import {MediaLibraryDialogs, useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import {CreateDirectoryRequest} from "@ews-turing-models/clientmodels/dist";
import * as Yup from "yup";
import {FormikHelpers, FormikValues} from "formik";
import EwsForm from "../../../../shared/ews-form";
import EwsInput from "../../../../shared/ews-form/components/ews-input";
import EditorDialogActions from "../../../../shared/ews-editor-dialog/components/editor-dialog-actions";
import {useRootStore} from "../../../../../core/stores/root-store";
import {BusyStates} from "../../../../../core/utilities/busy-states";
import mediaLibraryApi from "../../../../../core/api/media-library-api";
import {EwsFormErrorHandler} from "../../../../shared/ews-form/handlers/EwsFormErrorHandler";

const NewFolderDialog: React.FC = () => {
    const {busyStore, toastStore} = useRootStore();
    const {store} = useMediaLibraryStore();

    const handleClose = () => {
        store.closeDialog();
    }

    const modal: CreateDirectoryRequest = {
        parentDirectoryId: store.currentDirectory?.directoryId ?? "",
        directoryName: ""
    }

    const validationSchema = Yup.object().shape({
        directoryName: Yup.string().required("Folder name is required")
    });

    const onSubmit = (values: (FormikValues & CreateDirectoryRequest), actions: FormikHelpers<FormikValues & CreateDirectoryRequest>) => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        mediaLibraryApi.createDirectory(values)
            .then(result => {
                store.updateView(result.model).then(() => {
                    handleClose();
                    busyStore.removeBusyState(BusyStates.MediaLibrary);
                    toastStore.showToast({
                        message: `Folder created successfully`,
                        icon: 'tick',
                        intent: "success"
                    });
                });
            })
            .catch(error => {
                busyStore.removeBusyState(BusyStates.MediaLibrary);
                EwsFormErrorHandler(error);
            });
    };

    return (
        <Dialog
            className={ClassNames(["ews-dialog", "ews-dialog--primary"])}
            isOpen={store.currentDialog === MediaLibraryDialogs.NewFolder}
            icon={"folder-new"}
            title={"New Folder"}
            onClose={handleClose}
        >
            <EwsForm<CreateDirectoryRequest>
                initialValues={modal}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {formProps => (
                    <>
                        <div className={Classes.DIALOG_BODY}>
                            <EwsInput
                                label="Folder name"
                                placeholder={"New folder"}
                                required={true}
                                formikName="directoryName"
                            />
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <EditorDialogActions
                                onCancelAction={() => {handleClose()}}
                                formProps={formProps}
                            />
                        </div>
                    </>
                )}
            </EwsForm>

        </Dialog>
    )
}

export default observer(NewFolderDialog);