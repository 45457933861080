import {Colors, Intent} from "@ewstheme/core";
import moment from "moment";
import DOMPurify from "dompurify";
import parse from "html-react-parser";
import {rootStore} from "../stores/root-store";
import configData from "../../configs/settings.json";
import { MediaType } from "@ews-turing-models/clientmodels/dist";

export const ClassNames = (classes: string[]) => {
    let resultClasses: string[] = [];
    
    classes.forEach(value => {
        if (value) resultClasses.push(value);
    });
    
    if(resultClasses.length === 0) return "";
    
    return resultClasses.join(" ");
}

export const ColorFromIntent = (intent: Intent | undefined, defaultValue?: string, darken?: boolean) => {
    switch(intent){
        case "primary":
            return darken ? Colors.PURPLE1 : Colors.PURPLE3;
        case "success":
            return darken ? Colors.GREEN1 : Colors.GREEN3;
        case "warning":
            return darken ? Colors.ORANGE1 : Colors.ORANGE3;
        case "danger":
            return darken ? Colors.RED1 : Colors.RED3;
        default:
            return defaultValue;
    }
}

export const IsNullOrWhiteSpace = (value: string | undefined) =>{
    if(value === undefined) return false;
    if(value === null) return false;
    if(value.trim() === "") return false;
    return true;
}

export const SortComparer = (valueA: any, valueB: any) => {
    if (valueA < valueB) return -1;
    if (valueA > valueB) return 1;
    return 0;
}

export const ArrayEmpty = (arr: any[]) => {
    return !Array.isArray(arr) || !arr.length;    
}

export const FormatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export enum dateTimeFormats {
    LongDateTime,
    LongDate,
    LongTime,
    ShortDateTime,
    ShortDate,
    ShortTime,
    Year
}

export const FormatDateTime = (input: any, format: dateTimeFormats) => {
    if(!input) return null;
    switch(format){
        default:
        case dateTimeFormats.LongDateTime:
            return moment(input).format("ddd Do MMMM YYYY HH:mm:ss") + ' UTC';
        case dateTimeFormats.LongDate:
            return moment(input).format("ddd Do MMMM YYYY");
        case dateTimeFormats.LongTime:
            return moment(input).format("HH:mm:ss") + ' UTC';
        case dateTimeFormats.ShortDateTime:
            return moment(input).format("Do MMM YYYY HH:mm") + ' UTC';
        case dateTimeFormats.ShortDate:
            return moment(input).format("Do MMM YYYY");
        case dateTimeFormats.ShortTime:
            return moment(input).format("HH:mm") + ' UTC';
        case dateTimeFormats.Year:
            return moment(input).format("YYYY");

    }
}

export const ToTitleCase = (input: string) => {
    const result = input.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
}

export const SanitizeHtml = (html: string) => {
    const cleanHtml = DOMPurify.sanitize(`${html}`,{
        ADD_TAGS: ["iframe"],
        USE_PROFILES: { html: true },
    });
    return parse(cleanHtml);
}

export const CopyToClipboard = (input: string) => {
    navigator.clipboard.writeText(input).then(() => {
        rootStore.toastStore.showToast({
            message: "Copied to clipboard",
            intent: Intent.PRIMARY,
            icon: "info-sign"
        });
    });
}

export const ApiAsset = (url: string) => {
    return `${configData.API_URL}${url}`
}

export const FormatMediaTypeValue = (mediaType: MediaType) => {
    switch(mediaType){
        case MediaType.Document: return "Document";
        case MediaType.Image: return "Image";
        case MediaType.EmbeddedObject: return "Embedded Content";
        default: return "Unknown"
    }
}