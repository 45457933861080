import {IEwsInputFieldDialogProps, IEwsInputFieldPopoverProps} from "../interfaces";
import {FormGroup, HTMLSelect, Icon, IOptionProps} from "@ewstheme/core";
import {useField} from "formik";
import uuid from "react-uuid";
import EwsDialog from "../../ews-dialog";
import EwsPopover from "../../ews-popover";
import {observer} from "mobx-react";
import If from "../../utilities/if-component";

interface EwsSelectProps {
    label: string;
    subLabel?: string;
    large?: boolean;
    required?: boolean;
    disabled?: boolean;
    formikName: string;
    withDialog?: boolean;
    dialogProps?: IEwsInputFieldDialogProps;
    withPopover?: boolean;
    popoverProps?: IEwsInputFieldPopoverProps;
    options: IOptionProps[]
}

const EwsSelect : React.FC<EwsSelectProps> = props => {
    const [field, meta, helpers] = useField(
        {
            name: props.formikName
        });

    const componentUUID = uuid();

    return (
        <FormGroup
            className={"ews-select-field--container"}
            label={props.label}
            helperText={meta.touched && meta.error ? meta.error : undefined}
            labelFor={componentUUID}
            labelInfo={props.required && "(required)"}
            intent={meta.touched && meta.error ? 'danger' : undefined}
            subLabel={props.subLabel}
            disabled={props.disabled}>
            <If condition={props.withDialog === true && props.dialogProps !== undefined}>
                <div className={"ews-select-field--helper-dialog"}>
                    <EwsDialog
                        icon='info-sign'
                        showIconOnButton={true}
                        title={props.dialogProps?.title ?? ""}
                        intent='primary'
                        minimalButton={true}
                        buttonSize='small'
                        closeButtonText='Close'
                        closeButtonColor='primary'
                        headingColor='primary'
                    >
                        {props.dialogProps?.dialogBody ?? <></>}
                    </EwsDialog>
                </div>
            </If>
            <If condition={props.withPopover === true && props.popoverProps !== undefined}>
                <div className={"ews-select-field--helper-dialog"}>
                    <EwsPopover
                        title={props.popoverProps?.title ?? ""}
                        text={props.popoverProps?.popoverText ?? ""}>
                        <Icon intent='primary' icon="info-sign"/>
                    </EwsPopover>
                </div>
            </If>

            <HTMLSelect
                id={componentUUID}
                large={props.large}
                disabled={props.disabled}
                fill
                value={field.value}
                name={field.name}
                onChange={field.onChange}
                onBlur={field.onBlur}
                options={props.options}
            />

        </FormGroup>
    )
}

export default observer(EwsSelect)