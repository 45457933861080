import Main from "./main";
import Actions from "./actions";
import EwsWorkarea from "../../shared/ews-workarea";
import React, {useCallback, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {EwsMobxConsumer, EwsMobxProvider} from "../../../core/stores/ews-mobx-store";
import {SearchUserRequest, UserListItemResult} from "@ews-turing-models/clientmodels/dist";
import {useRootStore} from "../../../core/stores/root-store";
import BusyIndicator from "../../shared/busy-indicator";
import EwsLoading from "../../shared/ews-loading";
import {BusyStates} from "../../../core/utilities/busy-states";

const UserManagement : React.FC = () => {
    const {lookupStore, busyStore} = useRootStore();
    const initFeature = useCallback(async () => {
        await lookupStore.initUserManager()
    }, [lookupStore]);

    useEffect(() => {
        busyStore.setBusyState(BusyStates.UserManagement);
        initFeature().then(() => {
            busyStore.removeBusyState(BusyStates.UserManagement);
        });
    }, [initFeature]);
    
    return (
        <BusyIndicator keyName={BusyStates.UserManagement}>
            <EwsMobxProvider<UserListItemResult, SearchUserRequest>>
                <EwsMobxConsumer<UserListItemResult, SearchUserRequest>>
                    <EwsWorkarea title={"User Management"} icon={"people"} main={<Main />} actions={<Actions />} />
                </EwsMobxConsumer>
            </EwsMobxProvider>
        </BusyIndicator>
        
    )
}

export default observer(UserManagement)