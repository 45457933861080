import * as signalR from "@microsoft/signalr";
import {rootStore} from "../stores/root-store";
import {IHttpConnectionOptions} from "@microsoft/signalr";
import configData from "../../configs/settings.json";
const URL = `${configData.API_URL}/signalrhubs/general`;

export class SignalrHub {
    private connection: signalR.HubConnection;
    
    constructor() {
        var options: IHttpConnectionOptions = {
            accessTokenFactory: () => rootStore.authenticationStore.token ?? ''
        }
        this.connection = new signalR.HubConnectionBuilder()
            .withUrl(URL, options)
            .withAutomaticReconnect()
            .build();
    }
    
    public StartConnection = async () => {      
        
        await this.connection.start()
            .then(() => {
                this.connection.invoke("JoinGroup", rootStore.authenticationStore.tenantId)
                    .then(() => {
                        console.log("SignalR Connected");
                        rootStore.toastStore.showToast({
                            intent: "success",
                            icon: "tick",
                            message: "Connection with server established"
                        });
                    })
                    .catch(error => {
                        console.log(error);
                        rootStore.toastStore.showToast({
                            intent: "danger",
                            icon: "cross",
                            message: "Failed to established a connection to the server"
                        });
                    });
            })
            .catch(error => {
                console.log(error);
                rootStore.toastStore.showToast({
                    intent: "danger",
                    icon: "cross",
                    message: "Failed to established a connection"
                });
            })
    }
    
    public StopConnection = () => {
        this.connection.stop();
    }
    
    public Callback = (methodName: string, callback: (...args: any[]) => void) => {
        this.connection.on(methodName, callback);
    }
    
    public Send = (methodName: string, ...args: any[]) => {
        this.connection.send(methodName, ...args);
    }
}