import {useRootStore} from "../../../core/stores/root-store";
import {AppPaths} from "../../../AppPaths";
import {Navigate} from "react-router-dom";
import {observer} from "mobx-react";

interface RestrictedRouteProps {
    restrictedRoles?: string[];
    children: JSX.Element | JSX.Element[] | string;
}

const RestrictedRoute : React.FC<RestrictedRouteProps> = props => {
    const {authenticationStore} = useRootStore();
    
    const canAccess = () => {
        if(!props.restrictedRoles || props.restrictedRoles.length === 0) return true;
        if (authenticationStore.userAccount?.userPermissions.some((x => x.systemName === 'SysAdmin'))) return true;
        return authenticationStore.userAccount?.userPermissions.some(x => props.restrictedRoles?.includes(x.systemName));
    }

    if(!canAccess()) return (
        <Navigate to={AppPaths.AccessDenied.Url}/>
    )

    return (
        <>{props.children}</>
    );
}

export default observer(RestrictedRoute);