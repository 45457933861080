import {DialogFormProps} from "../../../shared/interfaces/dialog-form-props";
import {observer} from "mobx-react";
import {useRootStore} from "../../../../core/stores/root-store";
import {useEwsMobxContext} from "../../../../core/stores/ews-mobx-store";
import {
    DeleteUserRequest,
    EditUserRequest,
    SearchUserRequest,
    UserListItemResult
} from "@ews-turing-models/clientmodels/dist";
import {useCallback, useMemo, useState} from "react";
import UserManagementApi from "../../../../core/api/user-management-api";
import {BusyStates} from "../../../../core/utilities/busy-states";
import EwsInlineNotifications from "../../../shared/ews-inline-notifications";
import * as Yup from "yup";
import EwsForm from "../../../shared/ews-form";
import {FormikHelpers, FormikValues} from "formik";
import {Classes, Intent} from "@ewstheme/core";
import EwsGrid from "../../../shared/ews-grid/components/ews-grid";
import EwsGridItem from "../../../shared/ews-grid/components/ews-grid-item";
import EwsReadonlyInput from "../../../shared/ews-form/components/ews-readonly-input";
import EwsInput from "../../../shared/ews-form/components/ews-input";
import EditorDialogActions from "../../../shared/ews-editor-dialog/components/editor-dialog-actions";
import userManagementApi from "../../../../core/api/user-management-api";
import {EwsFormErrorHandler} from "../../../shared/ews-form/handlers/EwsFormErrorHandler";

interface DeleteModel {
    id: string;
    confirmName: string;
}

const DeleteUserForm : React.FC<DialogFormProps> = props => {
    const {busyStore, lookupStore, toastStore} = useRootStore();
    const store = useEwsMobxContext<UserListItemResult, SearchUserRequest>();
    const [userData, setUserData] = useState<EditUserRequest>();
    
    const initUserEdit = useCallback(async () => {
        if (store.selectedItem) {
            var data = await UserManagementApi.getUpdateUserAccount({userId: store.selectedItem.id});
            setUserData(data.model);
        }
    }, [UserManagementApi]);

    useMemo(() => {
        busyStore.setBusyState(BusyStates.DeleteUserForm);
        initUserEdit().then(() => busyStore.removeBusyState(BusyStates.DeleteUserForm));
    }, []);

    if((!userData || !store.selectedItem) && !busyStore.isBusy(BusyStates.DeleteUserForm)) return (
        <EwsInlineNotifications
            message='Failed to retrieve the selected user account data.'
            title='Error loading data'
            icon='error'
            intent='danger'
            fill />
    )
    
    if(!userData) return null;
    

    const userModel : DeleteModel = {
        id: userData.id,
        confirmName: ''
    }

    const validationSchema = Yup.object().shape({
        confirmName: Yup.string().test('validation-check', 'Fullname must match', (value, context) => {
            if (!value || value !== userData.fullName) {
                return false;
            }
            return true;
        })
    })
    
    const onSubmit = (values: (FormikValues & DeleteModel), actions: FormikHelpers<FormikValues & DeleteModel>) => {
        busyStore.setBusyState(BusyStates.UserManagement);
        userManagementApi.deleteUserAccount(values)
            .then(results => {
                let valuesClone = Object.assign({}, userData);
                store.clearSelection();
                store.setLoadedInitial(false);
                props.onCloseAction();
                toastStore.showToast({
                    message: `${valuesClone.fullName}'s account successfully deleted!`,
                    intent: "success",
                    icon: "tick"
                });
            })
            .catch(error => {
                EwsFormErrorHandler(error);
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.UserManagement)
            });
    }
    
    return (
        <EwsForm<DeleteModel>
            initialValues={userModel!}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {formProps => (
                <>
                    <div className={Classes.DIALOG_BODY}>
                        <p>Are you sure you want to delete the admin portal user '<span style={{fontWeight: "600"}}>{userData?.fullName}</span>'? This action cannot be undone!</p>
                        <EwsGrid>
                            <EwsGridItem span={6}>
                                <EwsReadonlyInput
                                    label="Fullname"
                                    placeholder="John Doe"
                                    value={userData.fullName}
                                />
                            </EwsGridItem>
                            <EwsGridItem span={6}>
                                <EwsReadonlyInput
                                    label="Email Address"
                                    placeholder="someone@example.com"
                                    value={store.selectedItem!.emailAddress}
                                />
                            </EwsGridItem>
                            <EwsGridItem span={12}>
                                <EwsInput
                                    label={`To confirm this action, please type '${userData?.fullName}'`}
                                    placeholder="Enter the admin portal users fullname"
                                    formikName="confirmName"
                                />
                            </EwsGridItem>
                        </EwsGrid>
                    </div>
                    <div className={Classes.DIALOG_FOOTER}>
                        <EditorDialogActions
                            onCancelAction={() => {props.onCloseAction()}}
                            formProps={formProps}
                            saveText="Delete"
                            Intent={Intent.DANGER}
                        />
                    </div>
                </>
            )}
        </EwsForm>
    )
}

export default observer(DeleteUserForm);