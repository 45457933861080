import {DialogFormProps} from "../../../shared/interfaces/dialog-form-props";
import {observer} from "mobx-react";
import {useRootStore} from "../../../../core/stores/root-store";
import {useEwsMobxContext} from "../../../../core/stores/ews-mobx-store";
import {CreateUserRequest, SearchUserRequest, UserListItemResult} from "@ews-turing-models/clientmodels/dist";
import * as Yup from 'yup';
import EwsForm from "../../../shared/ews-form";
import {FormikHelpers, FormikValues} from "formik";
import {Classes} from "@ewstheme/core";
import EwsGrid from "../../../shared/ews-grid/components/ews-grid";
import EwsGridItem from "../../../shared/ews-grid/components/ews-grid-item";
import EwsInput from "../../../shared/ews-form/components/ews-input";
import EwsPasswordInput from "../../../shared/ews-form/components/ews-password-input";
import EwsCheckboxGroup from "../../../shared/ews-form/components/ews-checkbox-group";
import UserRoleDetails from "./user-role-details";
import EditorDialogActions from "../../../shared/ews-editor-dialog/components/editor-dialog-actions";
import {BusyStates} from "../../../../core/utilities/busy-states";
import userManagementApi from "../../../../core/api/user-management-api";
import {EwsFormErrorHandler} from "../../../shared/ews-form/handlers/EwsFormErrorHandler";

const CreateUserForm: React.FC<DialogFormProps> = props => {
    const {busyStore, lookupStore, toastStore} = useRootStore();
    const store = useEwsMobxContext<UserListItemResult, SearchUserRequest>();

    const userModel: CreateUserRequest = {
        emailAddress: '',
        fullname: '',
        newPassword: '',
        confirmNewPassword: '',
        roleIds: []
    }

    const validationSchema = Yup.object().shape({
        emailAddress: Yup.string().required("Email address is required").email("Email address is not valid"),
        fullname: Yup.string().required("Fullname is required"),
        newPassword: Yup.string().required("New password is required"),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], "Password must match"),
        roleIds: Yup.array().test('validation-check', 'At least 1 role is required', (value, context) => {
            if (!value || value.length < 1) {
                return false;
            }
            return true;
        })
    });

    const onSubmit = (values: (FormikValues & CreateUserRequest), actions: FormikHelpers<FormikValues & CreateUserRequest>) => {
        busyStore.setBusyState(BusyStates.UserManagement);
        userManagementApi.createUserAccount(values)
            .then(results => {
                let valuesClone = Object.assign({}, values);
                store.setLoadedInitial(false);
                props.onCloseAction();
                toastStore.showToast({
                    message: `Account successfully created for ${valuesClone.fullname}`,
                    intent: "success",
                    icon: "tick"
                });
            })
            .catch(error => {
                EwsFormErrorHandler(error);
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.UserManagement)
            });
    }

    return (
        <EwsForm<CreateUserRequest>
            initialValues={userModel}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {formProps => (
                <>
                    <div className={Classes.DIALOG_BODY}>
                        <EwsGrid>
                            <EwsGridItem span={6}>
                                <EwsInput
                                    label='Email Address'
                                    subLabel='Email address cannot be changed once a user account has been created.'
                                    subLabelPlacement='bottom'
                                    placeholder='someone@example.com'
                                    required={true}
                                    formikName='emailAddress'
                                />
                            </EwsGridItem>
                            <EwsGridItem span={6}>
                                <EwsInput
                                    label='Fullname'
                                    placeholder='John Doe'
                                    required={true}
                                    formikName='fullname'
                                />
                            </EwsGridItem>
                            <EwsGridItem span={6}>
                                <EwsPasswordInput
                                    label='New Password'
                                    required={true}
                                    showSecurityLevelBar={true}
                                    showSecurityLevelDescription={true}
                                    formikName='newPassword'
                                />
                            </EwsGridItem>
                            <EwsGridItem span={6}>
                                <EwsPasswordInput
                                    label='Confirm New Password'
                                    required={true}
                                    showSecurityLevelBar={false}
                                    showSecurityLevelDescription={false}
                                    skipStrengthValidation
                                    formikName='confirmNewPassword'
                                />
                            </EwsGridItem>
                            <EwsGridItem span={12}>
                                <EwsCheckboxGroup
                                    label='Roles'
                                    required={true}
                                    inline
                                    columns={3}
                                    options={lookupStore.userRoles?.map(item => {
                                        return {value: item.id, label: item.name}
                                    }) ?? []}
                                    formikName='roleIds'
                                    withDialog
                                    dialogProps={{
                                        title: "User Roles",
                                        dialogBody: <UserRoleDetails/>
                                    }}
                                />
                            </EwsGridItem>
                        </EwsGrid>
                    </div>

                    <div className={Classes.DIALOG_FOOTER}>
                        <EditorDialogActions
                            onCancelAction={() => {props.onCloseAction()}}
                            formProps={formProps}
                        />
                    </div>
                </>
            )}
        </EwsForm>
    )
}

export default observer(CreateUserForm);