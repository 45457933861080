import {observer} from "mobx-react";
import {Dialog, MaybeElement} from "@ewstheme/core";
import {ClassNames} from "../../../core/utilities/react-utils";
import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import {EwsMobxEditModes} from "../../../core/stores/ews-mobx-store";
import If from "../utilities/if-component";

interface EwsEditorDialogProps {
    title?: string;
    icon?: BlueprintIcons_16Id | MaybeElement;
    intent: "primary" | "success" | "warning" | "danger";
    size?: "small" | "large";
    isOpen: boolean;
    onClose?: () => void;
    content? : JSX.Element | JSX.Element[] | string;
    ewsMobxMode?: EwsMobxEditModes;
    editContent? : JSX.Element | JSX.Element[] | string;
    createContent? : JSX.Element | JSX.Element[] | string;
    deleteContent? : JSX.Element | JSX.Element[] | string;
    readOnlyContent? : JSX.Element | JSX.Element[] | string;
}

const EwsEditorDialog : React.FC<EwsEditorDialogProps> = props => {
    return (
        <Dialog
            className={ClassNames(
                [
                    "dialog-form",
                    props.size ? `dialog-form-${props.size}` : "",
                    props.intent ? `dialog-form--${props.intent}` : ""                    
                ]
            )}
            isOpen={props.isOpen}
            onClose={props.onClose}
            title={props.title}
            icon={props.icon}
            canOutsideClickClose={false}
        >
            <If condition={props.ewsMobxMode === undefined && props.content !== undefined}>
                <>{props.content}</>
            </If>
            <If condition={props.ewsMobxMode === EwsMobxEditModes.Edit && props.editContent !== undefined}>
                <>{props.editContent}</>
            </If>
            <If condition={props.ewsMobxMode === EwsMobxEditModes.Create && props.createContent !== undefined}>
                <>{props.createContent}</>
            </If>
            <If condition={props.ewsMobxMode === EwsMobxEditModes.Delete && props.deleteContent !== undefined}>
                <>{props.deleteContent}</>
            </If>
            <If condition={props.ewsMobxMode === EwsMobxEditModes.Readonly && props.readOnlyContent !== undefined}>
                <>{props.readOnlyContent}</>
            </If>
        </Dialog>
    )
}

export default observer(EwsEditorDialog);