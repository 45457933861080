import {ChildDirectory} from "@ews-turing-models/clientmodels/dist";
import {observer} from "mobx-react";
import If from "../../../shared/utilities/if-component";
import {ISelectedItem, SelectedItemType, useMediaLibraryStore} from "../../../../core/stores/media-library-store";
import {ClassNames} from "../../../../core/utilities/react-utils";
import styles from "../styles.module.scss";
import {useState} from "react";
import {Checkbox} from "@ewstheme/core";
import EwsContextMenu from "../../../shared/ews-context-menu";

interface DirectoryItemProps {
    item: ChildDirectory;
}

const DirectoryItem : React.FC<DirectoryItemProps> = props => {
    const {store} = useMediaLibraryStore();
    const [hovering, setHovering] = useState(false);

    const isSelected = store.selectedItems.find(x =>
        x.id == props.item.directoryId &&
        x.type === SelectedItemType.Folder) !== undefined;
    
    
    const onSelectionMade = () => {
        const selectedItem : ISelectedItem = {
            id: props.item.directoryId,
            type: SelectedItemType.Folder
        }
        if(isSelected){
            store.removeSelectedItem(selectedItem);
        } else {
            store.addSelectedItem(selectedItem);
        }
    }
    
    const onSelectionClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    }
    
    return (
        <>
            <If condition={store.viewMode === "grid-view"}>
                <div 
                    className={styles.FolderGridContainer}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    <div className={ClassNames(
                        [
                            styles.FolderIcon
                        ]
                    )}
                    >
                        <span className={ClassNames(["fiv-ext","fiv-icon-folder-classic"])}/>
                    </div>
                    <div className={ClassNames(
                        [
                            styles.FolderName
                        ]
                    )}
                    >
                        {props.item.directoryName}
                    </div>
                    <If condition={hovering || isSelected}> 
                        <div
                            className={styles.Selector}
                            onClick={onSelectionClick}
                        >
                            <Checkbox
                                checked={isSelected}
                                onChange={onSelectionMade}
                            />
                        </div>
                    </If>
                </div>
            </If>
            <If condition={store.viewMode === "list"}>
                <div className={styles.viewRow}>
                    <div 
                        className={styles.SelectorCell}
                        onClick={onSelectionClick}
                    >
                        <Checkbox
                            checked={isSelected}
                            onChange={onSelectionMade}
                        />
                    </div>
                    <div className={styles.IconCell}>
                        <span className={ClassNames(["fiv-ext","fiv-icon-folder-classic"])}/>
                    </div>
                    <div className={styles.TitleCell}>{props.item.directoryName}</div>
                    <div className={styles.MediaTypeCell}></div>
                    <div className={styles.FileSizeCell}></div>
                    <div className={styles.UploadDateCell}></div>
                </div>
            </If>
        </>
        
    )
}

export default observer(DirectoryItem);