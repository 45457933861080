import {observer} from "mobx-react";
import styles from "../../styles.module.scss";
import {Checkbox, Code, InputGroup, Intent, Label, NumericInput, Pre} from "@ewstheme/core";
import {useEffect, useRef, useState} from "react";
import {ClassNames, CopyToClipboard} from "../../../../../core/utilities/react-utils";
import {useRootStore} from "../../../../../core/stores/root-store";
import If from "../../../../shared/utilities/if-component";

interface ImageUrlGeneratorFormProps {
    mediaId: string;
    generatedURLCallback: (url: string) => void
}

const ImageUrlGeneratorForm: React.FC<ImageUrlGeneratorFormProps> = props => {
    const {lookupStore} = useRootStore();
    const [originalImageChecked, setOriginalImageChecked] = useState(true);
    const [widthError, setWidthError] = useState(false);
    const [heightError, setHeightError] = useState(false);
    const [generatedUrl, setGeneratedUrl] = useState<string>("");

    const widthInput = useRef<HTMLInputElement>(null);
    const heightInput = useRef<HTMLInputElement>(null);

    const handleChange = (useOriginal?: boolean) => {
        clearErrors();
        const original = useOriginal ?? originalImageChecked;
        setGeneratedUrl("");
        if (original) {
            props.generatedURLCallback(`/images/original/${props.mediaId}`);
            setGeneratedUrl(`/images/original/${props.mediaId}`);
        } else {
            let isValid = true;
            if (!validateValue(widthInput.current?.value)) {
                setWidthError(true);
                isValid = false;
            }

            if (!validateValue(heightInput.current?.value)) {
                setHeightError(true);
                isValid = false;
            }

            if (heightInput.current?.value.toLowerCase() === "any" &&
                widthInput.current?.value.toLowerCase() === "any") {
                setWidthError(true);
                setHeightError(true);
                isValid = false;
            }

            if (isValid) {
                props.generatedURLCallback(`/images/${widthInput.current?.value}x${heightInput.current?.value}/${props.mediaId}`);
                setGeneratedUrl(`/images/${widthInput.current?.value}x${heightInput.current?.value}/${props.mediaId}`);
            } else {
                setGeneratedUrl("Invalid values entered");
            }

        }
    }

    const clearErrors = () => {
        setWidthError(false);
        setHeightError(false);
    }

    const validateValue = (value: string | undefined) => {
        if (!value) return false;
        if (value.toLowerCase() !== "any" && !Number.isInteger(value) && (isNaN(+value) || (+value) < 1)) {
            return false
        }
        return true;
    }

    const handleCopy = () => {
        if (!widthError && !heightError) {
            CopyToClipboard(generatedUrl)
        }
    }

    useEffect(() => {
        setGeneratedUrl(`/images/original/${props.mediaId}`);
    }, [props.mediaId]);

    return (
        <>
            <div>
                <p>Use the controls below to generate the image URL. Entering the pixel size will crop the image to fit the size entered or enter the term 'any' to keep the image aspect ratio.</p>
            </div>
            <div className={styles.MediaImageUrlGenerator}>
                <div>
                    <Checkbox
                        label={"Original Image"}
                        checked={originalImageChecked}
                        onClick={() => {
                            setOriginalImageChecked(!originalImageChecked);
                            handleChange(!originalImageChecked);
                        }}
                    />
                    <div className={styles.MediaImageUrlGeneratorControls}>
                        <Label style={{margin: 0}}>Size:</Label>
                        <InputGroup
                            placeholder={"Width"}
                            size={5}
                            disabled={originalImageChecked}
                            onChange={() => handleChange()}
                            inputRef={widthInput}
                            intent={widthError ? Intent.DANGER : undefined}
                        />
                        <span>x</span>
                        <InputGroup
                            placeholder={"Height"}
                            size={5}
                            disabled={originalImageChecked}
                            onChange={() => handleChange()}
                            inputRef={heightInput}
                            intent={heightError ? Intent.DANGER : undefined}
                        />
                    </div>
                </div>
                <div className={styles.MediaImageUrlResult}>
                    <Pre
                        className={ClassNames(
                            [
                                "wrap-code",
                                "clickable",
                                widthError || heightError ? "error" : ""
                            ])}
                        style={{margin: 0}}
                        onClick={handleCopy}
                    >
                        <Code>
                            <If condition={!widthError && !heightError}>
                                {`https://${lookupStore.tenantInfo?.hostName.split(";")[0]}${generatedUrl}`}
                            </If>
                            <If condition={widthError || heightError}>
                                {generatedUrl}
                            </If>
                        </Code>
                    </Pre>
                </div>
            </div>
        </>
    )
}

export default observer(ImageUrlGeneratorForm);