import {useRootStore} from "../../../../../core/stores/root-store";
import {
    MediaLibraryDialogs,
    SelectedItemType,
    useMediaLibraryStore
} from "../../../../../core/stores/media-library-store";
import {BusyStates} from "../../../../../core/utilities/busy-states";
import {Menu, MenuItem} from "@ewstheme/core";
import If from "../../../../shared/utilities/if-component";
import {BiTrash, BiUndo} from "react-icons/bi";
import {observer} from "mobx-react";

interface ContentContextMenuProps {
    mediaItemId: string;
}

const ContentContextMenu : React.FC<ContentContextMenuProps> = props => {
    const {busyStore, toastStore} = useRootStore();
    const {store} = useMediaLibraryStore();

    const onMoveClicked = () => {
        if (!store.selectedItems.find(x => x.id === props.mediaItemId && x.type === SelectedItemType.Folder)) {
            store.addSelectedItem({
                id: props.mediaItemId,
                type: SelectedItemType.File
            });
        }
        store.setCurrentDialog(MediaLibraryDialogs.MoveItems);
    }
    
    const onDeleteClicked = () => {
        if (!store.selectedItems.find(x => x.id === props.mediaItemId && x.type === SelectedItemType.Folder)) {
            store.addSelectedItem({
                id: props.mediaItemId,
                type: SelectedItemType.File
            });
        }
        store.setCurrentDialog(MediaLibraryDialogs.DeleteItems);
    }

    const onRestoreClicked = () => {
        if (!store.selectedItems.find(x => x.id === props.mediaItemId && x.type === SelectedItemType.Folder)) {
            store.addSelectedItem({
                id: props.mediaItemId,
                type: SelectedItemType.File
            });
        }

        busyStore.setBusyState(BusyStates.MediaLibrary);
        store.restoreSelected()
            .then(() => {
                if (store.currentDirectory) {
                    store.setDirectory(store.currentDirectory.directoryId);
                }
                toastStore.showToast({
                    message: "Selected files and folders restored successfully",
                    intent: "success",
                    icon: "tick"
                });
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.MediaLibrary);
            });
    }
    
    return (
        <Menu>
            <If condition={store.currentDirectory?.directoryId !== "RecyclingBin"}>
                <MenuItem
                    icon={"add-to-folder"}
                    text={store.selectedItems.length > 1 ? "Move selected" : "Move"}
                    onClick={onMoveClicked}
                />
                <MenuItem
                    icon={<BiTrash className={"box-icon"}/>}
                    text={store.selectedItems.length > 1 ? "Delete selected" : "Delete"}
                    onClick={onDeleteClicked}
                />
            </If>
            <If condition={store.currentDirectory?.directoryId === "RecyclingBin"}>
                <MenuItem
                    icon={<BiUndo className={"box-icon"}/>}
                    text={store.selectedItems.length > 1 ? "Restore selected" : "Restore"}
                    onClick={onRestoreClicked}
                />
            </If>
        </Menu>
    )
}

export default observer(ContentContextMenu);