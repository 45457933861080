import {observer} from "mobx-react";
import EwsActionsItem from "../../shared/ews-workarea/components/ews-actions-item";
import {MediaLibraryDialogs, SelectedItemType, useMediaLibraryStore} from "../../../core/stores/media-library-store";
import {BiCheckbox, BiRename, BiSelectMultiple, BiTrash, BiUndo} from "react-icons/bi";
import EwsActionsDivider from "../../shared/ews-workarea/components/ews-actions-divider";
import {BusyStates} from "../../../core/utilities/busy-states";
import {useRootStore} from "../../../core/stores/root-store";
import If from "../../shared/utilities/if-component";
import StorageUsageDialog from "./components/dialogs/storage-usage-dialog";

const Actions: React.FC = () => {
    const {busyStore, toastStore} = useRootStore();
    const {store} = useMediaLibraryStore();

    const onRenameClicked = () => {
        if (store.selectedItems[0].type === SelectedItemType.Folder) {
            store.setCurrentDialog(MediaLibraryDialogs.RenameFolder)
        }
    }

    const handleDownloadClicked = () => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        store.downloadSelectedFiles().then(() => {
            busyStore.removeBusyState(BusyStates.MediaLibrary);
        });
    }
    
    const handleRestoreClicked = () => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        store.restoreSelected()
            .then(() => {
                if (store.currentDirectory) {
                    store.setDirectory(store.currentDirectory.directoryId);
                }
                toastStore.showToast({
                    message: "Selected files and folders restored successfully",
                    intent: "success",
                    icon: "tick"
                });
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.MediaLibrary);
            });
    }

    return (
        <>
            <If condition={!store.hasErrored}>
                <EwsActionsItem
                    icon={<BiSelectMultiple className={"box-icon"}/>}
                    text={"Select all"}
                    onClick={() => store.selectAllItems()}
                />
                <EwsActionsItem
                    icon={<BiCheckbox className={"box-icon"}/>}
                    text={"Clear selection"}
                    onClick={() => store.clearSelection()}
                />
                <EwsActionsDivider/>
                <EwsActionsItem
                    icon={"media"}
                    text={"Add media"}
                    onClick={() => store.setCurrentDialog(MediaLibraryDialogs.AddFiles)}
                    disabled={store.currentDirectory?.directoryId === "RecyclingBin"}
                />
                <EwsActionsItem
                    icon={"download"}
                    text={"Download files"}
                    onClick={handleDownloadClicked}
                    disabled={!store.anySelectedFiles}
                />
                <EwsActionsItem
                    icon={"search"}
                    text={"Search media"}
                    onClick={() => {
                        store.setCurrentDialog(MediaLibraryDialogs.Search)
                    }}
                />
                <EwsActionsDivider/>
                <EwsActionsItem
                    icon={"folder-new"}
                    text={"New folder"}
                    onClick={() => {
                        store.setCurrentDialog(MediaLibraryDialogs.NewFolder);
                    }}
                    disabled={store.currentDirectory?.directoryId === "RecyclingBin"}
                />
                <EwsActionsItem
                    icon={"add-to-folder"}
                    text={"Move selected"}
                    disabled={store.selectedItems.length < 1 || store.currentDirectory?.directoryId === "RecyclingBin"}
                    onClick={() => {
                        store.setCurrentDialog(MediaLibraryDialogs.MoveItems);
                    }}
                />
                <EwsActionsItem
                    icon={<BiRename className={"box-icon"}/>}
                    text={"Rename"}
                    onClick={onRenameClicked}
                    disabled={store.selectedItems.length !== 1 || store.currentDirectory?.directoryId === "RecyclingBin"}
                />
                <If condition={store.currentDirectory?.directoryId !== "RecyclingBin"}>
                    <EwsActionsItem
                        icon={<BiTrash className={"box-icon"}/>}
                        text={"Delete selected"}
                        disabled={store.selectedItems.length < 1}
                        onClick={() => {
                            store.setCurrentDialog(MediaLibraryDialogs.DeleteItems);
                        }}
                    />
                </If>

                <If condition={store.currentDirectory?.directoryId === "RecyclingBin"}>
                    <EwsActionsItem
                        icon={<BiUndo className={"box-icon"}/>}
                        text={"Restore selected"}
                        disabled={store.selectedItems.length < 1}
                        onClick={handleRestoreClicked}
                    />
                </If>

                <EwsActionsDivider/>
                
                <EwsActionsItem
                    icon={"doughnut-chart"}
                    text={"View storage usage"}
                    onClick={() => {store.setCurrentDialog(MediaLibraryDialogs.StorageUsage)}}
                />
                
                <StorageUsageDialog />
            </If>
        </>
    )
}

export default observer(Actions);