import {observer} from "mobx-react";
import EwsActionsItem from "../../shared/ews-workarea/components/ews-actions-item";
import {EwsMobxEditModes, useEwsMobxContext} from "../../../core/stores/ews-mobx-store";
import {SearchUserRequest, UserListItemResult} from "@ews-turing-models/clientmodels/dist";
import EwsHeading from "../../shared/ews-typography/ews-heading";
import FilterForm from "./components/filter-form";

const Actions: React.FC = () => {
    const store = useEwsMobxContext<UserListItemResult, SearchUserRequest>();
    
    const handleCreateClick = () => {
        store.clearSelection();
        store.setEditMode(EwsMobxEditModes.Create);
    }
    
    const handleEditClick =() => {
        store.setEditMode(EwsMobxEditModes.Edit);
    }
    
    const handleDeleteClick = () => {
        store.setEditMode(EwsMobxEditModes.Delete);
    }
    
    return (
        <>
            <EwsActionsItem
                icon="new-person"
                text="Create new user"
                onClick={handleCreateClick}
            />
            <EwsActionsItem
                icon="person"
                text="Edit user"
                disabled={!(store.selectedItem && !store.selectedItem.isDeleted)}
                onClick={handleEditClick}
            />
            <EwsActionsItem
                icon="blocked-person"
                text="Delete user"
                disabled={!(store.selectedItem && !store.selectedItem.isDeleted)}
                onClick={handleDeleteClick}
            />
            <EwsActionsItem
                icon="history"
                text="View change history"
                disabled={store.selectedItem === undefined}
            />
            <EwsActionsItem
                icon="circle"
                text="Clear selection"
                disabled={store.selectedItem === undefined}
            />
            
            <div style={{marginTop: "2rem"}}>
                <EwsHeading text="Filter" variant="h4" />
                <FilterForm />
            </div>
            
        </>
    );
}

export default observer(Actions);