import {FormikProps, FormikValues} from "formik";
import {Button, Intent} from "@ewstheme/core";
import {observer} from "mobx-react";

interface EditorDialogActionsProps<T> {
    formProps: FormikProps<FormikValues & T>;
    onCancelAction: () => void;
    cancelText?: string;
    saveText?: string;
    Intent?: Intent;
}

const EditorDialogActions: <T>(props : EditorDialogActionsProps<T>) => React.ReactElement<EditorDialogActionsProps<T>> = props => {
    return (
        <div className='form-action-container'>
            <Button
                type='button'
                onClick={props.onCancelAction} >{props.cancelText ?? 'Cancel'}</Button>
            <Button
                intent={props.Intent ?? Intent.PRIMARY}
                disabled={!props.formProps.isValid}
                type='submit'
            >{props.saveText ?? 'Save'}</Button>
        </div>
    )
}

export default observer(EditorDialogActions);