import {HotkeyConfig, HotkeysProvider, HotkeysTarget2} from "@ewstheme/core";
import {observer} from "mobx-react";

interface EwsHotkeysProps {
    hotKeys: HotkeyConfig[];
    children: JSX.Element | JSX.Element[];
}

const EwsHotkeys : React.FC<EwsHotkeysProps> = props => {
    return (
        <HotkeysProvider>
            <HotkeysTarget2 hotkeys={props.hotKeys}>
                <>
                    {props.children}
                </>
            </HotkeysTarget2>
        </HotkeysProvider>
    )
}

export default observer(EwsHotkeys);