import {observer} from "mobx-react";
import {MediaLibraryDialogs, SelectedItemType, useMediaLibraryStore} from "../../../../core/stores/media-library-store";
import {SortByProperty} from "../../../../core/utilities/sorting-util";
import styles from "../styles.module.scss";
import {ClassNames} from "../../../../core/utilities/react-utils";
import If from "../../../shared/utilities/if-component";
import {Checkbox} from "@ewstheme/core";
import DirectoryItem from "./directory-item";
import {ChildDirectory, ContentItems} from "@ews-turing-models/clientmodels/dist";
import DirectoryContextMenu from "./menus/directory-context-menu";
import {ContextMenu2, ContextMenu2ChildrenProps} from "@ewstheme/popover2";
import ContentItem from "./content-item";
import ContentContextMenu from "./menus/content-context-menu";

const DirectoryViewer: React.FC = () => {
    const {store} = useMediaLibraryStore();

    const viewClass = () => {
        switch (store.viewMode) {
            case "grid-view":
                return styles.GridView;
            case "list":
                return styles.List;
            default:
                return "";
        }
    }

    const handleSelectorCellClick = () => {
        if (store.allSelected) {
            store.clearSelection();
        } else {
            store.selectAllItems();
        }
    }

    const FolderItem = observer((props: { item: ChildDirectory }) => {
        const isSelected = store.selectedItems.find(x =>
            x.id === props.item.directoryId &&
            x.type === SelectedItemType.Folder) !== undefined;

        const onClickHandler = (isContextOpen: boolean) => {
            if (!isContextOpen && !store.isViewingRecyclingBin) {
                store.setDirectory(props.item.directoryId);
            } else if (!isContextOpen && store.isViewingRecyclingBin) {
                store.setDeletedFolderView(props.item.directoryId);
                store.setCurrentDialog(MediaLibraryDialogs.DeletedFolderInfo);
            }
        }

        return (
            <ContextMenu2
                content={<DirectoryContextMenu directoryId={props.item.directoryId}/>}
            >
                {(ctxMenuProps: ContextMenu2ChildrenProps) => (
                    <div
                        className={ClassNames(
                            [
                                styles.ViewItem,
                                viewClass(),
                                isSelected ? styles.FolderSelected : "",
                                ctxMenuProps.className
                            ])}
                        onClick={() => onClickHandler(ctxMenuProps.contentProps.isOpen)}
                        onContextMenu={ctxMenuProps.onContextMenu}
                        ref={ctxMenuProps.ref}
                    >
                        {ctxMenuProps.popover}
                        <DirectoryItem item={props.item}/>
                    </div>
                )}
            </ContextMenu2>
        )
    });

    const FileItem = observer((props: { item: ContentItems }) => {
        const isSelected = store.selectedItems.find(x =>
            x.id === props.item.mediaId &&
            x.type === SelectedItemType.File) !== undefined;
        
        const onClickHandler = (isContextOpen: boolean) => {
            if(!isContextOpen && !store.isViewingRecyclingBin) {
                store.clearSelection();
                store.addSelectedItem({
                    id: props.item.mediaId,
                    type: SelectedItemType.File
                });
                store.setCurrentDialog(MediaLibraryDialogs.ViewContent);
            } else if (!isContextOpen && store.isViewingRecyclingBin){
                store.setDeletedFileView(props.item.mediaId);
                store.setCurrentDialog(MediaLibraryDialogs.DeletedFileInfo);
            }
        }
        
        return (
            <ContextMenu2 
                content={<ContentContextMenu mediaItemId={props.item.mediaId} />}
            >
                {(ctxMenuProps: ContextMenu2ChildrenProps) => (
                    <div className={ClassNames(
                        [
                            styles.ViewItem,
                            viewClass(),
                            isSelected ? styles.FolderSelected : "",
                            ctxMenuProps.className
                        ])}
                        onClick={() => onClickHandler(ctxMenuProps.contentProps.isOpen)}
                        onContextMenu={ctxMenuProps.onContextMenu}
                        ref={ctxMenuProps.ref}
                    >
                        {ctxMenuProps.popover}
                        <ContentItem item={props.item} />
                    </div>
                )}
            </ContextMenu2>
        )
    });

    return (
        <>
            <If condition={store.viewMode === "list"}>
                <div className={styles.ViewHeader}>
                    <div className={styles.SelectorCell}>
                        <Checkbox
                            checked={store.allSelected}
                            onClick={handleSelectorCellClick}
                        />
                    </div>
                    <div className={styles.TitleCell}>Title</div>
                    <div className={styles.MediaTypeCell}>Media Type</div>
                    <div className={styles.FileSizeCell}>Size</div>
                    <div className={styles.UploadDateCell}>Upload Date</div>
                </div>
            </If>
            <div
                className={ClassNames([styles.ViewContainer, store.viewMode === "grid-view" ? styles.WithSpacing : ""])}>
                
                {store.currentDirectory?.childDirectories.slice().sort(SortByProperty("directoryName")).map(item => (
                    <FolderItem
                        key={`directoryItem_${item.directoryId}`}
                        item={item}
                    />
                ))}
                {store.currentDirectory?.folderContents.slice().sort(SortByProperty("title")).map(item => (
                    <FileItem
                        key={`fileItem_${item.mediaId}`}
                        item={item}
                    />
                ))}
                <If
                    condition={store.currentDirectory != undefined &&
                        store.currentDirectory.childDirectories.length === 0 &&
                        store.currentDirectory.folderContents.length === 0}
                >
                    <div className={styles.EmptyFolderLabel}>
                        <If condition={store.currentDirectory?.directoryId !== "RecyclingBin"}>
                            <div>
                                <img src="/img/Empty-Folder.svg" width={200} alt={"Empty folder"}/>
                            </div>
                            <span>This folder is empty.<br/>To add files, use the 'Add Media' menu option.</span>
                        </If>
                        <If condition={store.currentDirectory?.directoryId == "RecyclingBin"}>
                            <span>Recycling bin is empty</span>
                        </If>
                    </div>
                </If>
            </div>
        </>
    )
}

export default observer(DirectoryViewer);