import {CSSProperties, useRef, useState} from "react";
import {useIntersection} from "./hooks/intersection-observer";
import {ClassNames} from "../../../core/utilities/react-utils";

interface EwsBgImageProps {
    url: string;
    classNames?: string;
    style?: CSSProperties;
}

const EwsBgImage : React.FC<EwsBgImageProps> = props => {
    const [isInView, setIsInView] = useState(false);
    const imgRef = useRef<HTMLDivElement>(null);
    useIntersection(imgRef, () => {
        setIsInView(true);
    });

    return (
        <div
            className={ClassNames(["ews-bg-image-container", props.classNames ?? ""])}
            ref={imgRef}
            style={{
                ...props.style
            }}
        >
            {isInView && (

                <img
                    className='ews-bg-image'
                    src={props.url}
                />

            )}
        </div>
    );
};

export default EwsBgImage;