import {
    ApiResult, ConfirmResetRequest,
    PasswordResetRequest,
    UserLoginRequest,
    UserLoginResult,
    UserLoginTenantResult
} from "@ews-turing-models/clientmodels/dist";
import ApiUtil from "../utilities/api-util";

const ApiUrl = 'Authentication/';

const AuthenticationApi = {
    getAvailableTenants: (model: UserLoginRequest) => ApiUtil.post<ApiResult<UserLoginTenantResult>>(`${ApiUrl}GetAvailableTenants`, model),
    login: (model: UserLoginRequest) => ApiUtil.post<ApiResult<UserLoginResult>>(`${ApiUrl}Login`, model),
    refreshToken: () => ApiUtil.post<ApiResult<UserLoginResult>>(`${ApiUrl}RefreshToken`),
    logout: () => ApiUtil.post(`${ApiUrl}Logout`),
    resetPassword: (model: PasswordResetRequest) => ApiUtil.post(`${ApiUrl}ResetPassword`, model),
    getConfirmPasswordReset: (model: {token: string}) => ApiUtil.get<ApiResult<boolean>>(`${ApiUrl}ConfirmPasswordReset`, model),
    postConfirmPasswordReset: (model: ConfirmResetRequest) => ApiUtil.post(`${ApiUrl}ConfirmPasswordReset`, model)
};

export default AuthenticationApi;