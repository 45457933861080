import {observer} from "mobx-react";
import {Overlay, Spinner} from "@ewstheme/core";
import If from "../utilities/if-component";
import EwsHeading from "../ews-typography/ews-heading";

export enum LoadingComponentDisplayStyle {
    FullScreen,
    InLine
}

interface EwsLoadingProps {
    message?: string;
    displayStyle?: LoadingComponentDisplayStyle;
    noMessage?: boolean;
}

const EwsLoading: React.FC<EwsLoadingProps> = ({
                                                   message = 'Loading...',
                                                   displayStyle = LoadingComponentDisplayStyle.FullScreen,
                                                   ...props
                                               }) => {

    return (
        <>
            <If condition={displayStyle === LoadingComponentDisplayStyle.InLine}>
                <div className={"ews-loading--container-inline"}>
                    <div className={"ews-loading--wrapper"}>
                        <div className={"ews-loading--item"}>
                            <Spinner intent='primary' size={50}/>
                        </div>
                        <If condition={props.noMessage === true}>
                            <div className={"ews-loading--item"}>
                                <EwsHeading variant="h3" text={message}/>
                            </div>
                        </If>
                    </div>
                </div>
            </If>
            <If condition={displayStyle === LoadingComponentDisplayStyle.FullScreen}>
                <Overlay
                    className={"ews-loading--container"}
                    canEscapeKeyClose={false}
                    canOutsideClickClose={false}
                    enforceFocus={true}
                    hasBackdrop={true}
                    isOpen={true}
                >
                    <div className={"ews-loading--wrapper"}>
                        <div className={"ews-loading--item"}>
                            <Spinner intent='primary' size={150}/>
                        </div>
                        <If condition={props.noMessage === true}>
                            <div className={"ews-loading--item"}>
                                <EwsHeading variant="h1" text={message}/>
                            </div>
                        </If>
                    </div>
                </Overlay>
            </If>
        </>
    )
}

export default observer(EwsLoading);