import {observer} from "mobx-react";
import {useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import EwsDropdown from "../../../../shared/ews-dropdown";
import {MenuItem2} from "@ewstheme/popover2";

const ViewModeMenu : React.FC = () => {
    const {store} = useMediaLibraryStore();
    
    return (
        <EwsDropdown
            icon={store.viewMode}
            minimal
            intent={"primary"}
        >
            <MenuItem2
                icon={"grid-view"}
                active={store.viewMode === "grid-view"}
                onClick={() => store.setViewMode("grid-view")}
                text={"Grid View"}
            />
            <MenuItem2
                icon={"list"}
                active={store.viewMode === "list"}
                onClick={() => store.setViewMode("list")}
                text={"list"}
            />
        </EwsDropdown>
    )
}

export default observer(ViewModeMenu);