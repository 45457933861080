interface EwsTextProps {
    textType?: "ui" | "monospace" | "running";
    size?: "small" | "large";
    muted?: boolean;
    disabled?: boolean;
    ellipsis?: boolean;
    children: JSX.Element | JSX.Element[] | string
}

export const EwsText: React.FC<EwsTextProps> = props => {
    let textTypeCSS = "bp4-ui-text ";
    let sizeCSS = "";
    const mutedCSS = props.muted ? "bp4-text-muted " : "";
    const disabledCSS = props.disabled ? "bp4-text-disabled " : "";
    const ellipsisCSS = props.ellipsis ? "bp4-text-overflow-ellipsis " : "";

    switch(props.textType){
        case "monospace": textTypeCSS = "bp4-monospace-text "; break;
        case "running": textTypeCSS = "bp4-running-text "; break;
    }

    switch(props.size){
        case "small": sizeCSS = "bp4-text-small "; break;
        case "large": sizeCSS = "bp4-text-large "; break;
    }

    return (
        <div className={`${textTypeCSS}${sizeCSS}${mutedCSS}${disabledCSS}${ellipsisCSS}`}>{props.children}</div>
    )
}