import {UserLoginRequest, UserLoginTenantResult} from "@ews-turing-models/clientmodels/dist";
import EwsInput from "../../../shared/ews-form/components/ews-input";
import EwsPasswordInput from "../../../shared/ews-form/components/ews-password-input";
import EwsForm from "../../../shared/ews-form";
import * as Yup from "yup";
import AuthenticationApi from "../../../../core/api/authentication-api";
import {observer} from "mobx-react";
import {EwsFormErrorHandler} from "../../../shared/ews-form/handlers/EwsFormErrorHandler";
import {FormikHelpers, FormikValues} from "formik";
import {useRootStore} from "../../../../core/stores/root-store";
import {Button} from "@ewstheme/core";
import {BusyStates} from "../../../../core/utilities/busy-states";

interface CredentialCaptureProps {
    onCredentialsValid: (credentials: UserLoginRequest, tenantResults: UserLoginTenantResult[]) => void;
    onPasswordResetClick: () => void;
}

const CredentialCapture: React.FC<CredentialCaptureProps> = props => {

    const {busyStore} = useRootStore();

    const loginModel: UserLoginRequest = {
        emailAddress: '',
        password: ''
    };

    const validationSchema = Yup.object().shape({
        emailAddress: Yup.string().required("Email address is required").email("Email address is not valid"),
        password: Yup.string().required("Password is required")
    });

    const handleSubmit = (values: FormikValues & UserLoginRequest, actions: FormikHelpers<FormikValues & UserLoginRequest>) => {
        busyStore.setBusyState(BusyStates.LoginForm);
        AuthenticationApi.getAvailableTenants(values)
            .then(results => {
                props.onCredentialsValid(values, results.model);
            }).catch(error => {
            EwsFormErrorHandler({error, actions});
        }).finally(() => {
            busyStore.removeBusyState(BusyStates.LoginForm);
        });
    }

    return (
        <>
            <EwsForm<UserLoginRequest>
                initialValues={loginModel}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
            >
                {props => (
                    <>
                        <EwsInput
                            label='Email Address'
                            placeholder='someone@example.com'
                            required={true}
                            formikName='emailAddress'
                        />
                        <EwsPasswordInput
                            label='Password'
                            required={true}
                            formikName='password'
                            showSecurityLevelBar={false}
                            showSecurityLevelDescription={false}
                            skipStrengthValidation
                        />
                        <Button
                            intent='primary'
                            disabled={!props.isValid}
                            type='submit'
                            fill
                        >Login</Button>
                    </>
                )}
            </EwsForm>
            <a
                onClick={props.onPasswordResetClick}
                style={{display: 'inline-block', marginTop: "10px"}}
            >
                Forgotten Password?
            </a>
        </>
    )
}

export default observer(CredentialCapture);