import {BreadcrumbProps, Breadcrumbs2} from "@ewstheme/popover2";
import {observer} from "mobx-react";
import {useMediaLibraryStore} from "../../../../core/stores/media-library-store";
import If from "../../../shared/utilities/if-component";
import {Boundary, Breadcrumb, Colors, Intent} from "@ewstheme/core";
import {SortByProperty} from "../../../../core/utilities/sorting-util";

const DirectoryBreadcrumb : React.FC = () => {
    const {store} = useMediaLibraryStore();
    
    const items: BreadcrumbProps[] = store.currentDirectory?.breadcrumb.slice().sort(SortByProperty("-levelFromChild")).map(item => {
        return {
            text: item.directoryName.replace("ROOT","Home"),
            current: item.directoryId === store.currentDirectory?.directoryId,
            onClick: item.directoryId !== store.currentDirectory?.directoryId ? () => {store.setDirectory(item.directoryId)} : undefined
        } as BreadcrumbProps
    }) ?? [];
    
    const renderItem = ({text, ...restProps}: BreadcrumbProps) => {
        return <Breadcrumb {...restProps}>
            <span style={{whiteSpace: "nowrap"}}>{text}</span>
        </Breadcrumb>
    };

    const renderCurrentItem = ({text, ...restProps}: BreadcrumbProps) => {
        return <Breadcrumb {...restProps}>
            <span style={{whiteSpace: "nowrap", color: Colors.PURPLE3}} >{text}</span>
        </Breadcrumb>
    };
    
    return (
        <If condition={store.currentDirectory !== undefined}>
            <Breadcrumbs2
                collapseFrom={Boundary.START}
                items={items}
                overflowListProps={{alwaysRenderOverflow: false}}
                breadcrumbRenderer={renderItem}
                currentBreadcrumbRenderer={renderCurrentItem}
            />
        </If>
    )
}

export default observer(DirectoryBreadcrumb);