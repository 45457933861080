import {
    ApiResult,
    CreateUserRequest, DeleteUserRequest, EditUserRequest,
    SearchUserRequest,
    TenantResult,
    UserListItemResult
} from "@ews-turing-models/clientmodels/dist";

import ApiUtil from "../utilities/api-util";

const ApiUrl = 'UserManagement/';

const UserManagementApi = {
    getUserAccounts: (model?: SearchUserRequest) => ApiUtil.get<ApiResult<UserListItemResult[]>>(`${ApiUrl}GetUserAccounts`, model),
    createUserAccount: (model : CreateUserRequest) => ApiUtil.post<ApiResult<boolean>>(`${ApiUrl}CreateUserAccount`, model),
    getUpdateUserAccount: (model: {userId: string}) => ApiUtil.get<ApiResult<EditUserRequest>>(`${ApiUrl}UpdateUserAccount`, model),
    postUpdateUserAccount: (model: EditUserRequest) => ApiUtil.post<ApiResult<boolean>>(`${ApiUrl}UpdateUserAccount`, model),
    deleteUserAccount: (model: DeleteUserRequest) => ApiUtil.post<ApiResult<boolean>>(`${ApiUrl}DeleteUserAccount`, model)
};

export default UserManagementApi;