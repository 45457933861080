import {observer} from "mobx-react";
import {DropEvent, FileRejection, useDropzone} from "react-dropzone";
import EwsHeading from "../../../../shared/ews-typography/ews-heading";
import {Button, Callout, Colors, Intent, ProgressBar} from "@ewstheme/core";
import {ClassNames, ColorFromIntent, FormatBytes} from "../../../../../core/utilities/react-utils";
import {acceptedFileTypes, fileValidator} from "../../helpers/file-upload-helpers";
import {useState} from "react";
import If from "../../../../shared/utilities/if-component";
import styles from "../../styles.module.scss";
import {useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import MediaLibraryApi from "../../../../../core/api/media-library-api";
import {useRootStore} from "../../../../../core/stores/root-store";

interface UploadFilesFormProps {
    fileUploadCallback: () => void;
}

const UploadFilesForm: React.FC<UploadFilesFormProps> = props => {
    const {store} = useMediaLibraryStore();
    const {toastStore} = useRootStore();
    const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
    const [isUploading, setIsUploading] = useState<"not-started" | "in-progress" | "completed">("not-started");
    const [failedFiles, setFailedFiles] = useState<File[]>([]);

    const onFilesDrop = <T extends File>(acceptedFiles: T[], fileRejections: FileRejection[], event: DropEvent) => {
        let newArray: File[] = [];
        switch(isUploading){
            case "not-started":
                newArray = selectedFiles.concat(acceptedFiles);
                break;
            case "completed":
                newArray = acceptedFiles;
                setFailedFiles([]);
                setIsUploading("not-started");
                break;
        }
        setSelectedFiles(newArray);
    }

    const {
        acceptedFiles,
        fileRejections,
        getRootProps,
        getInputProps
    } = useDropzone({
        validator: fileValidator,
        onDrop: onFilesDrop,
        disabled: isUploading === 'in-progress'
    });

    const removeFile = (file: File) => {
        console.log(file);
        const newArray = selectedFiles.filter(x => x != file);
        console.log(newArray);
        setSelectedFiles(newArray);
    }
    
    const ProgressBarIntent = () => {
        switch (isUploading){
            case "in-progress": return "primary";
            case "completed" : return "success";
            default: return undefined;
        }
    }
    
    const ProgressBarValue = () => {
        switch(isUploading){
            case "in-progress":
            case "completed":
                return 1;
            default:
                return 0;
        }
    }
    
    const ProgressBarAnimatedOrStripped = () => {
        switch(isUploading) {
            case "completed": return false;
            default: return true;
        }
    }
    
    const handleUploadClick = async () => {
        setIsUploading("in-progress");
        let promises: any[] = [];

        selectedFiles.forEach(file => {
            const formData = new FormData();
            formData.append('directoryId', store.currentDirectory?.directoryId ?? '');
            formData.append('file', file);
            const fileUpload = MediaLibraryApi.uploadFile(formData)
                .then(() => {
                    toastStore.showToast({
                        message: `${file.name} uploaded successfully`,
                        icon: 'tick',
                        intent: 'success'
                    });
                })
                .catch(error => {
                    console.log(error);
                    const newFailedFilesArray = failedFiles.concat(file);
                    setFailedFiles(newFailedFilesArray);
                    toastStore.showToast({
                        message: `${file.name} failed to upload`,
                        icon: 'cross',
                        intent: 'danger'
                    });
                });
            promises.push(fileUpload);
        });
        
        if(promises.length > 0){
            Promise.all(promises).then(() => {
                setIsUploading("completed");
                props.fileUploadCallback();
            });
        }
    }

    return (
        <section className={"uploadContainer"}>
            <Callout
                intent={Intent.WARNING}
                icon={"warning-sign"}
                title={"Upload limits"}
                style={{marginBottom: ".25rem"}}
            >
                Each file must be less than 100MB in size.
            </Callout>
            <div {...getRootProps({className: "dropzone"})}>
                <input {...getInputProps()} />
                <EwsHeading
                    intent={"primary"}
                    variant={"h3"}
                    text={"Drag 'n' drop files to upload here, or click to select files"}
                    style={{opacity: 0.65}}
                />
            </div>
            <div>
                <If condition={selectedFiles.length > 0 || fileRejections.length > 0}>
                    <>
                        <div style={{marginTop: "0.5rem"}}>
                            <EwsHeading variant={"h4"} text={"Selected files"} />
                        </div>
                        {selectedFiles.map((file, index) => (
                            <div
                                key={`FileUploadAcceptedFile_${index}`}
                                className={styles.FileUploadDetails}
                            >
                                <div className={styles.FileUploadName}>
                                    {file.name} ({FormatBytes(file.size)})
                                </div>
                                <div className={styles.FileUploadProgress}>
                                    <If condition={failedFiles.some(x => x === file)}>
                                        <span className={styles.UploadError}>File failed to upload.</span>
                                    </If>
                                    <If condition={!failedFiles.some(x => x === file)}>
                                        <ProgressBar
                                            intent={ProgressBarIntent()}
                                            value={ProgressBarValue()}
                                            animate={ProgressBarAnimatedOrStripped()}
                                            stripes={ProgressBarAnimatedOrStripped()}
                                        />
                                    </If>                                    
                                </div>
                                <div className={styles.FileUploadRemove}>
                                    <If condition={isUploading === "not-started"}>
                                        <Button
                                            intent={"danger"}
                                            icon={"cross"}
                                            minimal
                                            small
                                            onClick={() => removeFile(file)}
                                        />
                                    </If>
                                </div>
                            </div>
                        ))}
                        {fileRejections.map(({file, errors}, index) => (
                            <div
                                key={`FileUploadRejectedFile_${index}`}    
                                className={styles.FileUploadDetails}
                            >
                                <div className={ClassNames(
                                    [
                                        styles.FileUploadName,
                                        styles.UploadError
                                    ])}>
                                    {file.name} ({FormatBytes(file.size)}) - {errors.map(e => e.message).join(" ")}
                                </div>
                            </div>
                        ))}
                    </>
                    <If condition={selectedFiles.length > 0}>
                        <div className={"ews-text--end"} style={{marginTop: "0.25rem"}}>
                            <Button
                                intent={"primary"}
                                text={"Start upload"}
                                onClick={handleUploadClick}
                            />
                        </div>
                    </If>
                </If>
            </div>
        </section>
    )
}

export default observer(UploadFilesForm);