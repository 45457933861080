import {BaseIndexResultItem, RoleResult, SearchRequest, SearchResult, TenantResult} from "@ews-turing-models/clientmodels/dist";

import ApiUtil from "../utilities/api-util";

const ApiUrl = 'Lookups/';

const LookupApi = {
    getTenant: () => ApiUtil.get<TenantResult>(`${ApiUrl}GetTenant`),
    getUserRoles: () => ApiUtil.get<RoleResult[]>(`${ApiUrl}GetUserRoles`),
    search: (model: SearchRequest) => ApiUtil.get<SearchResult<BaseIndexResultItem>>(`${ApiUrl}Search`, model)
};

export default LookupApi;