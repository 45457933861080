import {observer} from "mobx-react";

export interface EwsTheadProps extends React.HTMLAttributes<HTMLElement> {
    children: JSX.Element | JSX.Element[]
}

const EwsThead : React.FC<EwsTheadProps> = ({children, ...rest}) => {
    return (
        <thead {...rest}>
            {children}
        </thead>
    )
}

export default observer(EwsThead);