import {observer} from "mobx-react";
import {Button, Intent} from "@ewstheme/core";
import styles from "../styles.module.scss";
import ViewModeMenu from "./menus/view-mode-menu";
import {MediaLibraryDialogs, useMediaLibraryStore} from "../../../../core/stores/media-library-store";
import {useRootStore} from "../../../../core/stores/root-store";
import {BusyStates} from "../../../../core/utilities/busy-states";

const InlineControls : React.FC = () => {
    const {busyStore} = useRootStore();
    const {store} = useMediaLibraryStore();
    
    const handleDownloadClicked = () => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        store.downloadSelectedFiles().then(() => {
            busyStore.removeBusyState(BusyStates.MediaLibrary); 
        });
    }
    
    return (
        <div className={styles.InlineControlsContainer}>
            <div>
                <Button
                    icon={"folder-new"}
                    text={"New Folder"}
                    intent={Intent.PRIMARY}
                    onClick={() => {
                        store.setCurrentDialog(MediaLibraryDialogs.NewFolder);
                    }}
                    disabled={store.currentDirectory?.directoryId === "RecyclingBin"}
                />
            </div>
            <div>
                <Button
                    icon={"media"}
                    text={"Add Media"} 
                    intent={Intent.PRIMARY}
                    disabled={store.currentDirectory?.directoryId === "RecyclingBin"}
                    minimal
                    onClick={() => store.setCurrentDialog(MediaLibraryDialogs.AddFiles)}
                />
            </div>
            <div>
                <Button
                    icon={"download"}
                    text={"Download Files"}
                    intent={Intent.PRIMARY}
                    minimal
                    disabled={!store.anySelectedFiles}
                    onClick={handleDownloadClicked}
                />
            </div>
            <div>
                <Button
                    icon={"search"}
                    text={"Search"}
                    intent={Intent.PRIMARY}
                    minimal
                    onClick={() => store.setCurrentDialog(MediaLibraryDialogs.Search)}
                />
            </div>
            <div style={{marginLeft: "auto"}}>
                <ViewModeMenu />
            </div>
        </div>
    )
}

export default observer(InlineControls);