import {observer} from "mobx-react";
import {Button, Classes, Dialog, Intent} from "@ewstheme/core";
import {ClassNames, FormatMediaTypeValue} from "../../../../../core/utilities/react-utils";
import {MediaLibraryDialogs, useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import styles from "../../styles.module.scss";
import {SearchRequest} from "@ews-turing-models/clientmodels/dist/search-request";
import * as Yup from "yup";
import EwsForm from "../../../../shared/ews-form";
import {FormikHelpers, FormikValues} from "formik";
import EwsInput from "../../../../shared/ews-form/components/ews-input";
import EwsSelect from "../../../../shared/ews-form/components/ews-select";
import {useState} from "react";
import {EwsText} from "../../../../shared/ews-typography/ews-text";
import mediaLibraryApi from "../../../../../core/api/media-library-api";
import {MediaIndexResultItem, SearchResult} from "@ews-turing-models/clientmodels/dist";
import If from "../../../../shared/utilities/if-component";
import EwsTable from "../../../../shared/ews-table/components/ews-table";
import EwsThead from "../../../../shared/ews-table/components/ews-thead";
import EwsTr from "../../../../shared/ews-table/components/ews-tr";
import EwsTh from "../../../../shared/ews-table/components/ews-th";
import EwsTbody from "../../../../shared/ews-table/components/ews-tbody";
import EwsTd from "../../../../shared/ews-table/components/ews-td";

interface FormModel {
    term: string;
    sortBy: "_score" | "name-asc" | "name-desc"
    pageSize: number;
}

const model: SearchRequest = {
    term: "",
    pageNumber: 1,
    pageSize: 20,
    sortBy: "_score",
    asc: false
}

const SearchMediaDialog: React.FC = () => {
    const {store} = useMediaLibraryStore();
    const [searchQuery, setSearchQuery] = useState(model);

    const [canPage, setCanPage] = useState(false);
    const [searchResults, setSearchResults] = useState<MediaIndexResultItem[]>([]);
    const [currentResults, setCurrentResults] = useState<SearchResult<MediaIndexResultItem> | undefined>(undefined);


    const handleClose = () => {
        store.closeDialog();
    }

    const formInitialModel: FormModel = {
        term: "",
        sortBy: "_score",
        pageSize: 20
    }

    const validationSchema = Yup.object().shape({
        term: Yup.string().required("Search term is required"),
        pageSize: Yup.number().min(1).max(100)
    });

    const onSubmit = (values: (FormikValues & FormModel), actions: FormikHelpers<FormikValues & FormModel>) => {
        let newSearchQuery = Object.assign({}, searchQuery);
        newSearchQuery.term = values.term;
        newSearchQuery.pageSize = values.pageSize;
        switch (values.sortBy) {
            case "_score":
                newSearchQuery.sortBy = "_score";
                newSearchQuery.asc = false;
                break;
            case "name-asc":
                newSearchQuery.sortBy = "title";
                newSearchQuery.asc = true;
                break;
            case "name-desc":
                newSearchQuery.sortBy = "title";
                newSearchQuery.asc = false;
                break;
        }
        
        newSearchQuery.pageNumber = 1;

        setSearchQuery(newSearchQuery);

        performSearch(newSearchQuery, true);

    }

    const performSearch = (query?: SearchRequest, clearResults?: boolean) => {
        if (clearResults === true) setSearchResults([]);
        mediaLibraryApi.search(query?? searchQuery)
            .then(result => {
                loadSearchResults(result, query, clearResults);
            });
    }

    const loadSearchResults = (result: SearchResult<MediaIndexResultItem>, query?: SearchRequest, clearResults?: boolean) => {
        if (result.pageNumber < result.pageCount) {
            let newSearchQuery = Object.assign({}, query ?? searchQuery);
            newSearchQuery.pageNumber = result.pageNumber + 1;
            setSearchQuery(newSearchQuery);
            setCanPage(true);
        } else {
            setCanPage(false);
        }

        let newResultList = Object.assign([], clearResults === true ? [] : searchResults).concat(result.data);
        setSearchResults(newResultList);
        setCurrentResults(result);
    }
    
    const handleMediaItemClicked = (directoryId: string) => {
        store.setDirectory(directoryId);
        handleClose();
    }

    return (
        <Dialog
            className={ClassNames(["dialog-form", "dialog-form--primary"])}
            isOpen={store.currentDialog === MediaLibraryDialogs.Search}
            icon={"search"}
            title={"Search Media"}
            onClose={handleClose}
        >
            <div className={Classes.DIALOG_BODY}>
                <div style={{marginBottom: "0.25rem"}}>
                    <EwsText textType={"running"}>Search for media items and click on them to go to the items
                        directory.</EwsText>
                </div>
                <EwsForm<FormModel>
                    initialValues={formInitialModel}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {formProps => (
                        <>
                            <div className={styles.MediaSearchContainer}>
                                <div className={styles.MediaSearchText}>
                                    <EwsInput
                                        label={"Search"}
                                        placeholder={"Keywords..."}
                                        required={true}
                                        formikName={"term"}
                                    />
                                </div>
                                <div style={{width: 100}}>
                                    <EwsSelect
                                        label={"Page size"}
                                        formikName={"pageSize"}
                                        options={[
                                            {value: 5, label: "5"},
                                            {value: 20, label: "20"},
                                            {value: 40, label: "20"},
                                            {value: 60, label: "20"},
                                            {value: 80, label: "20"},
                                            {value: 1000, label: "20"},
                                        ]}
                                    />
                                </div>
                                <div>
                                    <EwsSelect
                                        label={"Sort by"}
                                        formikName={"sortBy"}
                                        options={[
                                            {value: "_score", label: "Relevance"},
                                            {value: "name-asc", label: "Name (ascending)"},
                                            {value: "name-desc", label: "Name (descending)"},
                                        ]}
                                    />
                                </div>
                            </div>
                            <div style={{textAlign: "right"}}>
                                <Button
                                    type={"submit"}
                                    disabled={!formProps.isValid}
                                    intent={Intent.PRIMARY}
                                    text={"Search"}
                                />
                            </div>
                        </>
                    )}
                </EwsForm>

                <div className={styles.MediaSearchResultsContainer}>
                    <If condition={currentResults !== undefined}>
                        <If condition={(currentResults?.count ?? 0) < 1}>
                            <div style={{textAlign: "center"}}>
                                No results found.
                            </div>
                        </If>
                        <If condition={(currentResults?.count ?? 0) > 0}>
                            <div style={{textAlign: "right", paddingBottom: "0.5rem"}}>
                                {currentResults?.count} results found.
                            </div>
                            <EwsTable>
                                <EwsThead>
                                    <EwsTr>
                                        <EwsTh>Title</EwsTh>
                                        <EwsTh>Media Type</EwsTh>
                                        <EwsTh>Location</EwsTh>
                                    </EwsTr>
                                </EwsThead>
                                <EwsTbody>
                                    <>
                                        {searchResults.map(item => (
                                            <EwsTr
                                                key={`ewsMediaSearchItem_${item.id}`}
                                                onClick={() => handleMediaItemClicked(item.directoryId)}
                                                style={{cursor: "pointer"}}
                                            >
                                                <EwsTd>{item.title}</EwsTd>
                                                <EwsTd>{FormatMediaTypeValue(item.mediaType)}</EwsTd>
                                                <EwsTd>{item.path.replace("ROOT\\","Home\\") }</EwsTd>
                                            </EwsTr>
                                        ))}
                                    </>
                                </EwsTbody>
                            </EwsTable>
                            <div style={{paddingTop: "0.5rem", paddingBottom: "0.5rem", textAlign: "center"}}>
                                <If condition={canPage}>
                                    <Button
                                        type={"button"}
                                        intent={Intent.PRIMARY}
                                        text={"Load more"}
                                        onClick={() => performSearch()}
                                    />
                                </If>
                            </div>
                        </If>
                    </If>
                </div>
            </div>
        </Dialog>
    )
}

export default observer(SearchMediaDialog);