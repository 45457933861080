import {useState} from "react";
import {Button, Colors, FormGroup, Icon, InputGroup, Intent, ProgressBar} from "@ewstheme/core";
import {useField} from "formik";
import uuid from "react-uuid";
import {Tooltip2} from "@ewstheme/popover2";
import {observer} from "mobx-react";
import If from "../../utilities/if-component";

interface EwsPasswordInputProps {
    showSecurityLevelBar?: boolean;
    showSecurityLevelDescription?: boolean;
    securityLevels?: SecurityLevelProps[];
    label: string;
    formikName: string;
    required?: boolean;
    skipStrengthValidation?: boolean;
}

interface SecurityLevelProps {
    descriptionLabel: string;
    validator: RegExp;
    isPassed?: boolean;
}

export const defaultSecurityLevels: SecurityLevelProps[] = [
    {
        descriptionLabel: 'Minimum of 8 characters',
        validator: /.{8,}/
    },
    {
        descriptionLabel: '1 lowercase letter',
        validator: /.*[a-z].*/
    },
    {
        descriptionLabel: '1 uppercase letter',
        validator: /.*[A-Z].*/
    },
    {
        descriptionLabel: '1 number',
        validator: /.*[0-9].*/
    },
    {
        descriptionLabel: '1 non alphanumeric character (e.g. !@#$%^*-_+=)',
        validator: /.*[^a-zA-Z\d\s:].*/
    }
]

const EwsPasswordInput : React.FC<EwsPasswordInputProps> = ({securityLevels = defaultSecurityLevels, ...props}) => {
    const [pwdVisible, setPwdVisible] = useState(false);
    const [levels, setLevels] = useState(securityLevels);
    const [pwdStrength, setPwdStrength] = useState<{ color: Intent, value: number, text: string }>({
        color: "danger",
        value: 0,
        text: ""
    });
    const [field, meta, ] = useField(
        {
            name: props.formikName,
            validate: (value) => {
                return handleValidate(value);
            }
        });

    const toggleVisibility = () => {
        setPwdVisible(!pwdVisible);
    }

    const handleValidate = (value: string) => {
        var levelsResult = securityLevels?.map((securityLevel) => {
            let isValid = false;
            if (securityLevel.validator) {
                isValid = securityLevel.validator.test(value) || props.skipStrengthValidation === true;
            }

            return {
                descriptionLabel: securityLevel.descriptionLabel,
                validator: securityLevel.validator,
                isPassed: isValid
            }
        });
        setLevels(levelsResult);
        let pwdResult = calculatePwdStrength(levelsResult);
        setPwdStrength(pwdResult)
        let error;
        if(props.required && !value && value === ''){
            error = 'Password is required'
            return error;
        }
        if(value && value !== '' && pwdResult.value < 1){
            error = 'Password is not strong enough';
            return error;
        }

        return error;
    }

    const calculatePwdStrength = (levelsResult: SecurityLevelProps[]): { color: Intent, value: number, text: string } => {
        let passedSecurityLevel = (levelsResult.filter(x => x.isPassed).length / levelsResult.length);
        if (passedSecurityLevel >= 0.99) return {color: 'success', value: passedSecurityLevel, text: "Strong"};
        if (passedSecurityLevel >= 0.8) return {color: 'warning', value: passedSecurityLevel, text: "Weak"};
        if (passedSecurityLevel >= 0.6) return {color: 'danger', value: passedSecurityLevel, text: "Weak"};
        return {color: 'danger', value: passedSecurityLevel, text: "Weak"};
    }

    const componentUUID = uuid();

    const lockButton = (
        <Tooltip2 content={`${pwdVisible ? "Hide" : "Show"} Password`}>
            <Button
                icon={pwdVisible ? "unlock" : "lock"}
                intent={Intent.WARNING}
                minimal={true}
                onClick={toggleVisibility}
            />
        </Tooltip2>
    );

    return (
        <FormGroup
            label={props.label}
            helperText={meta.touched && meta.error ? meta.error : undefined}
            labelFor={componentUUID}
            labelInfo={props.required && "(required)"}
            intent={meta.touched && meta.error ? 'danger' : undefined}
        >
            <InputGroup
                id={componentUUID}
                placeholder="Enter your password..."
                rightElement={lockButton}
                type={pwdVisible ? "text" : "password"}
                intent={meta.touched && meta.error ? 'danger' : undefined}
                onChange={field.onChange}
                onBlur={field.onBlur}
                value={field.value}
                name={field.name}
                fill
            />
            <If condition={props.showSecurityLevelBar === true}>
                <ProgressBar animate={false} stripes={false} intent={pwdStrength.color} value={pwdStrength.value}
                             className={"ews-password-field--strength"}/>
            </If>
            <If condition={props.showSecurityLevelDescription === true}>
                <>
                    {levels.map((securityLevel, index) => (
                        <div key={index}>
                            <span>
                                <Icon
                                    icon={securityLevel.isPassed ? "tick" : "cross"}
                                    intent={securityLevel.isPassed ? "success" : "danger"}/>
                            </span>
                            <span
                                style={{color: securityLevel.isPassed ? Colors.GREEN3 : Colors.RED3}}>{securityLevel.descriptionLabel}</span>
                        </div>
                    ))}
                </>
            </If>
        </FormGroup>
    )
}

export default observer(EwsPasswordInput);