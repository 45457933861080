import React, {useCallback, useEffect, useState} from 'react';
import {Route, Routes} from "react-router-dom";
import {AppPaths} from "./AppPaths";
import MainLayout from "./components/layouts/main-layout";
import LoggedOutLayout from "./components/layouts/logged-out-layout";
import Login from "./components/features/login";
import Dashboard from "./components/features/dashboard";
import {useRootStore} from "./core/stores/root-store";
import EwsLoading from "./components/shared/ews-loading";
import {Position, Toaster} from "@ewstheme/core";
import RestrictedRoute from "./components/shared/restricted-route";
import UserManagement from "./components/features/user-management";
import ResetPassword from "./components/features/login/reset-password";
import {observer} from "mobx-react";
import MediaLibrary from "./components/features/media-library";

function App() {
    const {authenticationStore, toastStore, lookupStore, signalrStore} = useRootStore();
    
    const [isLoading, setIsLoading] = useState(true);
    
    const initApp = useCallback(async () => {
        if (authenticationStore.token && authenticationStore.refreshToken) {
            await authenticationStore.refreshLogin()
        }
    }, [authenticationStore]);
    
    const initCache = useCallback(async () => {
        await lookupStore.init();
    }, [lookupStore]);
    
    useEffect(() => {
        initApp().then(() => setIsLoading(false));
    }, [initApp]);
    
    const initSignalR = useCallback(async () => {
        await signalrStore.init();
    }, [signalrStore])
    
    useEffect(() => {
        if(authenticationStore.isLoggedIn){
            initCache().then(() =>  initSignalR().then());          
            
        } else {
            lookupStore.clearCache();
        }
    }, [authenticationStore.isLoggedIn])
    
    if(isLoading || lookupStore.isLoading) return <EwsLoading message="Initialising app..." />;
    
    return (
        <>
            <Routes>
                <Route path={AppPaths.Home.Path} element={<MainLayout/>}>
                    <Route path="" element={<Dashboard />}/>
                </Route>
                <Route path={AppPaths.Login.Path} element={<LoggedOutLayout/>}>
                    <Route path="" element={<Login/>}/>
                    <Route path={AppPaths.Login.ResetPassword.Path} element={<ResetPassword/>}/>
                </Route>
                <Route path={AppPaths.MediaLibrary.Path} element={<MainLayout/>}>
                    <Route path='' element={<MediaLibrary />}/>
                </Route>
                <Route path={AppPaths.System.Path} element={<MainLayout/>}>
                    <Route path={AppPaths.System.Users.Path} element={
                        <RestrictedRoute
                            restrictedRoles={AppPaths.System.Users.RestrictedRoles}
                        >
                            <UserManagement />
                        </RestrictedRoute>}
                    />
                </Route>
            </Routes>
            <Toaster position={Position.TOP} maxToasts={5} ref={toastStore.toastRefHandler} />
        </>
    );
}

export default observer(App);
