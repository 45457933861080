import {observer} from "mobx-react";

export interface EwsTdProps extends React.TdHTMLAttributes<HTMLElement> {
    children?: JSX.Element | JSX.Element[] | string;
}

const EwsTd : React.FC<EwsTdProps> = ({children, ...rest}) => {
    return (
        <td {...rest}>
            {children}
        </td>
    )
}

export default observer(EwsTd);