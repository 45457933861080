import {observer} from "mobx-react";
import {useRootStore} from "../../../../core/stores/root-store";
import {PasswordResetRequest} from "@ews-turing-models/clientmodels/dist";
import * as Yup from "yup";
import {FormikHelpers, FormikValues} from "formik";
import EwsForm from "../../../shared/ews-form";
import EwsInput from "../../../shared/ews-form/components/ews-input";
import {Button} from "@ewstheme/core";
import AuthenticationApi from "../../../../core/api/authentication-api";
import {EwsFormErrorHandler} from "../../../shared/ews-form/handlers/EwsFormErrorHandler";
import {useState} from "react";
import If from "../../../shared/utilities/if-component";
import EwsInlineNotifications from "../../../shared/ews-inline-notifications";
import {BusyStates} from "../../../../core/utilities/busy-states";

interface PasswordResetProps {
    onBackToLoginClick: () => void;
}

const PasswordReset: React.FC<PasswordResetProps> = props => {
    const {busyStore} = useRootStore();
    const [submitted, setSubmitted] = useState(false);

    const passwordResetModel: PasswordResetRequest = {
        emailAddress: ''
    };

    const validationSchema = Yup.object().shape({
        emailAddress: Yup.string().required("Email address is required").email("Email address is not valid")
    });

    const handleSubmit = (values: FormikValues & PasswordResetRequest, actions: FormikHelpers<FormikValues & PasswordResetRequest>) => {
        busyStore.setBusyState(BusyStates.LoginForm);
        AuthenticationApi.resetPassword(values)
            .then(results => {
                setSubmitted(true);
            }).catch(error => {
            EwsFormErrorHandler({error, actions});
        }).then(() => {
            busyStore.removeBusyState(BusyStates.LoginForm);
        });
    }

    return (
        <>
            <If condition={!submitted}>
                <EwsForm<PasswordResetRequest>
                    initialValues={passwordResetModel}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {props => (
                        <>
                            <EwsInput
                                label='Email Address'
                                placeholder='someone@example.com'
                                required={true}
                                formikName='emailAddress'
                            />
                            <Button
                                intent='primary'
                                disabled={!props.isValid}
                                type='submit'
                                fill
                            >Submit</Button>
                        </>
                    )}
                </EwsForm>
            </If>
            <If condition={submitted}>
                <EwsInlineNotifications
                    intent="success"
                    title="Request Submitted"
                    message="If you have entered a valid email address, you will shortly receive and email to complete your request. The link in the email is valid for 5 minutes."
                    fill
                />
            </If>
            <a
                onClick={props.onBackToLoginClick}
                style={{display: 'inline-block', marginTop: "10px"}}
            >
                Back to login
            </a>
        </>
    )
}

export default observer(PasswordReset);