import {makeAutoObservable, reaction} from "mobx";

interface PersistenceValues {
    [key: string]: any;
}

interface ForgettableValues {
    [key: string]: { data: any, expiry: Date }
}

const userPreferencesKey = "ewsUserPreferences";
const forgettablePreferenceKey = "ewsForgettablePreferences"

export class SitePersistenceStore {
    persistenceValues: PersistenceValues = window.localStorage.getItem(userPreferencesKey) ? JSON.parse(window.localStorage.getItem(userPreferencesKey) ?? "") : {};
    forgettableValues: ForgettableValues = window.localStorage.getItem(forgettablePreferenceKey) ? JSON.parse(window.localStorage.getItem(forgettablePreferenceKey) ?? "") : {};

    constructor() {
        makeAutoObservable(this);
    }

    setPreference = (key: string, value: any) => {
        this.persistenceValues[key] = value;
        window.localStorage.setItem(userPreferencesKey, JSON.stringify(this.persistenceValues));
    }

    getPreference = (key: string) => {
        return this.persistenceValues[key];
    }
    
    resetPreference = () => {
        this.persistenceValues = {}
        window.localStorage.removeItem(userPreferencesKey);
    }

    togglePreference = (key: string, initialValue: true = true) => {
        const value = this.getPreference(key);
        if (!value) {
            this.setPreference(key, initialValue);
        } else if (typeof value === "boolean") {
            this.setPreference(key, !(this.getPreference(key) === true));
        }
    }

    setTempPreference = (key: string, data: any, durationInMinutes: number = 30) => {
        this.forgettableValues[key] = {data: data, expiry: new Date(Date.now() + durationInMinutes * 60000)};
        window.localStorage.setItem(forgettablePreferenceKey, JSON.stringify(this.forgettableValues));
    }
    
    resetTempPreference = () => {
        this.forgettableValues = {};
        window.localStorage.removeItem(forgettablePreferenceKey);
    }
    
    getTempPreference = (key: string) => {
        const value = this.forgettableValues[key];
        if(value && new Date(value.expiry) > new Date(Date.now())){
            return value.data
        }
        return undefined;
    }
    
    toggleTempPreference = (key: string, durationInMinutes: number = 30, initialValue: true = true) => {
        const value = this.forgettableValues[key];
        if (!value || (value && new Date(value.expiry) <= new Date(Date.now()))) {
            this.setTempPreference(key, initialValue, durationInMinutes)
        } else if (typeof value.data === "boolean"){
            this.setTempPreference(key, !(this.getTempPreference(key) === true), durationInMinutes)
        }
    }
}