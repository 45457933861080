import {ApiResult, RoleResult, TenantResult, UserLoginResult} from "@ews-turing-models/clientmodels/dist";
import {makeAutoObservable, runInAction} from "mobx";
import LookupApi from "../api/lookup-api";
import ApiUtil from "../utilities/api-util";

export class LookupStore {
    tenantInfo: TenantResult | undefined = undefined;
    hasInitialized: boolean = false;
    isLoading: boolean = false;
    hasErrored: boolean = false;

    userRoles: RoleResult[] | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    init = async () => {
        runInAction(() => {
            this.isLoading = true;
            this.hasErrored = false;
        })
        let promises = [];

        const getTenant = LookupApi.getTenant().then((result: TenantResult) => {
            runInAction(() => {
                this.tenantInfo = result
            });
        }).catch(error => {
            console.log(error);
            runInAction(() =>{ this.hasErrored = true})
        });
        
        promises.push(getTenant);

        await Promise.all(promises).then(() => {
            runInAction(() => {
                this.hasInitialized = true;
                this.isLoading = false;
            });
        })
    }

    clearCache = () => {
        this.tenantInfo = undefined;
        this.userRoles = undefined;
    }
    
    initUserManager = async () => {
        this.userRoles = await LookupApi.getUserRoles();
    }    
    
}