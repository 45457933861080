export const SortByProperty = (property: string) => {
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substring(1);
    }
    return function (a: any ,b: any) {
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
};

export const SortByMultipleProperties = (properties: string[]) => {
    return function (obj1: any, obj2: any) {
        var i = 0, result = 0, numberOfProperties = properties.length;
        while(result === 0 && i < numberOfProperties) {
            result = SortByProperty(properties[i])(obj1, obj2);
            i++;
        }
        return result;
    }
};