export const getIconClassFromExtension = (extension: string) => {
    switch (extension) {
        case "3g2": return "fiv-icon-3g2";
        case "3ga": return "fiv-icon-3ga";
        case "3gp": return "fiv-icon-3gp";
        case "7z": return "fiv-icon-7z";
        case "aa": return "fiv-icon-aa";
        case "aac": return "fiv-icon-aac";
        case "ac": return "fiv-icon-ac";
        case "accdb": return "fiv-icon-accdb";
        case "accdt": return "fiv-icon-accdt";
        case "ace": return "fiv-icon-ace";
        case "adn": return "fiv-icon-adn";
        case "ai": return "fiv-icon-ai";
        case "aif": return "fiv-icon-aif";
        case "aifc": return "fiv-icon-aifc";
        case "aiff": return "fiv-icon-aiff";
        case "ait": return "fiv-icon-ait";
        case "amr": return "fiv-icon-amr";
        case "ani": return "fiv-icon-ani";
        case "apk": return "fiv-icon-apk";
        case "app": return "fiv-icon-app";
        case "applescript": return "fiv-icon-applescript";
        case "asax": return "fiv-icon-asax";
        case "asc": return "fiv-icon-asc";
        case "ascx": return "fiv-icon-ascx";
        case "asf": return "fiv-icon-asf";
        case "ash": return "fiv-icon-ash";
        case "ashx": return "fiv-icon-ashx";
        case "asm": return "fiv-icon-asm";
        case "asmx": return "fiv-icon-asmx";
        case "asp": return "fiv-icon-asp";
        case "aspx": return "fiv-icon-aspx";
        case "asx": return "fiv-icon-asx";
        case "au": return "fiv-icon-au";
        case "aup": return "fiv-icon-aup";
        case "avi": return "fiv-icon-avi";
        case "axd": return "fiv-icon-axd";
        case "aze": return "fiv-icon-aze";
        case "bak": return "fiv-icon-bak";
        case "bash": return "fiv-icon-bash";
        case "bat": return "fiv-icon-bat";
        case "bin": return "fiv-icon-bin";
        case "blank": return "fiv-icon-blank";
        case "bmp": return "fiv-icon-bmp";
        case "bowerrc": return "fiv-icon-bowerrc";
        case "bpg": return "fiv-icon-bpg";
        case "browser": return "fiv-icon-browser";
        case "bz2": return "fiv-icon-bz2";
        case "bzempty": return "fiv-icon-bzempty";
        case "c": return "fiv-icon-c";
        case "cab": return "fiv-icon-cab";
        case "cad": return "fiv-icon-cad";
        case "caf": return "fiv-icon-caf";
        case "cal": return "fiv-icon-cal";
        case "cd": return "fiv-icon-cd";
        case "cdda": return "fiv-icon-cdda";
        case "cer": return "fiv-icon-cer";
        case "cfg": return "fiv-icon-cfg";
        case "cfm": return "fiv-icon-cfm";
        case "cfml": return "fiv-icon-cfml";
        case "cgi": return "fiv-icon-cgi";
        case "chm": return "fiv-icon-chm";
        case "class": return "fiv-icon-class";
        case "cmd": return "fiv-icon-cmd";
        case "code": return "fiv-icon-code";
        case "codekit": return "fiv-icon-codekit";
        case "coffee": return "fiv-icon-coffee";
        case "coffeelintignore": return "fiv-icon-coffeelintignore";
        case "com": return "fiv-icon-com";
        case "compile": return "fiv-icon-compile";
        case "conf": return "fiv-icon-conf";
        case "config": return "fiv-icon-config";
        case "cpp": return "fiv-icon-cpp";
        case "cptx": return "fiv-icon-cptx";
        case "cr2": return "fiv-icon-cr2";
        case "crdownload": return "fiv-icon-crdownload";
        case "crt": return "fiv-icon-crt";
        case "crypt": return "fiv-icon-crypt";
        case "cs": return "fiv-icon-cs";
        case "csh": return "fiv-icon-csh";
        case "cson": return "fiv-icon-cson";
        case "csproj": return "fiv-icon-csproj";
        case "css": return "fiv-icon-css";
        case "csv": return "fiv-icon-csv";
        case "cue": return "fiv-icon-cue";
        case "cur": return "fiv-icon-cur";
        case "dart": return "fiv-icon-dart";
        case "dat": return "fiv-icon-dat";
        case "data": return "fiv-icon-data";
        case "db": return "fiv-icon-db";
        case "dbf": return "fiv-icon-dbf";
        case "deb": return "fiv-icon-deb";
        case "default": return "fiv-icon-default";
        case "dgn": return "fiv-icon-dgn";
        case "dist": return "fiv-icon-dist";
        case "diz": return "fiv-icon-diz";
        case "dll": return "fiv-icon-dll";
        case "dmg": return "fiv-icon-dmg";
        case "dng": return "fiv-icon-dng";
        case "doc": return "fiv-icon-doc";
        case "docb": return "fiv-icon-docb";
        case "docm": return "fiv-icon-docm";
        case "docx": return "fiv-icon-docx";
        case "dot": return "fiv-icon-dot";
        case "dotm": return "fiv-icon-dotm";
        case "dotx": return "fiv-icon-dotx";
        case "download": return "fiv-icon-download";
        case "dpj": return "fiv-icon-dpj";
        case "ds_store": return "fiv-icon-ds_store";
        case "dsn": return "fiv-icon-dsn";
        case "dtd": return "fiv-icon-dtd";
        case "dwg": return "fiv-icon-dwg";
        case "dxf": return "fiv-icon-dxf";
        case "editorconfig": return "fiv-icon-editorconfig";
        case "el": return "fiv-icon-el";
        case "elf": return "fiv-icon-elf";
        case "eml": return "fiv-icon-eml";
        case "enc": return "fiv-icon-enc";
        case "eot": return "fiv-icon-eot";
        case "eps": return "fiv-icon-eps";
        case "epub": return "fiv-icon-epub";
        case "eslintignore": return "fiv-icon-eslintignore";
        case "exe": return "fiv-icon-exe";
        case "f4v": return "fiv-icon-f4v";
        case "fax": return "fiv-icon-fax";
        case "fb2": return "fiv-icon-fb2";
        case "fla": return "fiv-icon-fla";
        case "flac": return "fiv-icon-flac";
        case "flv": return "fiv-icon-flv";
        case "fnt": return "fiv-icon-fnt";
        case "folder": return "fiv-icon-folder";
        case "fon": return "fiv-icon-fon";
        case "gadget": return "fiv-icon-gadget";
        case "gdp": return "fiv-icon-gdp";
        case "gem": return "fiv-icon-gem";
        case "gif": return "fiv-icon-gif";
        case "gitattributes": return "fiv-icon-gitattributes";
        case "gitignore": return "fiv-icon-gitignore";
        case "go": return "fiv-icon-go";
        case "gpg": return "fiv-icon-gpg";
        case "gpl": return "fiv-icon-gpl";
        case "gradle": return "fiv-icon-gradle";
        case "gz": return "fiv-icon-gz";
        case "h": return "fiv-icon-h";
        case "handlebars": return "fiv-icon-handlebars";
        case "hbs": return "fiv-icon-hbs";
        case "heic": return "fiv-icon-heic";
        case "hlp": return "fiv-icon-hlp";
        case "hs": return "fiv-icon-hs";
        case "hsl": return "fiv-icon-hsl";
        case "htm": return "fiv-icon-htm";
        case "html": return "fiv-icon-html";
        case "ibooks": return "fiv-icon-ibooks";
        case "icns": return "fiv-icon-icns";
        case "ico": return "fiv-icon-ico";
        case "ics": return "fiv-icon-ics";
        case "idx": return "fiv-icon-idx";
        case "iff": return "fiv-icon-iff";
        case "ifo": return "fiv-icon-ifo";
        case "image": return "fiv-icon-image";
        case "img": return "fiv-icon-img";
        case "iml": return "fiv-icon-iml";
        case "in": return "fiv-icon-in";
        case "inc": return "fiv-icon-inc";
        case "indd": return "fiv-icon-indd";
        case "inf": return "fiv-icon-inf";
        case "info": return "fiv-icon-info";
        case "ini": return "fiv-icon-ini";
        case "inv": return "fiv-icon-inv";
        case "iso": return "fiv-icon-iso";
        case "j2": return "fiv-icon-j2";
        case "jar": return "fiv-icon-jar";
        case "java": return "fiv-icon-java";
        case "jpe": return "fiv-icon-jpe";
        case "jpeg": return "fiv-icon-jpeg";
        case "jpg": return "fiv-icon-jpg";
        case "js": return "fiv-icon-js";
        case "json": return "fiv-icon-json";
        case "jsp": return "fiv-icon-jsp";
        case "jsx": return "fiv-icon-jsx";
        case "key": return "fiv-icon-key";
        case "kf8": return "fiv-icon-kf8";
        case "kmk": return "fiv-icon-kmk";
        case "ksh": return "fiv-icon-ksh";
        case "kt": return "fiv-icon-kt";
        case "kts": return "fiv-icon-kts";
        case "kup": return "fiv-icon-kup";
        case "less": return "fiv-icon-less";
        case "lex": return "fiv-icon-lex";
        case "licx": return "fiv-icon-licx";
        case "lisp": return "fiv-icon-lisp";
        case "lit": return "fiv-icon-lit";
        case "lnk": return "fiv-icon-lnk";
        case "lock": return "fiv-icon-lock";
        case "log": return "fiv-icon-log";
        case "lua": return "fiv-icon-lua";
        case "m": return "fiv-icon-m";
        case "m2v": return "fiv-icon-m2v";
        case "m3u": return "fiv-icon-m3u";
        case "m3u8": return "fiv-icon-m3u8";
        case "m4": return "fiv-icon-m4";
        case "m4a": return "fiv-icon-m4a";
        case "m4r": return "fiv-icon-m4r";
        case "m4v": return "fiv-icon-m4v";
        case "map": return "fiv-icon-map";
        case "master": return "fiv-icon-master";
        case "mc": return "fiv-icon-mc";
        case "md": return "fiv-icon-md";
        case "mdb": return "fiv-icon-mdb";
        case "mdf": return "fiv-icon-mdf";
        case "me": return "fiv-icon-me";
        case "mi": return "fiv-icon-mi";
        case "mid": return "fiv-icon-mid";
        case "midi": return "fiv-icon-midi";
        case "mk": return "fiv-icon-mk";
        case "mkv": return "fiv-icon-mkv";
        case "mm": return "fiv-icon-mm";
        case "mng": return "fiv-icon-mng";
        case "mo": return "fiv-icon-mo";
        case "mobi": return "fiv-icon-mobi";
        case "mod": return "fiv-icon-mod";
        case "mov": return "fiv-icon-mov";
        case "mp2": return "fiv-icon-mp2";
        case "mp3": return "fiv-icon-mp3";
        case "mp4": return "fiv-icon-mp4";
        case "mpa": return "fiv-icon-mpa";
        case "mpd": return "fiv-icon-mpd";
        case "mpe": return "fiv-icon-mpe";
        case "mpeg": return "fiv-icon-mpeg";
        case "mpg": return "fiv-icon-mpg";
        case "mpga": return "fiv-icon-mpga";
        case "mpp": return "fiv-icon-mpp";
        case "mpt": return "fiv-icon-mpt";
        case "msg": return "fiv-icon-msg";
        case "msi": return "fiv-icon-msi";
        case "msu": return "fiv-icon-msu";
        case "nef": return "fiv-icon-nef";
        case "nes": return "fiv-icon-nes";
        case "nfo": return "fiv-icon-nfo";
        case "nix": return "fiv-icon-nix";
        case "npmignore": return "fiv-icon-npmignore";
        case "ocx": return "fiv-icon-ocx";
        case "odb": return "fiv-icon-odb";
        case "ods": return "fiv-icon-ods";
        case "odt": return "fiv-icon-odt";
        case "ogg": return "fiv-icon-ogg";
        case "ogv": return "fiv-icon-ogv";
        case "ost": return "fiv-icon-ost";
        case "otf": return "fiv-icon-otf";
        case "ott": return "fiv-icon-ott";
        case "ova": return "fiv-icon-ova";
        case "ovf": return "fiv-icon-ovf";
        case "p12": return "fiv-icon-p12";
        case "p7b": return "fiv-icon-p7b";
        case "pages": return "fiv-icon-pages";
        case "part": return "fiv-icon-part";
        case "pcd": return "fiv-icon-pcd";
        case "pdb": return "fiv-icon-pdb";
        case "pdf": return "fiv-icon-pdf";
        case "pem": return "fiv-icon-pem";
        case "pfx": return "fiv-icon-pfx";
        case "pgp": return "fiv-icon-pgp";
        case "ph": return "fiv-icon-ph";
        case "phar": return "fiv-icon-phar";
        case "php": return "fiv-icon-php";
        case "pid": return "fiv-icon-pid";
        case "pkg": return "fiv-icon-pkg";
        case "pl": return "fiv-icon-pl";
        case "plist": return "fiv-icon-plist";
        case "pm": return "fiv-icon-pm";
        case "png": return "fiv-icon-png";
        case "po": return "fiv-icon-po";
        case "pom": return "fiv-icon-pom";
        case "pot": return "fiv-icon-pot";
        case "potx": return "fiv-icon-potx";
        case "pps": return "fiv-icon-pps";
        case "ppsx": return "fiv-icon-ppsx";
        case "ppt": return "fiv-icon-ppt";
        case "pptm": return "fiv-icon-pptm";
        case "pptx": return "fiv-icon-pptx";
        case "prop": return "fiv-icon-prop";
        case "ps": return "fiv-icon-ps";
        case "ps1": return "fiv-icon-ps1";
        case "psd": return "fiv-icon-psd";
        case "psp": return "fiv-icon-psp";
        case "pst": return "fiv-icon-pst";
        case "pub": return "fiv-icon-pub";
        case "py": return "fiv-icon-py";
        case "pyc": return "fiv-icon-pyc";
        case "qt": return "fiv-icon-qt";
        case "ra": return "fiv-icon-ra";
        case "ram": return "fiv-icon-ram";
        case "rar": return "fiv-icon-rar";
        case "raw": return "fiv-icon-raw";
        case "rb": return "fiv-icon-rb";
        case "rdf": return "fiv-icon-rdf";
        case "rdl": return "fiv-icon-rdl";
        case "reg": return "fiv-icon-reg";
        case "resx": return "fiv-icon-resx";
        case "retry": return "fiv-icon-retry";
        case "rm": return "fiv-icon-rm";
        case "rom": return "fiv-icon-rom";
        case "rpm": return "fiv-icon-rpm";
        case "rpt": return "fiv-icon-rpt";
        case "rsa": return "fiv-icon-rsa";
        case "rss": return "fiv-icon-rss";
        case "rst": return "fiv-icon-rst";
        case "rtf": return "fiv-icon-rtf";
        case "ru": return "fiv-icon-ru";
        case "rub": return "fiv-icon-rub";
        case "sass": return "fiv-icon-sass";
        case "scss": return "fiv-icon-scss";
        case "sdf": return "fiv-icon-sdf";
        case "sed": return "fiv-icon-sed";
        case "sh": return "fiv-icon-sh";
        case "sit": return "fiv-icon-sit";
        case "sitemap": return "fiv-icon-sitemap";
        case "skin": return "fiv-icon-skin";
        case "sldm": return "fiv-icon-sldm";
        case "sldx": return "fiv-icon-sldx";
        case "sln": return "fiv-icon-sln";
        case "sol": return "fiv-icon-sol";
        case "sphinx": return "fiv-icon-sphinx";
        case "sql": return "fiv-icon-sql";
        case "sqlite": return "fiv-icon-sqlite";
        case "step": return "fiv-icon-step";
        case "stl": return "fiv-icon-stl";
        case "svg": return "fiv-icon-svg";
        case "swd": return "fiv-icon-swd";
        case "swf": return "fiv-icon-swf";
        case "swift": return "fiv-icon-swift";
        case "swp": return "fiv-icon-swp";
        case "sys": return "fiv-icon-sys";
        case "tar": return "fiv-icon-tar";
        case "tax": return "fiv-icon-tax";
        case "tcsh": return "fiv-icon-tcsh";
        case "tex": return "fiv-icon-tex";
        case "tfignore": return "fiv-icon-tfignore";
        case "tga": return "fiv-icon-tga";
        case "tgz": return "fiv-icon-tgz";
        case "tif": return "fiv-icon-tif";
        case "tiff": return "fiv-icon-tiff";
        case "tmp": return "fiv-icon-tmp";
        case "tmx": return "fiv-icon-tmx";
        case "torrent": return "fiv-icon-torrent";
        case "tpl": return "fiv-icon-tpl";
        case "ts": return "fiv-icon-ts";
        case "tsv": return "fiv-icon-tsv";
        case "ttf": return "fiv-icon-ttf";
        case "twig": return "fiv-icon-twig";
        case "txt": return "fiv-icon-txt";
        case "udf": return "fiv-icon-udf";
        case "vb": return "fiv-icon-vb";
        case "vbproj": return "fiv-icon-vbproj";
        case "vbs": return "fiv-icon-vbs";
        case "vcd": return "fiv-icon-vcd";
        case "vcf": return "fiv-icon-vcf";
        case "vcs": return "fiv-icon-vcs";
        case "vdi": return "fiv-icon-vdi";
        case "vdx": return "fiv-icon-vdx";
        case "vmdk": return "fiv-icon-vmdk";
        case "vob": return "fiv-icon-vob";
        case "vox": return "fiv-icon-vox";
        case "vscodeignore": return "fiv-icon-vscodeignore";
        case "vsd": return "fiv-icon-vsd";
        case "vss": return "fiv-icon-vss";
        case "vst": return "fiv-icon-vst";
        case "vsx": return "fiv-icon-vsx";
        case "vtx": return "fiv-icon-vtx";
        case "war": return "fiv-icon-war";
        case "wav": return "fiv-icon-wav";
        case "wbk": return "fiv-icon-wbk";
        case "webinfo": return "fiv-icon-webinfo";
        case "webm": return "fiv-icon-webm";
        case "webp": return "fiv-icon-webp";
        case "wma": return "fiv-icon-wma";
        case "wmf": return "fiv-icon-wmf";
        case "wmv": return "fiv-icon-wmv";
        case "woff": return "fiv-icon-woff";
        case "woff2": return "fiv-icon-woff2";
        case "wps": return "fiv-icon-wps";
        case "wsf": return "fiv-icon-wsf";
        case "xaml": return "fiv-icon-xaml";
        case "xcf": return "fiv-icon-xcf";
        case "xfl": return "fiv-icon-xfl";
        case "xlm": return "fiv-icon-xlm";
        case "xls": return "fiv-icon-xls";
        case "xlsm": return "fiv-icon-xlsm";
        case "xlsx": return "fiv-icon-xlsx";
        case "xlt": return "fiv-icon-xlt";
        case "xltm": return "fiv-icon-xltm";
        case "xltx": return "fiv-icon-xltx";
        case "xml": return "fiv-icon-xml";
        case "xpi": return "fiv-icon-xpi";
        case "xps": return "fiv-icon-xps";
        case "xrb": return "fiv-icon-xrb";
        case "xsd": return "fiv-icon-xsd";
        case "xsl": return "fiv-icon-xsl";
        case "xspf": return "fiv-icon-xspf";
        case "xz": return "fiv-icon-xz";
        case "yaml": return "fiv-icon-yaml";
        case "yml": return "fiv-icon-yml";
        case "z": return "fiv-icon-z";
        case "zip": return "fiv-icon-zip";
        case "zsh": return "fiv-icon-zsh";
        default: return "fiv-icon-blank";
    }
}