import {observer} from "mobx-react";
import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import {Alignment, Button, Intent, MaybeElement} from "@ewstheme/core";

interface EwsActionsItemProps {
    icon: BlueprintIcons_16Id | MaybeElement;
    text: string;
    onClick?: () => void;
    disabled?: boolean;
}

const EwsActionsItem : React.FC<EwsActionsItemProps> = props => {
    return (
        <Button
            fill
            minimal
            small
            icon={props.icon}
            intent={Intent.PRIMARY}
            alignText={Alignment.LEFT}
            onClick={props.onClick}
            disabled={props.disabled}
        >
            {props.text}
        </Button>
    )
}

export default observer(EwsActionsItem);