import {observer} from "mobx-react";
import {useRootStore} from "../../../core/stores/root-store";
import EwsLoading, {LoadingComponentDisplayStyle} from "../ews-loading";
import If from "../utilities/if-component";
import {useEffect, useState} from "react";
import {ClassNames} from "../../../core/utilities/react-utils";

interface BusyIndicatorProps {
    keyName: string
    children: string | JSX.Element | JSX.Element[]
}

const BusyIndicator : React.FC<BusyIndicatorProps> = props => {
    const {busyStore} = useRootStore();
    
    return (
        <div className={"ews-busy-indicator--wrapper"}>
            <If condition={busyStore.isBusy(props.keyName) === true}>
                <EwsLoading
                    displayStyle={LoadingComponentDisplayStyle.FullScreen}
                    noMessage
                />
            </If>
            {props.children}
        </div>
    )
}

export default observer(BusyIndicator);