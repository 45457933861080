import {Outlet} from "react-router-dom";
import {observer} from "mobx-react";
import styles from "./styles.module.scss";

const LoggedOutLayout : React.FC = () => {
    return (
        <div className={styles.loggedOutContainer}>
            <Outlet />
        </div>
    )
}

export default observer(LoggedOutLayout);