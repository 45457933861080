import {observer} from "mobx-react";
import {useEwsMobxContext} from "../../../../core/stores/ews-mobx-store";
import {SearchUserRequest, UserListItemResult} from "@ews-turing-models/clientmodels/dist";
import EwsTable from "../../../shared/ews-table/components/ews-table";
import EwsThead from "../../../shared/ews-table/components/ews-thead";
import EwsTr from "../../../shared/ews-table/components/ews-tr";
import EwsTh from "../../../shared/ews-table/components/ews-th";
import EwsTbody from "../../../shared/ews-table/components/ews-tbody";
import If from "../../../shared/utilities/if-component";
import {ArrayEmpty} from "../../../../core/utilities/react-utils";
import EwsTd from "../../../shared/ews-table/components/ews-td";
import {Radio} from "@ewstheme/core";

const UserTable : React.FC = () => {
    const store = useEwsMobxContext<UserListItemResult, SearchUserRequest>();
    return (
        <EwsTable>
            <EwsThead>
                <EwsTr>
                    <EwsTh>Fullname</EwsTh>
                    <EwsTh>Email Address</EwsTh>
                    <EwsTh>Roles</EwsTh>
                </EwsTr>
            </EwsThead>
            <EwsTbody>
                    <If condition={ArrayEmpty(store.data)}>
                        <EwsTr>
                            <EwsTd colSpan={3} style={{textAlign: 'center'}}>No admin portal users</EwsTd>
                        </EwsTr>
                    </If>
                    <If condition={!ArrayEmpty(store.data)}>
                        {store.data.map((item) => (
                            <EwsTr
                                key={`UserData-${item.id}`}
                                className={item.isDeleted ? 'ews-table-danger' : item.isDisabled ? 'ews-table-warning' : undefined}
                            >
                                <EwsTd>
                                    <Radio
                                        name='UserListSelector'
                                        value={item.id}
                                        label={item.fullName}
                                        checked={store.selectedItem?.id === item.id}
                                        onChange={() => {
                                            store.makeSelection(x => x.id === item.id)
                                        }}
                                    />
                                </EwsTd>
                                <EwsTd>{item.emailAddress}</EwsTd>
                                <EwsTd>{item.roles.join(', ')}</EwsTd>
                            </EwsTr>
                        ))}
                    </If>
            </EwsTbody>
        </EwsTable>
    )
};

export default observer(UserTable);