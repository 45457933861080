import {observer} from "mobx-react";
import {MediaLibraryDialogs, useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import {useCallback, useEffect, useState} from "react";
import {MediaStorageInfoByCategoryResult, MediaStorageInfoResult} from "@ews-turing-models/clientmodels/dist";
import MediaLibraryApi from "../../../../../core/api/media-library-api";
import {useRootStore} from "../../../../../core/stores/root-store";
import {BusyStates} from "../../../../../core/utilities/busy-states";
import {ClassNames} from "../../../../../core/utilities/react-utils";
import {Button, Classes, Dialog, Tab, TabId, Tabs} from "@ewstheme/core";
import StorageSummaryChart from "../charts/storage-summary-chart";
import FileCategoriesChart from "../charts/file-categories-chart";

const StorageUsageDialog : React.FC = () => {
    const {busyStore} = useRootStore();
    const {store} = useMediaLibraryStore();
    const [storageInfo, setStorageInfo] = useState<MediaStorageInfoResult | undefined>(undefined)
    const [storageInfoByCategory, setstorageInfoByCategory] = useState<MediaStorageInfoByCategoryResult[]>([])

    const [activeTab, setActiveTab] = useState<TabId>();

    const handleClose = () => {
        store.closeDialog();
        setStorageInfo(undefined);
        setstorageInfoByCategory([]);
    }
    
    const loadData = useCallback(async () => {
        let promises : any[] = [];
        promises.push(MediaLibraryApi.getStorageInfo().then(result => setStorageInfo(result.model)));
        promises.push(MediaLibraryApi.getStorageInfoByFileCategory().then(result => setstorageInfoByCategory(result.model)));
        
        await Promise.all(promises);
        
    },[store.currentDialog]);
    
    useEffect(() => {
        if(store.currentDialog === MediaLibraryDialogs.StorageUsage){
            busyStore.setBusyState(BusyStates.MediaLibrary);
            loadData().then(() =>{
                busyStore.removeBusyState(BusyStates.MediaLibrary);
            });
        }
    }, [store.currentDialog]);
    
    return (
        <Dialog
            className={ClassNames(["dialog-form", "dialog-form--primary"])}
            isOpen={store.currentDialog === MediaLibraryDialogs.StorageUsage}
            icon={"doughnut-chart"}
            title={"Storage Usage"}
            onClose={handleClose}
        >
            <div className={Classes.DIALOG_BODY}>
                <Tabs
                    id={"MediaLibrary_StorageInfo_Tabs"}
                    animate={true}
                    selectedTabId={activeTab}
                    onChange={(props) => setActiveTab(props)}
                >
                    <Tab
                        id={"MediaLibrary_StorageInfo_Summary"}
                        title={"Summary"}
                        panel={<StorageSummaryChart data={storageInfo} />}
                    />
                    <Tab
                        id={"MediaLibrary_StorageInfo_ByCategory"}
                        title={"By File Category"}
                        panel={<FileCategoriesChart data={storageInfoByCategory} storageData={storageInfo} />}
                    />
                </Tabs>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={"form-action-container"}>
                    <Button
                        type={"button"}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default observer(StorageUsageDialog);