import {observer} from "mobx-react";
import styles from "./styles.module.scss";
import {UserLoginRequest, UserLoginResult, UserLoginTenantResult} from "@ews-turing-models/clientmodels/dist";
import CredentialCapture from "./components/credential-capture";
import {useState} from "react";
import BusyIndicator from "../../shared/busy-indicator";
import EwsHeading from "../../shared/ews-typography/ews-heading";
import {Colors} from "@ewstheme/core";
import If from "../../shared/utilities/if-component";
import TenantCapture from "./components/tenant-capture";
import {useRootStore} from "../../../core/stores/root-store";
import {show} from "@ewstheme/core/lib/esnext/components/context-menu/contextMenu";
import PasswordReset from "./components/password-reset";
import {BusyStates} from "../../../core/utilities/busy-states";

const Login: React.FC = () => {
    const {busyStore, authenticationStore} = useRootStore();
    const [credentials, setCredentials] = useState<UserLoginRequest>({emailAddress: "", password: ""});
    const [tenants, setTenants] = useState<UserLoginTenantResult[]>([]);
    const [credentialSuccess, setCredentialSuccess] = useState(false);
    const [showPasswordReset, setShowPasswordReset] = useState(false);
        
    const handleValidCredentials = (value: UserLoginRequest, tenantsResult: UserLoginTenantResult[]) => {
        setCredentials(value);
        if(tenantsResult.length > 1){
            setTenants(tenantsResult);
            setCredentialSuccess(true);
        } else {
            performLogin(tenantsResult[0].tenantId, value)
        }
    }
    
    const handleTenantSelected = (tenant: UserLoginTenantResult) => {
        performLogin(tenant.tenantId);
    }
    
    const performLogin = (tenantId: string, loginCreds?: UserLoginRequest) => {
        busyStore.setBusyState(BusyStates.LoginForm);
        authenticationStore.setTenantId(tenantId);
        authenticationStore.login(loginCreds ?? credentials).finally(() => {
            busyStore.removeBusyState(BusyStates.LoginForm);
        });
    }
    
    return (
        <div className={styles.loginBackground}>
            <div className={styles.loginContainer}>
                <img src="/img/Logo-Dark.png" className={styles.logo} alt="Encompass Web Systems" />
                <div className={styles.formContainer}>
                    <BusyIndicator keyName={BusyStates.LoginForm}>
                        <If condition={!credentialSuccess}>
                            <If condition={!showPasswordReset}>
                                <div style={{textAlign: "center", marginTop: "1.5rem"}}>
                                    <EwsHeading variant="h2" text={"Login"} />
                                </div>
                                <CredentialCapture
                                    onCredentialsValid={handleValidCredentials}
                                    onPasswordResetClick={() => setShowPasswordReset(true)}
                                />
                            </If>
                            <If condition={showPasswordReset}>
                                <div style={{textAlign: "center", marginTop: "1.5rem"}}>
                                    <EwsHeading variant="h2" text={"Password Reset"} />
                                </div>
                                <PasswordReset
                                    onBackToLoginClick={() => setShowPasswordReset(false)}
                                />
                            </If>
                        </If>                 
                        <If condition={credentialSuccess}>
                            <div style={{textAlign: "center", marginTop: "1.5rem"}}>
                                <EwsHeading variant="h2" text={"Select Tenant"} />
                            </div>
                            <TenantCapture tenants={tenants} onTenantSelected={handleTenantSelected} />
                        </If>
                    </BusyIndicator>
                </div>
            </div>
        </div>
    )
}

export default observer(Login);
