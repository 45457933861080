import {useRootStore} from "../../../../../core/stores/root-store";
import {MediaLibraryDialogs, useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import { EditDirectoryRequest } from "@ews-turing-models/clientmodels/dist";
import * as Yup from "yup";
import {FormikHelpers, FormikValues} from "formik";
import {BusyStates} from "../../../../../core/utilities/busy-states";
import mediaLibraryApi from "../../../../../core/api/media-library-api";
import {EwsFormErrorHandler} from "../../../../shared/ews-form/handlers/EwsFormErrorHandler";
import {ClassNames} from "../../../../../core/utilities/react-utils";
import {Classes, Dialog} from "@ewstheme/core";
import EwsInput from "../../../../shared/ews-form/components/ews-input";
import EditorDialogActions from "../../../../shared/ews-editor-dialog/components/editor-dialog-actions";
import EwsForm from "../../../../shared/ews-form";
import {observer} from "mobx-react";
import {BiRename} from "react-icons/bi";

const RenameFolderDialog: React.FC = () => {

    const {busyStore, toastStore} = useRootStore();
    const {store} = useMediaLibraryStore();

    const handleClose = () => {
        store.closeDialog();
    }
    
    const modal: EditDirectoryRequest = {
        directoryId: store.selectedItems[0]?.id,
        directoryName: store.currentDirectory?.childDirectories
            .find(x => x.directoryId == store.selectedItems[0]?.id)?.directoryName ?? ""
    }
    
    const validationSchema = Yup.object().shape({
        directoryName: Yup.string().required("Folder name is required")
    });
    
    const onSubmit = (values: (FormikValues & EditDirectoryRequest), actions: FormikHelpers<FormikValues & EditDirectoryRequest>) => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        mediaLibraryApi.editDirectory(values)
            .then(result => {
                store.updateView(result.model).then(() => {
                    handleClose();
                    busyStore.removeBusyState(BusyStates.MediaLibrary);
                    toastStore.showToast({
                        message: `Folder renamed successfully`,
                        icon: 'tick',
                        intent: 'success'
                    });
                });
            })
            .catch(error => {
                EwsFormErrorHandler(error);
                busyStore.removeBusyState(BusyStates.MediaLibrary);
            })
    };
    
    return (
        <Dialog
            className={ClassNames(["ews-dialog", "ews-dialog--primary"])}
            isOpen={store.currentDialog === MediaLibraryDialogs.RenameFolder}
            icon={<BiRename className={ClassNames(["box-icon", "box-icon-dialog"])}/>}
            title={"Rename Folder"}
            onClose={handleClose}
        >
            <EwsForm<EditDirectoryRequest>
                initialValues={modal}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {formProps => (
                    <>
                        <div className={Classes.DIALOG_BODY}>
                            <EwsInput
                                label="Folder name"
                                placeholder={"New folder"}
                                required={true}
                                formikName="directoryName"
                            />
                        </div>
                        <div className={Classes.DIALOG_FOOTER}>
                            <EditorDialogActions
                                onCancelAction={() => {handleClose()}}
                                formProps={formProps}
                            />
                        </div>
                    </>
                )}
            </EwsForm>
        </Dialog>
    )
}

export default observer(RenameFolderDialog);