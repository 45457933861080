import {UserLoginTenantResult} from "@ews-turing-models/clientmodels/dist";
import {observer} from "mobx-react";
import {FormEvent, useState} from "react";
import {Button, Intent, Radio, RadioGroup} from "@ewstheme/core";
import styles from "../styles.module.scss";
import {SortComparer} from "../../../../core/utilities/react-utils";

interface TenantCaptureProps {
    tenants: UserLoginTenantResult[]
    onTenantSelected: (tenant: UserLoginTenantResult) => void;
}

const TenantCapture : React.FC<TenantCaptureProps> = props => {
    const [selectedTenant, setSelectedTenant] = useState<string | undefined>();
    
    const handleSelectionChange = (event: FormEvent<HTMLInputElement>) => setSelectedTenant(event.currentTarget.value);
    
    const handleProcessClick = () => {
        if(selectedTenant){
            const tenant = props.tenants.find(value => value.tenantId === selectedTenant);
            if(!tenant) throw new Error("Unable to determine selected tenant");
            
            props.onTenantSelected(tenant);
        }
        
    }
    
    return (
        <>
            <div className={styles.tenantContainer}>
                <RadioGroup
                    onChange={handleSelectionChange}
                    selectedValue={selectedTenant}
                >
                    {props.tenants.sort((a,b) => SortComparer(a.tenantName, b.tenantName))
                        .map((value, index) => (
                        <Radio
                            className={styles.bp4Control}
                            key={`tenantSelect_${index}`}
                            label={value.tenantName}
                            value={value.tenantId}
                        />
                        ))}
                </RadioGroup>
            </div>
            <Button
                intent={Intent.PRIMARY}
                onClick={handleProcessClick}
                fill
            >Proceed</Button>
        </>
    )
}

export default observer(TenantCapture);
