import {observer} from "mobx-react";
import {EwsMobxEditModes, useEwsMobxContext} from "../../../core/stores/ews-mobx-store";
import {ApiResult, SearchUserRequest, UserListItemResult} from "@ews-turing-models/clientmodels/dist";
import {useCallback, useEffect} from "react";
import userManagementApi from "../../../core/api/user-management-api";
import {useRootStore} from "../../../core/stores/root-store";
import UserTable from "./components/user-table";
import {Dialog, MaybeElement} from "@ewstheme/core";
import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import CreateUserForm from "./components/create-user-form";
import EwsEditorDialog from "../../shared/ews-editor-dialog";
import {BusyStates} from "../../../core/utilities/busy-states";
import EditUserForm from "./components/edit-user-form";
import DeleteUserForm from "./components/delete-user-form";

const Main : React.FC = () => {
    const {busyStore} = useRootStore();
    const store = useEwsMobxContext<UserListItemResult, SearchUserRequest>();
    
    const initStore = useCallback(async () => {
        if(!store.filter){
            store.setFilter({
                searchTerm: "",
                isDeleted: false,
                isDisabled: false
            });
        }
        if (!store.loadedInitial) {
            var data: ApiResult<UserListItemResult[]> = await userManagementApi.getUserAccounts(store.filter);
            store.loadData(data.model);
        }
    },[]);

    useEffect(() => {
        busyStore.setBusyState(BusyStates.UserManagement);
        initStore().then(() => {
            busyStore.removeBusyState(BusyStates.UserManagement);
            store.setLoadedInitial(true);
        });
    }, [initStore, store.loadedInitial]);

    const handleEditDialogClose = () => {
        store.setEditMode(EwsMobxEditModes.Readonly);
    }

    const userEditFormTitle = () => {
        switch(store.editMode){
            case EwsMobxEditModes.Create:
                return 'Create New Admin User';
            case EwsMobxEditModes.Edit:
                return 'Edit Admin User';
            case EwsMobxEditModes.Delete:
                return 'Delete Admin User';
            default:
                return undefined;
        }
    }

    const userEditFormIcon = () : BlueprintIcons_16Id | MaybeElement => {
        switch(store.editMode){
            case EwsMobxEditModes.Create:
                return 'new-person';
            case EwsMobxEditModes.Edit:
                return 'edit';
            case EwsMobxEditModes.Delete:
                return 'blocked-person';
            default:
                return undefined;
        }
    }

    if(!store.loadedInitial) return <></>;    
    
    return (
        <>
            <UserTable />
            <EwsEditorDialog
                title={userEditFormTitle()}
                icon={userEditFormIcon()}
                intent={store.editMode === EwsMobxEditModes.Delete ? "danger" : "primary"}
                isOpen={([EwsMobxEditModes.Delete, EwsMobxEditModes.Create, EwsMobxEditModes.Edit]).some(x => x = store.editMode)}
                onClose={handleEditDialogClose}
                ewsMobxMode={store.editMode}
                createContent={
                    <CreateUserForm onCloseAction={() => handleEditDialogClose()}/>
                }
                editContent={
                    <EditUserForm onCloseAction={() => handleEditDialogClose()}/>
                }
                deleteContent={
                    <DeleteUserForm onCloseAction={() => handleEditDialogClose()}/>
                }
            />
        </>
    );
}

export default observer(Main);