import {observer} from "mobx-react";
import {useRootStore} from "../../../core/stores/root-store";
import SignalrDemo from "../demos/signalr-demo";

const Dashboard: React.FC = () => {
    const {authenticationStore} = useRootStore()
    return (
        <>
            <h1>Welcome, {authenticationStore.tenantId}</h1>
            <SignalrDemo />
        </>
    )
}

export default observer(Dashboard);