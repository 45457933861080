import {observer} from "mobx-react";
import {useRootStore} from "../../../../core/stores/root-store";
import {Button, ButtonGroup, Card} from "@ewstheme/core";
import EwsGrid from "../../../shared/ews-grid/components/ews-grid";
import EwsGridItem from "../../../shared/ews-grid/components/ews-grid-item";
import If from "../../../shared/utilities/if-component";
import EwsHeading from "../../../shared/ews-typography/ews-heading";
import {EwsText} from "../../../shared/ews-typography/ews-text";
import {PreferenceSettings} from "../../../../core/utilities/preference-settings";
import ChangePasswordDialog from "./change-password-dialog";

const ProfileCard : React.FC = () => {
    const {authenticationStore, sitePersistenceStore} = useRootStore()
    
    return (
        <>
            <Card elevation={2} style={{width: "250px", padding: "0.5rem"}}>
                <EwsHeading variant="h4" text="Access Levels" />
                <EwsGrid>
                    <If condition={authenticationStore.userAccount !== null && authenticationStore.userAccount.userPermissions.length > 0}>
                        <>
                            {authenticationStore.userAccount?.userPermissions.map(role => (
                                <EwsGridItem span={6} key={`userAccessRole_${role.id}`}>
                                    <span>{role.name}</span>
                                </EwsGridItem>
                            ))}
                        </>
                    </If>
                    <If condition={authenticationStore.userAccount === null || authenticationStore.userAccount.userPermissions.length === 0}>
                        <div className={"ews-text--center"}>
                            <EwsText>You have no permissions allocated to your login.</EwsText>
                        </div>
                    </If>
                </EwsGrid>
                <hr/>
                <EwsHeading variant="h4" text="Theme" />
                <EwsGrid>
                    <EwsGridItem span={12}>
                        <ButtonGroup fill>
                            <Button
                                intent={!sitePersistenceStore.getPreference(PreferenceSettings.DarkMode) ? 'primary': undefined}
                                text='Light'
                                icon='flash'
                                onClick={() => sitePersistenceStore.togglePreference(PreferenceSettings.DarkMode)}
                            />
                            <Button
                                intent={sitePersistenceStore.getPreference(PreferenceSettings.DarkMode) ? 'primary': undefined}
                                icon='moon'
                                text='Dark'
                                onClick={() => sitePersistenceStore.togglePreference(PreferenceSettings.DarkMode)}
                            />
                        </ButtonGroup>
                    </EwsGridItem>
                </EwsGrid>
                <hr />
                <ChangePasswordDialog />
                <Button
                    intent='primary'
                    minimal={true}
                    text='Logout'
                    fill
                    alignText='left'
                    onClick={() => authenticationStore.logout()}
                />
            </Card>
        </>
    )
}

export default observer(ProfileCard);