import {observer} from "mobx-react";
import {useRootStore} from "../../../../../core/stores/root-store";
import {MediaLibraryDialogs, useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import {Button, Classes, Dialog, Tab, TabId, Tabs} from "@ewstheme/core";
import {ClassNames} from "../../../../../core/utilities/react-utils";
import {useState} from "react";
import UploadFilesForm from "../forms/upload-files-form";
import AddEmbedContentForm from "../forms/add-embed-content-form";

const AddFilesDialog : React.FC = () => {
    const {store} = useMediaLibraryStore();
    const [activeTab, setActiveTab] = useState<TabId>();
    const [requiresRefresh,setRequiresRefresh] = useState(false);

    const handleClose = () => {
        store.closeDialog();
        if(requiresRefresh){
            store.setDirectory(store.currentDirectory!.directoryId);
        }
    }
    
    return (
        <Dialog
            className={ClassNames(["dialog-form", "dialog-form--primary"])}
            isOpen={store.currentDialog === MediaLibraryDialogs.AddFiles}
            icon={"media"}
            title={"Add Media"}
            onClose={handleClose}
        >
            <div className={Classes.DIALOG_BODY}>
                <Tabs 
                    id={"MediaLibrary_UploadDialog_Tabs"}
                    renderActiveTabPanelOnly={true}
                    animate={true}
                    selectedTabId={activeTab}
                    onChange={(props) => setActiveTab(props)}
                >
                    <Tab
                        id={"MediaLibrary_UploadDialog_UploadFile"}
                        title={"Upload file(s)"}
                        panel={<UploadFilesForm fileUploadCallback={() => setRequiresRefresh(true) } />}
                    />
                    <Tab
                        id={"MediaLibrary_UploadDialog_EmbeddedContent"}
                        title={"Embedded Content"}
                        panel={<AddEmbedContentForm contentAddedCallback={() => {setRequiresRefresh(true); handleClose();}} />}
                    />
                </Tabs>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={"form-action-container"}>
                    <Button
                        type={"button"}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default observer(AddFilesDialog);