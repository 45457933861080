import {createContext, useContext} from "react";
import {AuthenticationStore} from "./authentication-store";
import {BusyStore} from "./busy-store";
import {SitePersistenceStore} from "./site-persistance-store";
import {ToastStore} from "./toast-store";
import {LookupStore} from "./lookup-store";
import {SignalrStore} from "./signalr-store";

interface RootStore {
    authenticationStore: AuthenticationStore;
    busyStore: BusyStore;
    sitePersistenceStore: SitePersistenceStore;
    toastStore: ToastStore;
    lookupStore: LookupStore;
    signalrStore: SignalrStore;
}

export const rootStore: RootStore = {
    authenticationStore: new AuthenticationStore(),
    busyStore: new BusyStore(),
    sitePersistenceStore: new SitePersistenceStore(),
    toastStore: new ToastStore(),
    lookupStore: new LookupStore(),
    signalrStore: new SignalrStore(),
}

export const RootStoreContext = createContext(rootStore);

export function useRootStore() {
    return useContext(RootStoreContext);
}