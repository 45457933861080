import {observer} from "mobx-react";
import {
    MediaLibraryDialogs,
    SelectedItemType,
    useMediaLibraryStore
} from "../../../../../core/stores/media-library-store";
import {useCallback, useEffect, useState} from "react";
import {
    Button,
    Classes,
    Code,
    Dialog,
    Pre
} from "@ewstheme/core";
import {ApiAsset, ClassNames, CopyToClipboard, SanitizeHtml} from "../../../../../core/utilities/react-utils";
import mediaLibraryApi from "../../../../../core/api/media-library-api";
import {useRootStore} from "../../../../../core/stores/root-store";
import {BusyStates} from "../../../../../core/utilities/busy-states";
import {MediaItemResult} from "@ews-turing-models/clientmodels/dist";
import EwsGrid from "../../../../shared/ews-grid/components/ews-grid";
import EwsGridItem from "../../../../shared/ews-grid/components/ews-grid-item";
import styles from "../../styles.module.scss";
import If from "../../../../shared/utilities/if-component";
import EwsHeading from "../../../../shared/ews-typography/ews-heading";
import ImageUrlGeneratorForm from "../forms/image-url-generator-form";
import {getIconClassFromExtension} from "../../helpers/file-icon-helpers";
import ApiUtil from "../../../../../core/utilities/api-util";
import UpdateMediaItemForm from "../forms/update-media-item-form";

const ViewContentDialog: React.FC = () => {
    const {busyStore, lookupStore} = useRootStore();
    const {store} = useMediaLibraryStore();
    const [requiresRefresh, setRequiresRefresh] = useState(false);
    const [mediaData, setMediaData] = useState<MediaItemResult | undefined>(undefined);
    const [imageUrl, setImageUrl] = useState<string | undefined>(undefined);

    const updateImagePreview = (url: string) => {
        setImageUrl(url);
    }

    const handleClose = () => {
        store.closeDialog();
        setMediaData(undefined);
        setImageUrl(undefined);
        if (requiresRefresh) {
            if (store.currentDirectory) {
                store.setDirectory(store.currentDirectory.directoryId);
            }
        }
        setRequiresRefresh(false);
    }

    const initData = useCallback(async () => {
        const result = await mediaLibraryApi.getMediaItem({mediaId: store.selectedItems[0].id})
        setMediaData(result.model);
        if (result.model.mediaType === "Image") {
            setImageUrl(ApiAsset(`/images/original/${result.model.mediaItemID}`));
        }
    }, [mediaLibraryApi.getMediaItem])
    
    const handleDownload = () => {
        switch(mediaData?.mediaType){
            case "Document":
                ApiUtil.getDownload(
                    `/files/${mediaData.mediaItemID}`, 
                    `${mediaData.title}-${mediaData.mediaItemID}.${mediaData.fileExtensions}`,
                    false);
                break;
            case "Image":
                if(imageUrl){
                    ApiUtil.getDownload(
                        imageUrl, 
                        `${mediaData.title}-${mediaData.mediaItemID}.${mediaData.fileExtensions}`,
                        false);  
                }
                break;
        }
    }

    useEffect(() => {
        if (store.selectedItems.length === 1 &&
            store.selectedItems[0].type === SelectedItemType.File &&
            store.currentDialog === MediaLibraryDialogs.ViewContent) {
            busyStore.setBusyState(BusyStates.MediaLibrary);
            initData().then(() => busyStore.removeBusyState(BusyStates.MediaLibrary));
        }
    }, [store.selectedItems, store.currentDialog, initData])

    if (!mediaData) return null;

    return (
        <Dialog
            className={ClassNames(["dialog-form", "dialog-form--primary", "dialog-form-medium"])}
            isOpen={store.currentDialog === MediaLibraryDialogs.ViewContent}
            icon={"media"}
            title={mediaData.title}
            onClose={handleClose}
        >
            <div className={Classes.DIALOG_BODY}>
                <EwsGrid>
                    <EwsGridItem span={8}>
                        <div className={ClassNames(
                            [
                                styles.MediaViewPreview,
                                mediaData.mediaType === "EmbeddedObject" ? styles.Embedded : ""
                            ])}
                        >
                            <div className={styles.MediaViewPreviewWrapper}>
                                <If condition={mediaData.mediaType === "EmbeddedObject"}>
                                    {SanitizeHtml(mediaData.embedCode)}
                                </If>
                                <If condition={mediaData.mediaType === "Image"}>
                                    <img src={imageUrl} loading={"lazy"} alt={mediaData.title}/>
                                    <div className={styles.ImageDownload}>
                                        <Button
                                            icon={"download"}
                                            text={"Download"}
                                            onClick={handleDownload}
                                        />
                                    </div>
                                </If>
                                <If condition={mediaData.mediaType === "Document"}>
                                    <div>
                                        <div>
                                           <span className={ClassNames(
                                               [
                                                   styles.MediaViewPreviewIcon,
                                                   "fiv-cla",
                                                   getIconClassFromExtension(mediaData.fileExtensions?.toLowerCase() ?? "")
                                               ])}
                                           />
                                        </div>
                                        <div style={{marginTop: "0.5rem", textAlign: "center"}}>
                                            <Button
                                                icon={"download"}
                                                text={"Download"}
                                                onClick={handleDownload}
                                            />
                                        </div>
                                    </div>
                                </If>
                            </div>
                        </div>
                    </EwsGridItem>
                    <EwsGridItem span={4}>
                        <UpdateMediaItemForm
                            mediaItem={mediaData}
                            updatedCallback={(result) => {
                                setRequiresRefresh(true);
                                setMediaData(result);
                            }}
                        />
                    </EwsGridItem>
                    <EwsGridItem span={12}>
                        <If condition={mediaData.mediaType === "EmbeddedObject"}>
                            <EwsHeading
                                variant={"h5"}
                                intent={"primary"}
                                text={"Embed Code"}
                            />
                            <Pre className={ClassNames(["wrap-code", "clickable"])}
                                 onClick={() => CopyToClipboard(mediaData.embedCode)}
                            >
                                <Code>
                                    {mediaData.embedCode}
                                </Code>
                            </Pre>
                        </If>
                        <If condition={mediaData.mediaType === "Image"}>
                            <EwsHeading
                                variant={"h5"}
                                intent={"primary"}
                                text={"Image URL"}
                            />
                            <ImageUrlGeneratorForm
                                mediaId={mediaData.mediaItemID}
                                generatedURLCallback={updateImagePreview}
                            />
                        </If>
                        <If condition={mediaData.mediaType === "Document"}>
                            <EwsHeading
                                variant={"h5"}
                                intent={"primary"}
                                text={"URL"}
                            />
                            <Pre className={ClassNames(["wrap-code", "clickable"])}
                                 onClick={() => CopyToClipboard(
                                     `https://${lookupStore.tenantInfo?.hostName.split(";")[0]}/files/${mediaData.mediaItemID}`
                                 )}
                            >
                                <Code>
                                    {`https://${lookupStore.tenantInfo?.hostName.split(";")[0]}/files/${mediaData.mediaItemID}`}
                                </Code>
                            </Pre>
                        </If>
                    </EwsGridItem>
                </EwsGrid>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className={"form-action-container"}>
                    <Button
                        type={"button"}
                        onClick={handleClose}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </Dialog>
    )
}

export default observer(ViewContentDialog);