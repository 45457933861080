import {observer} from "mobx-react";
import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import {Colors, Icon, IconSize, MaybeElement} from "@ewstheme/core";
import {useRootStore} from "../../../../core/stores/root-store";
import {ClassNames} from "../../../../core/utilities/react-utils";
import {PreferenceSettings} from "../../../../core/utilities/preference-settings";
import If from "../../utilities/if-component";
import uuid from "react-uuid";
import {useEffect, useState} from "react";
import {Popover2} from "@ewstheme/popover2";
import {NavLink, useLocation, useNavigate} from "react-router-dom";


interface EwsSidemenuGroupProps {
    items: EwsSidemenuGroupItems[]
    text: string;
    icon: BlueprintIcons_16Id | MaybeElement;
}

export interface EwsSidemenuGroupItems {
    text: string;
    icon: BlueprintIcons_16Id | MaybeElement;
    appPath: string;
}

const EwsSidemenuGroup: React.FC<EwsSidemenuGroupProps> = props => {
    const {sitePersistenceStore} = useRootStore();
    const navigate = useNavigate();
    let location = useLocation();

    const isActive = !!props.items.find(x => x.appPath === location.pathname);

    const componentUUID = uuid();

    return (
        <div style={{position: "relative"}}>
            <div className={ClassNames(
                [
                    "ews-sidebar--group",
                    isActive ? "active" : ""
                ]
            )}>
                <If condition={!isActive}>
                    <Popover2
                        interactionKind={"hover"}
                        placement={"right-start"}
                        minimal
                        hoverCloseDelay={50}
                        renderTarget={({isOpen, ref, ...targetProps}) => (
                            <div
                                {...targetProps}
                                ref={ref}
                                className={ClassNames(["ews-sidebar--group-item"])}
                                onClick={() => navigate(props.items[0].appPath)}
                            >
                                <Icon icon={props.icon} size={IconSize.LARGE} style={{color: Colors.PURPLE4}}/>
                                <If condition={sitePersistenceStore.getPreference(PreferenceSettings.SideBarCollapsed) !== true}>
                                    <span className={"ews-sidebar--item-text"}>{props.text}</span>
                                </If>
                            </div>   
                        )}
                        content={
                            <div
                                className={ClassNames(["ews-sidebar--hover-menu"])}
                            >
                                <div className="ews-sidebar--hover-menu-header">
                                    <span>{props.text}</span>
                                </div>
                                {props.items.map((item, index) => (
                                    <div
                                        key={`item-${componentUUID}-subitem-${index}`}
                                        className={ClassNames(["ews-sidebar--item", item.appPath === location.pathname ? "active": ""])}
                                        onClick={() => navigate(item.appPath)}
                                    >
                                        <Icon icon={item.icon}/>
                                        <span className={"ews-sidebar--item-text"}>{item.text}</span>
                                    </div>
                                ))}
                            </div>
                        }
                    />                    
                </If>
                <If condition={isActive}>
                    <>
                        <div
                            className={ClassNames(["ews-sidebar--group-item"])}
                            onClick={() => navigate(props.items[0].appPath)}
                        >
                            <Icon icon={props.icon} size={IconSize.LARGE} style={{color: Colors.PURPLE4}}/>
                            <If condition={sitePersistenceStore.getPreference(PreferenceSettings.SideBarCollapsed) !== true}>
                                <span className={"ews-sidebar--item-text"}>{props.text}</span>
                            </If>
                        </div>
                        {props.items.map((item, index) => (
                            <div
                                key={`item-${componentUUID}-subitem-${index}`}
                                className={ClassNames(
                                    [
                                        "ews-sidebar--item",
                                        sitePersistenceStore.getPreference(PreferenceSettings.SideBarCollapsed) === true ? "collapsed" : "",
                                        item.appPath === location.pathname ? "active": ""
                                    ])}
                                onClick={() => navigate(item.appPath)}
                            >
                                <Icon icon={item.icon}/>
                                <If condition={sitePersistenceStore.getPreference(PreferenceSettings.SideBarCollapsed) !== true}>
                                    <span className={"ews-sidebar--item-text"}>{item.text}</span>
                                </If>
                            </div>
                        ))}
                    </>
                </If>
            </div>
        </div>
    )
}

export default observer(EwsSidemenuGroup);