import {observer} from "mobx-react";
import {useRootStore} from "../../../../../core/stores/root-store";
import {useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import {AddEmbeddedItemRequest} from "@ews-turing-models/clientmodels/dist";
import * as Yup from "yup";
import EwsForm from "../../../../shared/ews-form";
import {FormikHelpers, FormikValues} from "formik";
import EwsGrid from "../../../../shared/ews-grid/components/ews-grid";
import EwsGridItem from "../../../../shared/ews-grid/components/ews-grid-item";
import EwsInput from "../../../../shared/ews-form/components/ews-input";
import EwsTextarea from "../../../../shared/ews-form/components/ews-textarea";
import {Button, Intent} from "@ewstheme/core";
import {BusyStates} from "../../../../../core/utilities/busy-states";
import mediaLibraryApi from "../../../../../core/api/media-library-api";
import {EwsFormErrorHandler} from "../../../../shared/ews-form/handlers/EwsFormErrorHandler";

interface AddEmbedContentFormProps {
    contentAddedCallback: () => void;
}

const AddEmbedContentForm: React.FC<AddEmbedContentFormProps> = props => {
    const {busyStore, toastStore} = useRootStore();
    const {store} = useMediaLibraryStore();

    const model: AddEmbeddedItemRequest = {
        directoryId: store.currentDirectory?.directoryId ?? "",
        title: "",
        description: "",
        embedCode: ""
    }

    const validationSchema = Yup.object().shape({
        title: Yup.string().required("Title is required"),
        embedCode: Yup.string().required("Embed code is required")
    });

    const onSubmit = (values: (FormikValues & AddEmbeddedItemRequest), actions: FormikHelpers<FormikValues & AddEmbeddedItemRequest>) => {
        busyStore.setBusyState(BusyStates.MediaLibrary);

        mediaLibraryApi.addEmbedCode(values)
            .then(() => {
                props.contentAddedCallback();
                busyStore.removeBusyState(BusyStates.MediaLibrary);
                toastStore.showToast({
                    message: "Media item was created successfully",
                    icon: "tick",
                    intent: "success"
                });
            })
            .catch(error => {
                busyStore.removeBusyState(BusyStates.MediaLibrary);
                EwsFormErrorHandler(error);
            });
    }

    return (
        <EwsForm<AddEmbeddedItemRequest>
            initialValues={model}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {formProps => (
                <>
                    <EwsGrid>
                        <EwsGridItem span={6}>
                            <EwsInput
                                label={"Title"}
                                placeholder={"Media title"}
                                required={true}
                                formikName={"title"}
                            />
                        </EwsGridItem>
                        <EwsGridItem span={12}>
                            <EwsTextarea
                                label={"Description"}
                                placeholder={"Description about the media"}
                                formikName={"description"}
                                rows={3}
                            />
                        </EwsGridItem>
                        <EwsGridItem span={12}>
                            <EwsTextarea
                                label={"Embed Code"}
                                placeholder={"<!--Your embed code -->"}
                                formikName={"embedCode"}
                                rows={6}
                            />
                        </EwsGridItem>
                    </EwsGrid>
                    <div style={{textAlign: "right"}}>
                        <Button
                            type={"submit"}
                            intent={Intent.PRIMARY}
                            disabled={!formProps.isValid}>
                            Add Embed Content
                        </Button>
                    </div>
                </>
            )}
        </EwsForm>
    )
}

export default observer(AddEmbedContentForm);