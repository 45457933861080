import PrivateComponent from "../../shared/private-component";
import {Outlet, useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {useRootStore} from "../../../core/stores/root-store";
import styles from "./styles.module.scss";
import {Alignment, AnchorButton, Button, Icon, InputGroup, Intent, Navbar} from "@ewstheme/core";
import {ClassNames} from "../../../core/utilities/react-utils";
import {Popover2} from "@ewstheme/popover2";
import ProfileCard from "./components/profile-card";
import {EwsText} from "../../shared/ews-typography/ews-text";
import {PreferenceSettings} from "../../../core/utilities/preference-settings";
import EwsSidemenu from "../../shared/ews-sidemenu";
import EwsSidemenuItem from "../../shared/ews-sidemenu/components/ews-sidemenu-item";
import EwsSidemenuGroup from "../../shared/ews-sidemenu/components/ews-sidemenu-group";
import {AppPaths} from "../../../AppPaths";
import If from "../../shared/utilities/if-component";
import {Input} from "../../shared/ews-form/components/ews-input.stories";
import {useCallback, useEffect, useState} from "react";
import TenantSearch from "./components/tenant-search";

const MainLayout: React.FC = () => {
    const {sitePersistenceStore, authenticationStore, lookupStore} = useRootStore();
    const [searchQuery, setSearchQuery] = useState("");
    const [searchOpen, setSearchOpen] = useState(false);

    return (
        <PrivateComponent performRedirect={true}>
            <div
                className={ClassNames([styles.mainLayoutContainer, sitePersistenceStore.getPreference(PreferenceSettings.DarkMode) ? "bp4-dark" : ""])}>
                <div className={ClassNames(
                    [
                        styles.sideBar,
                        styles.wrapper,
                        sitePersistenceStore.getPreference(PreferenceSettings.SideBarCollapsed) ? styles.collapsed : ""
                    ])}
                >
                    <Navbar
                        className={styles.sideBarNavBar}
                    >
                        <Navbar.Group align={Alignment.RIGHT}>
                            <Button
                                icon={sitePersistenceStore.getPreference(PreferenceSettings.SideBarCollapsed) ? "double-chevron-right" : "double-chevron-left"}
                                onClick={() => sitePersistenceStore.togglePreference(PreferenceSettings.SideBarCollapsed)}
                                minimal
                                className={styles.NavBarButton}
                            />
                        </Navbar.Group>
                    </Navbar>
                    <div className={ClassNames([styles.content, styles.noPadding, styles.allowOverflow])}>
                        <EwsSidemenu>
                            <EwsSidemenuItem
                                text="Dashboard"
                                icon="dashboard"
                                appPath={AppPaths.Home.Url}
                            />
                            <EwsSidemenuItem
                                text="Media Library"
                                icon="media"
                                appPath={AppPaths.MediaLibrary.Url}
                            />
                            <EwsSidemenuGroup
                                text="Settings"
                                icon="cog"
                                items={[
                                    {
                                        text: "Admin Portal Users",
                                        icon: "people",
                                        appPath: AppPaths.System.Users.Url
                                    }
                                ]}
                            />
                        </EwsSidemenu>
                    </div>
                    <div className={styles.footer}>
                        <EwsText><>&copy; Encompass Web Systems {(new Date).getFullYear()}</>
                        </EwsText>
                    </div>
                </div>
                <div
                    className={ClassNames(
                        [
                            styles.workspace,
                            styles.wrapper,
                            sitePersistenceStore.getPreference(PreferenceSettings.SideBarCollapsed) === false ? styles.collapsed : ""
                        ])}
                >
                    <Navbar
                        className={styles.workspaceNavBar}
                    >
                        <Navbar.Group align={Alignment.LEFT}>
                            <Navbar.Heading
                                className={styles.NavBarHeading}
                            >
                                {lookupStore.tenantInfo?.name}
                            </Navbar.Heading>
                            <If condition={lookupStore.tenantInfo !== null}>
                                <AnchorButton
                                    minimal
                                    className={styles.NavBarButton}
                                    href={`https://${lookupStore.tenantInfo?.hostName.split(";")[0]}/`}
                                    target="_blank"
                                >
                                    Go to website
                                </AnchorButton>
                            </If>
                        </Navbar.Group>
                        <Navbar.Group align={Alignment.RIGHT}>
                            <Popover2
                                placement={"bottom-end"}
                                modifiers={{arrow: {enabled: false}}}
                                content={<TenantSearch query={searchQuery}
                                                       callback={() => setSearchOpen(false)}/>}
                                autoFocus={false}
                                isOpen={searchOpen}
                            >
                                <InputGroup
                                    leftIcon={"search"}
                                    rightElement={
                                        <If condition={searchQuery !== undefined && searchQuery !== ""}>
                                            <Button
                                                icon={"cross"}
                                                intent={Intent.DANGER}
                                                minimal
                                                onClick={() => {
                                                    setSearchOpen(false);
                                                    setSearchQuery("");
                                                }}
                                            />
                                        </If>
                                    }
                                    placeholder={"Search..."}
                                    value={searchQuery}
                                    onClick={() => {
                                        setSearchOpen(true);
                                    }}
                                    onChange={event => setSearchQuery(event.target.value)}
                                ></InputGroup>
                            </Popover2>
                            <Popover2
                                placement="bottom-end"
                                modifiers={{arrow: {enabled: false}}}
                                content={<ProfileCard/>}
                            >
                                <Button
                                    icon="user"
                                    minimal={true}
                                    className={styles.NavBarButton}
                                    text={authenticationStore.userAccount?.fullName}
                                />
                            </Popover2>
                        </Navbar.Group>
                    </Navbar>
                    <div className={styles.content} style={{overflow: "hidden"}}>
                        <Outlet/>
                    </div>
                </div>
            </div>
        </PrivateComponent>
    )
}

export default observer(MainLayout);