import {MediaDirectoryTreeResult} from "@ews-turing-models/clientmodels/dist";
import {observer} from "mobx-react";
import {useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import EwsDropdown from "../../../../shared/ews-dropdown";
import {MenuItem2} from "@ewstheme/popover2";
import If from "../../../../shared/utilities/if-component";
import {Classes, Menu} from "@ewstheme/core";
import {ClassNames} from "../../../../../core/utilities/react-utils";
import styles from "../../styles.module.scss";

const DirectoryMenu: React.FC = () => {
    const {store} = useMediaLibraryStore();

    const handleDirectoryClick = (directoryId: string) => {
        store.setDirectory(directoryId);
    }

    function DirectoryMenuItem(props: { directory: MediaDirectoryTreeResult }) {
        return (
            <>
                <If condition={props.directory.childDirectories.length == 0}>
                    <MenuItem2
                        icon={<span className={ClassNames([
                            "fiv-ext",
                            "fiv-icon-folder-classic"])}
                        ></span>}
                        text={props.directory.directoryName}
                        roleStructure={"menuitem"}
                        onClick={() => handleDirectoryClick(props.directory.directoryId)}
                        active={props.directory.directoryId === store.currentDirectory?.directoryId}
                    />
                </If>
                <If condition={props.directory.childDirectories.length > 0}>
                    <MenuItem2
                        icon={<span className={ClassNames([
                            "fiv-ext",
                            "fiv-icon-folder-classic-open"])}
                        ></span>}
                        text={props.directory.directoryName.replace("ROOT", "Home")}
                        roleStructure={"menuitem"}
                        onClick={() => handleDirectoryClick(props.directory.directoryId)}
                        active={props.directory.directoryId === store.currentDirectory?.directoryId}
                    >
                        {props.directory.childDirectories.map(item => (
                            <DirectoryMenuItem
                                key={`MediaLibraryFolderMenu_${item.directoryId}`}
                                directory={item}
                            />
                        ))}
                    </MenuItem2>
                </If>
            </>

        );
    }

    return (
        <EwsDropdown
            icon="folder-open"
            minimal
            intent={"primary"}
        >
            <If condition={store.directoryTree !== undefined}>
                <MenuItem2
                    text={"Recycling Bin"}
                    icon={<span className={styles.RecyclingBinIcon}></span>}
                    onClick={() => store.setDirectory("RecyclingBin")}
                    active={store.isViewingRecyclingBin}
                />
                <DirectoryMenuItem directory={store.directoryTree!}/>
            </If>
        </EwsDropdown>
    )
}

export default observer(DirectoryMenu);