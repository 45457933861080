import {Checkbox, FormGroup, Icon, IOptionProps} from "@ewstheme/core";
import {IEwsInputFieldDialogProps, IEwsInputFieldPopoverProps} from "../interfaces";
import {observer} from "mobx-react";
import {useField} from "formik";
import EwsDialog from "../../ews-dialog";
import EwsPopover from "../../ews-popover";
import {ClassNames} from "../../../../core/utilities/react-utils";
import If from "../../utilities/if-component";

interface EwsCheckboxGroupProps {
    label: string;
    subLabel?: string;
    large?: boolean;
    disabled?: boolean;
    required?: boolean;
    inline?: boolean;
    columns?: number;
    scrollable?: boolean;
    options: IOptionProps[]
    formikName: string;
    withDialog?: boolean;
    dialogProps?: IEwsInputFieldDialogProps;
    withPopover?: boolean;
    popoverProps?: IEwsInputFieldPopoverProps;
}

const EwsCheckboxGroup : React.FC<EwsCheckboxGroupProps> = props => {
    const [field, meta, helpers] = useField(
        {
            name: props.formikName
        });
    
    const checkboxItemClass = () => {
        if(props.inline)
            return "ews-checkbox-group--inline";
        if(props.scrollable)
            return "ews-checkbox-group--scrollable";
        
        return "";
    }
    
    return (
        <FormGroup
            className={"ews-checkbox-group--container"}
            label={props.label}
            helperText={meta.touched && meta.error ? meta.error : undefined}
            labelInfo={props.required && "(required)"}
            intent={meta.touched && meta.error ? 'danger' : undefined}
            subLabel={props.subLabel}
            disabled={props.disabled}
        >
            <If condition={props.withDialog === true && props.dialogProps !== undefined}>
                <div className={"ews-checkbox-group--helper-dialog"}>
                    <EwsDialog
                        icon='info-sign'
                        showIconOnButton={true}
                        title={props.dialogProps?.title ?? ""}
                        intent='primary'
                        minimalButton={true}
                        buttonSize='small'
                        closeButtonText='Close'
                        closeButtonColor='primary'
                        headingColor='primary'
                    >
                        {props.dialogProps?.dialogBody ?? <></>}
                    </EwsDialog>
                </div>
            </If>
            <If condition={props.withPopover === true && props.popoverProps !== undefined}>
                <div className={"ews-checkbox-group--helper-dialog"}>
                    <EwsPopover
                        title={props.popoverProps?.title ?? ""}
                        text={props.popoverProps?.popoverText ?? ""}>
                        <Icon intent='primary' icon="info-sign"/>
                    </EwsPopover>
                </div>
            </If>
            <div className={ClassNames(["ews-checkbox-group--items", checkboxItemClass(), `${meta.touched && meta.error ? ' error' : ''}`])}>
                {props.options.map((item, index) => (
                    <div key={index} style={{width: props.inline && props.columns ? `${(100 / props.columns)}%` : undefined}}>
                        <Checkbox
                            checked={field.value.find((x: any) => x === item.value)}
                            label={item.label}
                            value={item.value}
                            disabled={props.disabled || item.disabled}
                            name={field.name}
                            onBlur={field.onBlur}
                            onChange={field.onChange}
                            large={props.large}
                        />
                    </div>
                ))}
            </div>



        </FormGroup>
    )
}

export default EwsCheckboxGroup;