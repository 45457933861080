import {observer} from "mobx-react";
import EwsForm from "../../../shared/ews-form";
import {SearchUserRequest, UserListItemResult} from "@ews-turing-models/clientmodels/dist";
import {useEwsMobxContext} from "../../../../core/stores/ews-mobx-store";
import {FormikHelpers, FormikValues} from "formik";
import EwsInput from "../../../shared/ews-form/components/ews-input";
import EwsCheckbox from "../../../shared/ews-form/components/ews-checkbox";
import {Button} from "@ewstheme/core";
import {useRootStore} from "../../../../core/stores/root-store";
import userManagementApi from "../../../../core/api/user-management-api";
import {EwsFormErrorHandler} from "../../../shared/ews-form/handlers/EwsFormErrorHandler";
import {BusyStates} from "../../../../core/utilities/busy-states";

const FilterForm: React.FC = props => {
    const {busyStore} = useRootStore();
    const store = useEwsMobxContext<UserListItemResult, SearchUserRequest>();
    let filterModel: SearchUserRequest = {
        searchTerm: "",
        isDeleted: false,
        isDisabled: false
    }

    const handleSubmit = (values: (FormikValues & SearchUserRequest), actions: FormikHelpers<FormikValues & SearchUserRequest>) => {
        busyStore.setBusyState(BusyStates.UserManagement);
        userManagementApi.getUserAccounts(values)
            .then((results) => {
                store.loadData(results.model);
                store.setFilter(values);
            })
            .catch(error => {
                EwsFormErrorHandler({error, actions});
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.UserManagement);
            });
    }

    return (
        <EwsForm<SearchUserRequest>
            initialValues={store.filter ?? filterModel}
            onSubmit={handleSubmit}
        >
            {props => (
                <>
                    <EwsInput
                        label=""
                        formikName="searchTerm"
                        placeholder="Email address or name...."
                        style={{marginBottom: 5}}
                    />
                    <EwsCheckbox
                        label="Show disabled accounts"
                        formikName="isDisabled"
                        style={{marginBottom: 0}}
                    />
                    <EwsCheckbox
                        label="Show deleted accounts"
                        formikName="isDeleted"
                        style={{marginBottom: 0}}
                    />
                    <div style={{textAlign: "right"}}>
                        <Button
                            small
                            type='reset'
                            onClick={() => {
                                store.setFilter(filterModel);
                                store.setLoadedInitial(false);
                            }}
                        >
                            Reset
                        </Button>
                        <Button
                            small
                            intent='primary'
                            type='submit'
                        >
                            Apply
                        </Button>
                    </div>
                </>
            )}
        </EwsForm>
    )
}

export default observer(FilterForm)