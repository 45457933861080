import {observer} from "mobx-react";

interface EwsSidemenuProps {
    children?: JSX.Element | JSX.Element[];
}

const EwsSidemenu : React.FC<EwsSidemenuProps> = props => {
    return (
        <div className="ews-sidebar">
            {props.children}
        </div>
    );
}

export default observer(EwsSidemenu)