import {observer} from "mobx-react";
import {useRootStore} from "../../../core/stores/root-store";
import styles from "./styles.module.scss";
import BusyIndicator from "../../shared/busy-indicator";
import If from "../../shared/utilities/if-component";
import EwsHeading from "../../shared/ews-typography/ews-heading";
import {useCallback, useEffect, useState} from "react";
import useQuery from "../../../core/hooks/use-query";
import EwsInlineNotifications from "../../shared/ews-inline-notifications";
import AuthenticationApi from "../../../core/api/authentication-api";
import {useNavigate} from "react-router-dom";
import {AppPaths} from "../../../AppPaths";
import {ConfirmResetRequest} from "@ews-turing-models/clientmodels/dist";
import EwsForm from "../../shared/ews-form";
import {FormikHelpers, FormikValues} from "formik";
import * as Yup from "yup";
import EwsPasswordInput from "../../shared/ews-form/components/ews-password-input";
import {Button} from "@ewstheme/core";
import {EwsFormErrorHandler} from "../../shared/ews-form/handlers/EwsFormErrorHandler";
import {BusyStates} from "../../../core/utilities/busy-states";

const ResetPassword: React.FC = () => {
    const {busyStore} = useRootStore();
    const [showError, setShowError] = useState(false);
    const [hasLoaded, setHasLoaded] = useState(false);
    const [changeSuccess, setChangeSuccess] = useState(false);
    const navigate = useNavigate();
    const query = useQuery();

    let model: ConfirmResetRequest = {
        token: "",
        newPassword: "",
        confirmNewPassword: ""
    }

    const validationSchema = Yup.object().shape({
        newPassword: Yup.string().required("New password is required"),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], "Password must match"),
    });

    const init = useCallback(async () => {
        AuthenticationApi.getConfirmPasswordReset({token: query.get("token")!})
            .then(result => {
                if (result.model !== true) {
                    setShowError(true);
                } else {
                    setHasLoaded(true);
                }
            })
    }, [AuthenticationApi]);

    useEffect(() => {
        if (query.get("token")) {
            busyStore.setBusyState(BusyStates.ResetPasswordForm);
            init().then(() => {
                busyStore.removeBusyState(BusyStates.ResetPasswordForm);
            });
        } else {
            setShowError(true);
        }
    }, [query]);

    const onSubmit = (values: (FormikValues & ConfirmResetRequest), actions: FormikHelpers<FormikValues & ConfirmResetRequest>) => {
        var dataToSubmit = values;
        dataToSubmit.token = query.get("token")!;

        busyStore.setBusyState(BusyStates.ResetPasswordForm);
        AuthenticationApi.postConfirmPasswordReset(dataToSubmit)
            .then(result => {
                setChangeSuccess(true);
            })
            .catch(error => {
                EwsFormErrorHandler({error, actions});
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.ResetPasswordForm);
            });
    }

    return (
        <div className={styles.loginBackground}>
            <div className={styles.loginContainer}>
                <img src="/img/Logo-Dark.png" className={styles.logo} alt="Encompass Web Systems"/>
                <div className={styles.formContainer} style={{minHeight: "100px"}}>
                    <BusyIndicator keyName={BusyStates.ResetPasswordForm}>
                        <div style={{textAlign: "center", marginTop: "1.5rem"}}>
                            <EwsHeading variant="h2" text={"Reset Password"}/>
                        </div>
                        <If condition={showError}>
                            <EwsInlineNotifications
                                title="Unable to complete password reset."
                                intent="danger"
                                icon="error"
                                message={"Invalid, tampered, or expired token. Please try again. If the issue persists, please contact your system administrator."}
                                fill
                            />
                        </If>
                        <If condition={hasLoaded && !changeSuccess}>
                            <EwsForm<ConfirmResetRequest>
                                initialValues={model}
                                validationSchema={validationSchema}
                                onSubmit={onSubmit}
                            >
                                {props => (
                                    <>
                                        <EwsPasswordInput
                                            label='New Password'
                                            required={true}
                                            showSecurityLevelBar={true}
                                            showSecurityLevelDescription={true}
                                            formikName='newPassword'
                                        />
                                        <EwsPasswordInput
                                            label='Confirm New Password'
                                            required={true}
                                            showSecurityLevelBar={false}
                                            showSecurityLevelDescription={false}
                                            skipStrengthValidation
                                            formikName='confirmNewPassword'
                                        />
                                        <Button
                                            intent='primary'
                                            disabled={!props.isValid}
                                            type='submit'
                                            fill
                                        >Change Password</Button>
                                    </>
                                )}
                            </EwsForm>
                        </If>
                        <If condition={hasLoaded && changeSuccess}>
                            <EwsInlineNotifications
                                title="Password change successfully."
                                intent="success"
                                icon="tick"
                                message={"You can now log in with your new password."}
                                fill
                            />
                        </If>
                        <a
                            onClick={() => {
                                navigate(AppPaths.Login.Url)
                            }}
                            style={{display: 'inline-block', marginTop: "10px"}}
                        >
                            Back to login
                        </a>
                    </BusyIndicator>
                </div>
            </div>
        </div>
    )
}

export default observer(ResetPassword);