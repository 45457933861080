import {observer} from "mobx-react";

interface EwsTableProps extends React.HTMLAttributes<HTMLElement> {
    children: JSX.Element | JSX.Element[]
    responsive?: boolean;
}

const EwsTable : React.FC<EwsTableProps> = ({children, responsive, ...rest}) => {
    return (
        <div className={`ews-table-container ${responsive ? "ews-table-responsive" : ""}`} >
            <table className='ews-table' {...rest}>
                {children}
            </table>
        </div>
    )
}

export default observer(EwsTable);