import {Toaster, ToastProps} from "@ewstheme/core";
import {makeAutoObservable} from "mobx";

export class ToastStore {
    toaster: Toaster | undefined = undefined;
    
    constructor() {
        makeAutoObservable(this);
    }
    
    toastRefHandler = (ref: Toaster) => this.toaster = ref;
    
    showToast = (options: ToastProps) => this.toaster?.show(options);
}