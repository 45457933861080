import DirectoryMenu from "./menus/directory-menu";
import DirectoryBreadcrumb from "./directory-breadcrumb";
import ViewModeMenu from "./menus/view-mode-menu";
import {observer} from "mobx-react";
import styles from "../styles.module.scss";
import DirectoryViewer from "./directory-viewer";
import InlineControls from "./inline-controls";
import If from "../../../shared/utilities/if-component";
import EwsHotkeys from "../../../shared/ews-hotkeys";
import {Callout, HotkeyConfig, Intent} from "@ewstheme/core";
import {MediaLibraryDialogs, useMediaLibraryStore} from "../../../../core/stores/media-library-store";
import NewFolderDialog from "./dialogs/new-folder-dialog";
import RenameFolderDialog from "./dialogs/rename-folder-dialog";
import MoveItemsDialog from "./dialogs/move-items-dialog";
import DeleteItemsDialog from "./dialogs/delete-items-dialog";
import DeletedFolderInfoDialog from "./dialogs/deleted-folder-info-dialog";
import AddFilesDialog from "./dialogs/add-files-dialog";
import ViewContentDialog from "./dialogs/view-content-dialog";
import DeletedFileInfoDialog from "./dialogs/deleted-file-info-dialog";
import SearchMediaDialog from "./dialogs/search-media-dialog";

interface MediaLibraryExplorerProps {
    showInlineControls?: boolean;
}

const MediaLibraryExplorer: React.FC<MediaLibraryExplorerProps> = props => {
    const {store} = useMediaLibraryStore();
    
    const hotkeys : HotkeyConfig[] = [
        {
            combo: "ctrl+a",
            label: "Select all",
            global: true,
            onKeyDown(e: KeyboardEvent): any {
                e.preventDefault();
                store.selectAllItems();
            }
        },
        {
            combo: "alt+n",
            label: "New folder",
            global: true,
            onKeyDown(e: KeyboardEvent): any {
                e.preventDefault();
                store.setCurrentDialog(MediaLibraryDialogs.NewFolder);
            }
        },
        {
            combo: "ctrl+f",
            label: "Search",
            global: true,
            onKeyDown(e : KeyboardEvent): any {
                e.preventDefault();
                store.setCurrentDialog(MediaLibraryDialogs.Search);
            }
        }
    ];
    
    return (
        <EwsHotkeys hotKeys={hotkeys}>
            <If condition={store.hasErrored}>
                <Callout
                    intent={Intent.DANGER}
                    title={"Error loading media library"}
                >
                    There was an issue loading the media library. Click <a onClick={() => store.resetRequestedDirectory()}>here</a> to perform a reset to the media libraries viewing preferences. If the issue persists, please contact your system administrator. 
                </Callout>
            </If>
            <If condition={!store.hasErrored}>
                <div className={styles.DirectoryExplorer}>
                    <If condition={props.showInlineControls === true}>
                        <InlineControls/>
                    </If>
                    <div className={styles.DirectoryNavigation}>
                        <div>
                            <DirectoryMenu/>
                        </div>
                        <div className={styles.DirectoryBreadCrumb}>
                            <DirectoryBreadcrumb/>
                        </div>
                        <If condition={props.showInlineControls !== true}>
                            <div>
                                <ViewModeMenu/>
                            </div>
                        </If>
                    </div>
                    <div className={styles.DirectoryViewer}>
                        <DirectoryViewer/>
                    </div>
                </div>
                <NewFolderDialog />
                <RenameFolderDialog />
                <MoveItemsDialog />
                <DeleteItemsDialog />
                <DeletedFolderInfoDialog />
                <AddFilesDialog />
                <ViewContentDialog />
                <DeletedFileInfoDialog />
                <SearchMediaDialog />
            </If>
        </EwsHotkeys>
    )
}

export default observer(MediaLibraryExplorer);