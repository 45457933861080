import {SignalrHub} from "../utilities/signalr-hub";
import {makeAutoObservable} from "mobx";

export class SignalrStore {
    private hub: SignalrHub = new SignalrHub();
    
    messages: {message: string, usersName: string}[] = [];
    
    constructor() {
        makeAutoObservable(this);
    }
    
    init = async () => {
        this.hub.StartConnection().then();
        this.hub.Callback("receiveDemoMessage", (message: string, usersName: string) => {
            this.messages.push({message, usersName});
        });
    }
    
    sendMessage = (message: string) => {
        this.hub.Send("broadcastDemoMessage", message);
    }

    disconnect = () => {
        this.hub.StopConnection();
    }
}