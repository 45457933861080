import {IEwsInputFieldDialogProps, IEwsInputFieldPopoverProps} from "../interfaces";
import {useField} from "formik";
import {Checkbox, FormGroup, Icon} from "@ewstheme/core";
import EwsDialog from "../../ews-dialog";
import EwsPopover from "../../ews-popover";
import {observer} from "mobx-react";
import If from "../../utilities/if-component";
import {CSSProperties} from "react";

interface EwsCheckboxProps {
    label: string;
    large?: boolean;
    disabled?: boolean;
    formikName: string;
    withDialog?: boolean;
    dialogProps?: IEwsInputFieldDialogProps;
    withPopover?: boolean;
    popoverProps?: IEwsInputFieldPopoverProps;
    style?: CSSProperties;
}


const EwsCheckbox : React.FC<EwsCheckboxProps> = props => {
    const [field, meta, helpers] = useField(
        {
            name: props.formikName
        });

    return (
        <FormGroup
            className={"ews-checkbox-group--container"}
            helperText={meta.touched && meta.error ? meta.error : undefined}
            intent={meta.touched && meta.error ? 'danger' : undefined}
            style={props.style}
        >
            <If condition={props.withDialog === true && props.dialogProps !== undefined}>
                <div className={"ews-checkbox-group--helper-dialog"}>
                    <EwsDialog
                        icon='info-sign'
                        showIconOnButton={true}
                        title={props.dialogProps?.title ?? ""}
                        intent='primary'
                        minimalButton={true}
                        buttonSize='small'
                        closeButtonText='Close'
                        closeButtonColor='primary'
                        headingColor='primary'
                    >
                        {props.dialogProps?.dialogBody ?? <></>}
                    </EwsDialog>
                </div>
            </If>
            <If condition={props.withPopover === true && props.popoverProps !== undefined}>
                <div className={"ews-checkbox-group--helper-dialog"}>
                    <EwsPopover
                        title={props.popoverProps?.title ?? ""}
                        text={props.popoverProps?.popoverText ?? ""}>
                        <Icon intent='primary' icon="info-sign"/>
                    </EwsPopover>
                </div>
                
            </If>

            <Checkbox
                checked={field.value === true}
                label={props.label}
                name={field.name}
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
                disabled={props.disabled}
                large={props.large}
            />

        </FormGroup>
    )
}

export default observer(EwsCheckbox);