import {observer} from "mobx-react";
import {useRootStore} from "../../../../core/stores/root-store";
import EwsGrid from "../../../shared/ews-grid/components/ews-grid";
import EwsGridItem from "../../../shared/ews-grid/components/ews-grid-item";
import EwsHeading from "../../../shared/ews-typography/ews-heading";

const UserRoleDetails : React.FC = () => {
    const {lookupStore} = useRootStore();
    return (
        <EwsGrid>
            {lookupStore.userRoles?.map((item) => (
                <EwsGridItem span={12} key={`userRoleDetails_${item.id}`}>
                    <div style={{marginBottom: '1rem'}}>
                        <EwsHeading variant='h6' text={item.name}/>
                        <span>{item.description}</span>
                    </div>
                </EwsGridItem>
            ))}
        </EwsGrid>
    )
}

export default observer(UserRoleDetails);