import {Accept, FileError} from "react-dropzone";
import {FormatBytes} from "../../../../core/utilities/react-utils";
import accepts from "attr-accept";

const maxFileSize = 104857600;

export const fileValidator: (<T extends File>(file: T) => (FileError | FileError[] | null)) = (file: File) => {
    if (file.size > maxFileSize) {
        return {
            code: "filesize-too-large",
            message: `The file size is larger than ${FormatBytes(maxFileSize)}.`
        }
    }
    if(file.type.toLowerCase().startsWith("audio/")){
        return {
            code: "filetype-is-audio",
            message: "To add audio files, please host the file on a audio streaming service like SoundCloud and use an embed code to use on your site."
        }
    }
    if(file.type.toLowerCase().startsWith("video/")){
        return {
            code: "filetype-is-audio",
            message: "To add video files, please host the file on a video streaming service like YouTube and use an embed code to use on your site."
        }
    }

    const acceptedFiles = acceptPropAsAcceptAttr(acceptedFileTypes);
    
    if (acceptedFiles){
        if(!accepts(file, acceptedFiles)){
            return {
                code: "filetype-is-invalid",
                message: "File type is not valid for upload."
            }
        }
    }
    
    return null;
}

const acceptPropAsAcceptAttr = (accept: Accept) => {
    const emptyArray: string[] = [];
    
    if (isDefined(accept)) {
        
        return Object.entries(accept)
            .reduce((a, [mimeType, ext]) => [...a, mimeType, ...ext], emptyArray)
            // Silently discard invalid entries as pickerOptionsFromAccept warns about these
            .filter((v) => isMIMEType(v) || isExt(v))
            .join(",");
    }

    return undefined;
}

const isDefined = (value: any) => {
    return value !== undefined && value !== null;
}

const isMIMEType = (v: any) => {
    return (
        v === "audio/*" ||
        v === "video/*" ||
        v === "image/*" ||
        v === "text/*" ||
        /\w+\/[-+.\w]+/g.test(v)
    );
}
const isExt = (v: any) => {
    return /^.*\.[\w]+$/.test(v);
}

export const acceptedFileTypes: Accept = {
    "text/csv":[".csv"],
    "application/msword":[".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document":[".docx"],
    "application/vnd.oasis.opendocument.text":[".odt"],
    "application/pdf":[".pdf"],
    "application/rtf":[".rtf"],
    "text/plain":[".txt"],
    "image/gif":[".gif"],
    "image/jpeg":[".jpeg", ".jpg"],
    "image/svg+xml":[".svg"],
    "image/tiff":[".tif",".tiff"],
    "image/png":[".png"],
    "image/bmp":[".bmp"],
    "image/webp":[".webp"],
    "audio/aac":[".aac"],
    "audio/aiff":[".aiff"],
    "audio/x-flac":[".flac"],
    "audio/m4a":[".m4a"],
    "audio/mpeg":[".mp3"],
    "audio/ogg":[".ogg"],
    "audio/wav":[".wav"],
    "audio/x-ms-wma":[".wma"],
    "audio/webm":[".weba"],
    "video/3gpp":[".3gp"],
    "video/x-msvideo":[".avi"],
    "video/x-flv":[".flv"],
    "video/x-m4v":[".m4v"],
    "video/x-matroska":[".mkv"],
    "video/quicktime":[".mov"],
    "video/mp4":[".mp4"],
    "video/mpeg":[".mpeg", ".mpg"],
    "video/webm":[".webm"],
    "video/x-ms-wmv":[".wmv"],
    "application/epub+zip":[".epub"],
    "application/zip":[".zip"],
    "application/vnd.ms-powerpoint":[".ppt"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation":[".pptx"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/postscript": [".ai"],
    "application/collada+xml": [".dae"],
    "image/x-dwg": [".dwg"],
    "application/octet-stream":[".fbx",".vss"],
    "image/heif":[".heif"],
    "application/x-ibooks+zip": [".ibooks"],
    "application/msproject": [".mpp"],
    "image/vnd.adobe.photoshop": [".psd"],
    "application/vnd.visio.xml": [".vdx"],
    "application/vnd.visio":[".vsd"]
}