import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {createBrowserHistory} from "history";
import EwsRouter from "./components/shared/ews-router";
import {rootStore, RootStoreContext} from './core/stores/root-store';

export const history = createBrowserHistory();

ReactDOM.render(
  <>
      <RootStoreContext.Provider value={rootStore}>
          <EwsRouter history={history}>
              <App />
          </EwsRouter>
      </RootStoreContext.Provider>
  </>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
