import { ContentItems } from "@ews-turing-models/clientmodels/dist";
import {observer} from "mobx-react";
import {ISelectedItem, SelectedItemType, useMediaLibraryStore} from "../../../../core/stores/media-library-store";
import {useState} from "react";
import If from "../../../shared/utilities/if-component";
import styles from "../styles.module.scss";
import {
    ApiAsset,
    ClassNames,
    dateTimeFormats,
    FormatBytes,
    FormatDateTime,
    ToTitleCase
} from "../../../../core/utilities/react-utils";
import {getIconClassFromExtension} from "../helpers/file-icon-helpers";
import {Checkbox} from "@ewstheme/core";
import EwsBgImage from "../../../shared/ews-bg-image";

interface ContentItemProps {
    item: ContentItems;
}

const ContentItem : React.FC<ContentItemProps> = props => {
    const {store} = useMediaLibraryStore();
    const [hovering, setHovering] = useState(false);

    const isSelected = store.selectedItems.find(x =>
        x.id == props.item.mediaId &&
        x.type === SelectedItemType.File) !== undefined;

    const onSelectionMade = () => {
        const selectedItem : ISelectedItem = {
            id: props.item.mediaId,
            type: SelectedItemType.File
        }
        if(isSelected){
            store.removeSelectedItem(selectedItem);
        } else {
            store.addSelectedItem(selectedItem);
        }
    }

    const onSelectionClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
    }
    
    return (
        <>
            <If condition={store.viewMode === "grid-view"}>
                <div
                    className={styles.FileGridContainer}
                    onMouseEnter={() => setHovering(true)}
                    onMouseLeave={() => setHovering(false)}
                >
                    <If condition={props.item.mediaType === "Image"}>
                        <div className={styles.FileImage}>
                            <EwsBgImage url={ApiAsset(`/images/150xany/${props.item.mediaId}`)} />
                        </div>
                    </If>
                    <If condition={props.item.mediaType === "Document"}>
                        <div className={ClassNames(
                            [
                                styles.FileIcon
                            ]
                        )}
                        >
                            <span className={ClassNames(
                                [
                                    "fiv-cla",
                                    getIconClassFromExtension(props.item.fileExtension?.toLowerCase() ?? "")
                                ])}
                            />
                        </div>
                    </If>
                    <If condition={props.item.mediaType === "EmbeddedObject"}>
                        <div className={ClassNames(
                            [
                                styles.FileIcon
                            ]
                        )}
                        >
                            <span className={ClassNames(
                                [
                                    "fiv-cla",
                                    getIconClassFromExtension("html")
                                ])}
                            />
                        </div>
                    </If>
                    <div className={ClassNames(
                        [
                            styles.FileName
                        ]
                    )}
                    >
                        {props.item.title}
                    </div>
                    <If condition={hovering || isSelected}>
                        <div
                            className={styles.Selector}
                            onClick={onSelectionClick}
                        >
                            <Checkbox
                                checked={isSelected}
                                onChange={onSelectionMade}
                            />
                        </div>
                    </If>
                </div>
            </If>
            <If condition={store.viewMode === "list"}>
                <div className={styles.viewRow}>
                    <div
                        className={styles.SelectorCell}
                        onClick={onSelectionClick}
                    >
                        <Checkbox
                            checked={isSelected}
                            onChange={onSelectionMade}
                        />
                    </div>
                    <div className={styles.IconCell}>
                        <span className={ClassNames(
                            [
                                "fiv-cla",
                                getIconClassFromExtension(props.item.mediaType === "EmbeddedObject" ? "html" : props.item.fileExtension?.toLowerCase() ?? "")
                            ])}
                        />
                    </div>
                    <div className={styles.TitleCell}>{props.item.title}</div>
                    <div className={styles.MediaTypeCell}>
                        {ToTitleCase(props.item.mediaType)}
                    </div>
                    <div className={styles.FileSizeCell}>
                        <If condition={props.item.fileSize !== null}>
                            {FormatBytes(props.item.fileSize)}
                        </If>
                    </div>
                    <div className={styles.UploadDateCell}>
                        {FormatDateTime(props.item.uploadDate, dateTimeFormats.ShortDate)}
                    </div>
                </div>
            </If>
        </>
    );
}

export default observer(ContentItem);