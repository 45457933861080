import {observer} from "mobx-react";

interface IfProps {
    condition: boolean;
    children: string | JSX.Element | JSX.Element[];
}

const If : React.FC<IfProps> = props => {
    if(!props.condition) {
        return null;
    }
    
    return <>{props.children}</>;
}

export default observer(If);