import {ApiResult, UserLoginRequest, UserLoginResult, UserProfileResult } from "@ews-turing-models/clientmodels/dist"
import {makeAutoObservable, reaction, runInAction} from "mobx";
import {history} from "../../index";
import AuthenticationApi from "../api/authentication-api";
import {rootStore} from "./root-store";

const jwtKey = "jwt";
const refreshTokenKey = "refreshToken";
const tenantIdKey = "tenantId";

export class AuthenticationStore {
    token: string | null = window.localStorage.getItem(jwtKey);
    refreshToken: string | null = window.localStorage.getItem(refreshTokenKey);
    tenantId: string | null = window.localStorage.getItem(tenantIdKey);
    userAccount: UserProfileResult | null = null;

    constructor() {
        makeAutoObservable(this);
        
        reaction(
            () => this.token,
            token => {
                if (token) {
                    window.localStorage.setItem(jwtKey, token);
                } else {
                    window.localStorage.removeItem(jwtKey);
                }
            }
        );

        reaction(
            () => this.refreshToken,
            refreshToken => {
                if (refreshToken) {
                    window.localStorage.setItem(refreshTokenKey, refreshToken);
                } else {
                    window.localStorage.removeItem(refreshTokenKey);
                }
            }
        );

        reaction(
            () => this.tenantId,
            tenantId => {
                if (tenantId) {
                    window.localStorage.setItem(tenantIdKey, tenantId);
                } else {
                    window.localStorage.removeItem(tenantIdKey);
                }
            }
        );
    }

    get isLoggedIn() {
        return !!this.userAccount && !!this.tenantId;
    }

    setToken = (token: string | null) => {
        this.token = token;
    }

    setRefreshToken = (refreshToken: string | null) => {
        this.refreshToken = refreshToken;
    }

    setTenantId = (tenantId: string | null) => {
        this.tenantId = tenantId;
    }
    
    login = async (model: UserLoginRequest) => {
        try {
            const loginResult: ApiResult<UserLoginResult> = await AuthenticationApi.login(model);
            runInAction(() => {
                this.setToken(loginResult.model.jwtToken);
                this.setRefreshToken(loginResult.model.refreshToken);
                this.userAccount = loginResult.model.userProfile;
                history.push("/");
            });
        } catch (error) {
            throw error;
        }
    }
    
    logout = async () => {
        try {
            await AuthenticationApi.logout();
        } catch (error){
            console.log(error);
        }
        
        rootStore.signalrStore.disconnect();
        
        runInAction(() => {
            this.setToken(null);
            this.setRefreshToken(null);
            this.setTenantId(null);
            this.userAccount = null;
        });
        history.push("/");
    }
    
    refreshLogin = async () => {
        try {
            const refreshResult: ApiResult<UserLoginResult> = await AuthenticationApi.refreshToken();
            runInAction(() => {
                this.setToken(refreshResult.model.jwtToken);
                this.setRefreshToken(refreshResult.model.refreshToken);
                this.userAccount = refreshResult.model.userProfile;
                //history.push("/");
            });
        } catch (error){
            console.log(error);
        }
    }
}