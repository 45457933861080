import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import {FormGroup, Icon, InputGroup, MaybeElement} from "@ewstheme/core";
import {IEwsInputFieldDialogProps, IEwsInputFieldPopoverProps} from "../interfaces";
import uuid from "react-uuid";
import EwsDialog from "../../ews-dialog";
import EwsPopover from "../../ews-popover";
import {observer} from "mobx-react";
import If from "../../utilities/if-component";
import {IsNullOrWhiteSpace} from "../../../../core/utilities/react-utils";

interface EwsReadonlyInputProps {
    label: string;
    placeholder?: string;
    subLabel?: string;
    subLabelPlacement?: 'top' | 'bottom';
    size?: "small" | "regular" | "large";
    value: string;
    icon?: BlueprintIcons_16Id | MaybeElement;
    withDialog?: boolean;
    dialogProps?: IEwsInputFieldDialogProps;
    withPopover?: boolean;
    popoverProps?: IEwsInputFieldPopoverProps;
}
const EwsReadonlyInput : React.FC<EwsReadonlyInputProps> = ({subLabelPlacement = "top", ...props}) => {
    const componentUUID = uuid();

    const errorIcon = (<div><Icon icon="error" intent="danger" /></div>)


    return (
        <FormGroup
            className={"ews-input-field--container"}
            label={props.label}
            subLabel={subLabelPlacement === 'top' ? props.subLabel : undefined}
            labelFor={componentUUID}
        >
            <If condition={props.withDialog === true && props.dialogProps !== undefined}>
                <div className={"ews-input-field--helper-dialog"}>
                    <EwsDialog
                        icon='info-sign'
                        showIconOnButton={true}
                        title={props.dialogProps?.title ?? ""}
                        intent='primary'
                        minimalButton={true}
                        buttonSize='small'
                        closeButtonText='Close'
                        closeButtonColor='primary'
                        headingColor='primary'
                    >
                        {props.dialogProps?.dialogBody ?? <></>}
                    </EwsDialog>
                </div>
            </If>
            <If condition={props.withPopover === true && props.popoverProps !== undefined}>
                <div className={"ews-input-field--helper-dialog"}>
                    <EwsPopover
                        title={props.popoverProps?.title ?? ""}
                        text={props.popoverProps?.popoverText ?? ""}>
                        <Icon intent='primary' icon="info-sign"/>
                    </EwsPopover>
                </div>
            </If>


            <InputGroup
                className={"ews-input-field"}
                id={componentUUID}
                placeholder={props.placeholder}
                small={props.size == 'small'}
                large={props.size == 'large'}
                disabled={true}
                fill
                leftIcon={props.icon}
                value={props.value}
            />
            <If condition={IsNullOrWhiteSpace(props.subLabel) && subLabelPlacement === 'bottom'}>
                <div className="bp4-form-group-sub-label">{props.subLabel}</div>
            </If>
        </FormGroup>
    )
}

export default observer(EwsReadonlyInput);