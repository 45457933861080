import {observer} from "mobx-react";
import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import {Button, Classes, Intent, MaybeElement, Menu} from "@ewstheme/core";
import {Popover2} from "@ewstheme/popover2";

interface EwsDropdownProps {
    text?: string;
    icon?: BlueprintIcons_16Id | MaybeElement;
    intent?: Intent;
    minimal?: boolean;
    children: JSX.Element | JSX.Element[]; 
}

const EwsDropdown : React.FC<EwsDropdownProps> = props => {
    const theMenu = (
        <Menu>
            {props.children}
        </Menu>
    )
    
    return (
        <Popover2 placement="bottom-start" content={theMenu}>
            <Button
                icon={props.icon}
                text={props.text}
                rightIcon="caret-down"
                intent={props.intent}
                minimal={props.minimal}
            />
        </Popover2>
    )
}

export default observer(EwsDropdown);