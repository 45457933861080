import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import {Alignment, Button, Classes, Dialog, Intent, MaybeElement} from "@ewstheme/core";
import {forwardRef, Ref, useCallback, useImperativeHandle, useState} from "react";
import {observer} from "mobx-react";
import {ClassNames} from "../../../core/utilities/react-utils";
import If from "../utilities/if-component";
import * as React from "react";

interface EwsDialogProps {
    icon: BlueprintIcons_16Id | MaybeElement;
    showIconOnButton?: boolean;
    title: string;
    buttonText?: string;
    intent? : Intent | undefined;
    headingColor? : Intent | undefined;
    minimalButton?: boolean;
    fillButton?: boolean;
    buttonSize?: 'small' | 'regular' | 'large';
    buttonAlignText?: Alignment;
    closeButtonEvent?: () => void;
    closeButtonText?: string;
    closeButtonColor? : Intent | undefined;
    actionButtonEvent?: () => void;
    actionButtonText?: string;
    actionButtonColor? : Intent | undefined;
    actionButtonDisabled?: boolean;
    shouldCloseOnAction?: boolean;
    children: JSX.Element | JSX.Element[];
}

export interface EwsDialogForwardedRef {
    closeDialog: () => void;
}

const EwsDialog = forwardRef((props: EwsDialogProps, ref?: Ref<EwsDialogForwardedRef>) => {
    const [isOpen, setIsOpen] = useState(false);
    
    useImperativeHandle(ref, () => ({
        closeDialog() {
            setIsOpen(false);
        }
    }))
    
    const handleOpenButtonClick = () => {
        setIsOpen(true);
    };
    const handleCloseButtonClick = useCallback(() => {
        setIsOpen(false);
        if (props.closeButtonEvent) props.closeButtonEvent();
    },[]);
    const handleActionButtonClick = useCallback(() => {
        if (props.shouldCloseOnAction) setIsOpen(false);
        if(props.actionButtonEvent) props.actionButtonEvent();
    }, []);
    
    const dialogClass = () => {
        switch(props.headingColor){
            case Intent.PRIMARY:
                return "ews-dialog--primary";
            case Intent.SUCCESS:
                return "ews-dialog--success";
            case Intent.WARNING:
                return "ews-dialog--warning";
            case Intent.DANGER:
                return "ews-dialog--danger";
            default:
                return "";
        }
    }

    return (
        <>
            <Button
                onClick={handleOpenButtonClick}
                text={props.buttonText}
                minimal={props.minimalButton}
                small={props.buttonSize === 'small'}
                large={props.buttonSize === 'large'}
                icon={props.showIconOnButton && props.icon}
                intent={props.intent}
                fill={props.fillButton}
                alignText={props.buttonAlignText}
                type="button"
            />
            <Dialog
                className={ClassNames(["ews-dialog", dialogClass()])}
                isOpen={isOpen}
                onClose={handleCloseButtonClick}
                icon={props.icon}
                title={props.title}
            >
                <div className={Classes.DIALOG_BODY}>
                    {props.children}
                </div>
                <If condition={props.closeButtonText !== undefined || props.actionButtonText !== undefined}>
                    <div className={Classes.DIALOG_FOOTER}>
                        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                            <If condition={props.closeButtonText !== undefined}>
                                <Button
                                    type="button"
                                    onClick={handleCloseButtonClick}
                                    intent={props.closeButtonColor}
                                >{props.closeButtonText}</Button>
                            </If>
                            <If condition={props.actionButtonText !== undefined}>
                                <Button
                                    type="button"
                                    intent={props.actionButtonColor}
                                    onClick={handleActionButtonClick}
                                    disabled={props.actionButtonDisabled}
                                >{props.actionButtonText}</Button>
                            </If>
                        </div>
                    </div>
                </If>
            </Dialog>
        </>
    )
});

export default observer(EwsDialog);