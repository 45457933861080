import {observer} from "mobx-react";

interface EwsGridProps {
    children?: JSX.Element | JSX.Element[]
    fluid?: boolean;
    spacing?: 1 | 2 | 3 | 4 | 5;
    alignment?: "start" | "center" | "end"
}

const EwsGrid : React.FC<EwsGridProps> = ({fluid = true, spacing = 3, alignment = "start", ...props}) => {
    let spacingClassName = `ews-gridspacing--${spacing}`;
    let alignmentClassName = `ews-gridrow--align-${alignment}`

    return (
        <div className={`ews-gridcontainer ${spacingClassName}`}>
            <div className={`${fluid ? 'ews-grid--fluid' : 'ews-grid--fixed'}`}>
                <div className={`ews-gridrow ${alignmentClassName}`}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default observer(EwsGrid);