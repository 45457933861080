import { ApiResult, CreateDirectoryRequest,
    DeleteRestoreMediaItemsRequest, EditDirectoryRequest, MediaDirectoryListItemResult, MediaDirectoryTreeResult,
    MediaItemResult, MoveDirectoryRequest, MoveFileRequest, AddEmbeddedItemRequest, UpdateMediaItemRequest, DeletedMediaDirectoryTreeResult, PermanentlyDeleteRequest, MediaStorageInfoResult, MediaStorageInfoByCategoryResult, SearchResult, MediaIndexResultItem, SearchRequest } from "@ews-turing-models/clientmodels/dist";
import ApiUtil from "../utilities/api-util";
import moment from "moment";

const ApiUrl = 'MediaLibrary/';

const MediaLibraryApi = {
    loadDirectory: (model: {directoryId?: string, showHidden?: Boolean}) => ApiUtil.multipleGet([
        {url: `${ApiUrl}GetDirectoryTree`},
        {url: `${ApiUrl}GetFolder`, params: model}
    ]).then(results => {
        return {
            directoryTree: results[0] as ApiResult<MediaDirectoryTreeResult>,
            directory: results[1] as ApiResult<MediaDirectoryListItemResult>
        }
    }),
    loadRecyclingBin: () => ApiUtil.multipleGet([
        {url: `${ApiUrl}GetDirectoryTree`},
        {url: `${ApiUrl}GetRecyclingBin`}
    ]).then(results => {
        return {
            directoryTree: results[0] as ApiResult<MediaDirectoryTreeResult>,
            directory: results[1] as ApiResult<MediaDirectoryListItemResult>
        }
    }),
    getDirectoryTree: () => ApiUtil.get<ApiResult<MediaDirectoryTreeResult>>(`${ApiUrl}GetDirectoryTree`),
    getFolder: (model: {directoryId?: string, showHidden?: Boolean}) => ApiUtil.get<ApiResult<MediaDirectoryListItemResult>>(`${ApiUrl}GetFolder`, model),
    createDirectory: (model: CreateDirectoryRequest) => ApiUtil.post<ApiResult<MediaDirectoryListItemResult>>(`${ApiUrl}CreateDirectory`, model),
    editDirectory: (model: EditDirectoryRequest) => ApiUtil.post<ApiResult<MediaDirectoryListItemResult>>(`${ApiUrl}EditDirectory`, model),
    moveDirectory: (model: MoveDirectoryRequest) => ApiUtil.post<ApiResult<MediaDirectoryListItemResult>>(`${ApiUrl}MoveDirectory`, model),
    moveFile: (model: MoveFileRequest) => ApiUtil.post<ApiResult<MediaDirectoryListItemResult>>(`${ApiUrl}MoveFile`, model),
    deleteDirectories: (model: DeleteRestoreMediaItemsRequest) => ApiUtil.post<ApiResult<boolean>>(`${ApiUrl}DeleteDirectories`, model),
    getDeletedFolder: (model: {directoryId: string}) => ApiUtil.get<ApiResult<MediaDirectoryListItemResult>>(`${ApiUrl}GetDeletedFolder`, model),
    uploadFile: (model: FormData) => ApiUtil.postStream<ApiResult<MediaItemResult>>(`${ApiUrl}UploadFile`, model),
    bulkFiles: (model: {mediaIds: string[]}) => ApiUtil.postDownload(`${ApiUrl}BulkFiles`, `${moment(new Date()).format("YYYYMMDD-hhmmss")}.zip`, model),
    deleteMediaItems: (model: DeleteRestoreMediaItemsRequest) => ApiUtil.post<ApiResult<boolean>>(`${ApiUrl}DeleteMediaItems`, model),
    addEmbedCode: (model: AddEmbeddedItemRequest) => ApiUtil.post<ApiResult<MediaItemResult>>(`${ApiUrl}AddEmbedCode`, model),
    getMediaItem: (model: {mediaId: string}) => ApiUtil.get<ApiResult<MediaItemResult>>(`${ApiUrl}GetMediaItem`, model),
    updateMediaItem: (model: UpdateMediaItemRequest) => ApiUtil.post<ApiResult<MediaItemResult>>(`${ApiUrl}UpdateMediaItem`, model),
    getDeletedFolderTree: (model: {directoryId: string}) => ApiUtil.get<ApiResult<DeletedMediaDirectoryTreeResult>>(`${ApiUrl}GetDeletedFolderTree`, model),
    restoreDirectories: (model: DeleteRestoreMediaItemsRequest) => ApiUtil.post<ApiResult<boolean>>(`${ApiUrl}RestoreDirectories`, model),
    permanentlyDeleteFolder: (model: PermanentlyDeleteRequest) => ApiUtil.post<ApiResult<boolean>>(`${ApiUrl}PermanentlyDeleteFolder`, model),
    restoreMediaItems: (model: DeleteRestoreMediaItemsRequest) => ApiUtil.post<ApiResult<boolean>>(`${ApiUrl}RestoreMediaItems`, model),
    permanentlyDeleteFile: (model: PermanentlyDeleteRequest) => ApiUtil.post<ApiResult<boolean>>(`${ApiUrl}PermanentlyDeleteFile`, model),
    getStorageInfo: () => ApiUtil.get<ApiResult<MediaStorageInfoResult>>(`${ApiUrl}GetStorageInfo`),
    getStorageInfoByFileCategory: () => ApiUtil.get<ApiResult<MediaStorageInfoByCategoryResult[]>>(`${ApiUrl}GetStorageInfoByFileCategory`),
    search: (model: SearchRequest) => ApiUtil.get<SearchResult<MediaIndexResultItem>>(`${ApiUrl}Search`, model)
};

export default MediaLibraryApi;