import {observer} from "mobx-react";
import {Icon, Intent, MaybeElement} from "@ewstheme/core";
import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import {useState} from "react";
import {ClassNames} from "../../../core/utilities/react-utils";
import If from "../utilities/if-component";

interface EwsInlineNotificationsProp {
    intent?: Intent;
    icon?: BlueprintIcons_16Id | MaybeElement;
    title: string;
    message: string;
    dismissible?: boolean;
    fill?: boolean;
}

const EwsInlineNotifications : React.FC<EwsInlineNotificationsProp> = props => {
    const [visible, setVisible] = useState(true);

    const handleDismiss = () => {
        if(props.dismissible){
            setVisible(false);
        }
    }

    return (
        <If condition={visible}>
            <div className={"ews-inline-notification--wrapper"}>

                <div className={ClassNames(["ews-inline-notification", `${props.intent ? props.intent : ''}`, `${props.fill ? 'fill' : ''}`])}>
                    {props.dismissible && (
                        <button onClick={handleDismiss}>
                            <Icon icon='cross'/>
                        </button>
                    )}

                    <If condition={props.icon !== undefined}>
                        <div className={"ews-inline-notification--icon"}>
                            <Icon icon={props.icon} size={40} />
                        </div>
                    </If>
                    <div className={"ews-inline-notification--text"}>
                        <h3>{props.title}</h3>
                        <span>{props.message}</span>
                    </div>
                </div>

            </div>
        </If>        
    )
}

export default observer(EwsInlineNotifications)