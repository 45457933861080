import {observer} from "mobx-react";
import {useRootStore} from "../../../../../core/stores/root-store";
import {MediaLibraryDialogs, SelectedItemType, useMediaLibraryStore} from "../../../../../core/stores/media-library-store";
import {ClassNames} from "../../../../../core/utilities/react-utils";
import {Button, Classes, Dialog, Intent, Tree, TreeNodeInfo} from "@ewstheme/core";
import {MediaDirectoryTreeResult} from "@ews-turing-models/clientmodels/dist";
import {useCallback, useEffect, useReducer, useState} from "react";
import {cloneDeep} from "lodash";
import styles from "../../styles.module.scss";
import {BusyStates} from "../../../../../core/utilities/busy-states";

type NodePath = number[];

type TreeAction =
    | { type: "SET_IS_EXPANDED"; payload: { path: NodePath; isExpanded: boolean } }
    | { type: "DESELECT_ALL" }
    | { type: "REFRESH"; payload: { nodes: TreeNodeInfo[]} }
    | { type: "SET_SELECTED"; payload: { path: NodePath }};

function forEachNode(nodes: TreeNodeInfo[] | undefined, callback: (node: TreeNodeInfo) => void) {
    if (nodes === undefined) {
        return;
    }

    for (const node of nodes) {
        callback(node);
        forEachNode(node.childNodes, callback);
    }
}

function forNodeAtPath(nodes: TreeNodeInfo[], path: NodePath, callback: (node: TreeNodeInfo) => void) {
    callback(Tree.nodeFromPath(path, nodes));
}

function treeReducer(state: TreeNodeInfo[], action: TreeAction) {
    switch (action.type) {
        case "DESELECT_ALL":
            const newState1 = cloneDeep(state);
            forEachNode(newState1, node => (node.isSelected = false));
            return newState1;
        case "SET_IS_EXPANDED":
            const newState2 = cloneDeep(state);
            forNodeAtPath(newState2, action.payload.path, node => (
                node.isExpanded = action.payload.isExpanded,
                node.icon = <span className={ClassNames([
                    "fiv-ext",
                    `fiv-icon-folder-classic${ action.payload.isExpanded ? '-open' : ''}`,
                    styles.DirectoryTreeIcon
                ])}></span>
            ));
            return newState2;
        case "REFRESH":
            return action.payload.nodes;
        case "SET_SELECTED":
            const newState3 = cloneDeep(state);
            forEachNode(newState3, node => node.isSelected = false);
            forNodeAtPath(newState3, action.payload.path, node => (node.isSelected = true))
            return newState3
        default:
            return state;
    }
}

const MoveItemsDialog: React.FC = () => {
    const {busyStore, toastStore} = useRootStore();
    const {store} = useMediaLibraryStore();
    const [selectedDirectoryId, setSelectedDirectoryId] = useState<string | undefined>(undefined);

    const mapDirectoryTreeItem = (item: MediaDirectoryTreeResult) => {
        const output: TreeNodeInfo = {
            id: item.directoryId,
            label: <span className={ClassNames([
                item.isHidden ? styles.Hidden : "",
                item.directoryId === store.currentDirectory?.directoryId ? styles.Muted : ""
            ])}>{item.directoryName.replace("ROOT","Home")}</span>,
            hasCaret: item.childDirectories.length > 0,
            icon: <span className={ClassNames([
                "fiv-ext",
                `fiv-icon-folder-classic${item.directoryName === "ROOT" ? "-open" : ""}`,
                styles.DirectoryTreeIcon,
                item.isHidden ? styles.Hidden : ''
            ])}></span>,
            childNodes: item.childDirectories.map((subItem: MediaDirectoryTreeResult) => {
                return mapDirectoryTreeItem(subItem)
            }),
            isExpanded: item.directoryName === "ROOT",
            isSelected: item.directoryId === selectedDirectoryId,
            disabled: store.selectedItems.some(x => x.id === item.directoryId && x.type === SelectedItemType.Folder)
        }

        return output;
    }
    
    const initialState: TreeNodeInfo[] = store.directoryTree ? [
        mapDirectoryTreeItem(store.directoryTree)
    ] : [];

    const [nodes, dispatch] = useReducer(treeReducer, initialState);

    const handleTreeUpdate = () => {
        if(store.directoryTree){
            dispatch({
                payload: {nodes: [mapDirectoryTreeItem(store.directoryTree)]},
                type: "REFRESH"
            });
        }
    }

    useEffect(() => {
        handleTreeUpdate();
        setSelectedDirectoryId(undefined);
    }, [store.currentDialog]);
    
    const handleNodeClick = useCallback(
        (node: TreeNodeInfo, nodePath: NodePath, e: React.MouseEvent<HTMLElement>) => {
            
            if(store.currentDirectory?.directoryId !== node.id.toString()){
                setSelectedDirectoryId(node.id.toString());
                dispatch({
                    payload: { path: nodePath },
                    type: "SET_SELECTED"
                });
            }
        },
        [],
    );

    const handleNodeCollapse = useCallback((_node: TreeNodeInfo, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: false },
            type: "SET_IS_EXPANDED",
        });
    }, []);

    const handleNodeExpand = useCallback((_node: TreeNodeInfo, nodePath: NodePath) => {
        dispatch({
            payload: { path: nodePath, isExpanded: true },
            type: "SET_IS_EXPANDED",
        });
    }, []);
    
    const handleClose = () => {
        store.closeDialog();
    }
    
    const handleSubmit = () => {
        busyStore.setBusyState(BusyStates.MediaLibrary);
        store.moveSelectedItems(selectedDirectoryId!)
            .then(() => {
                let valueClone = (" " + selectedDirectoryId!).slice(1);
                handleClose();
                busyStore.removeBusyState(BusyStates.MediaLibrary);
                toastStore.showToast({
                    message: `Successfully moved selected items`,
                    icon: 'tick',
                    intent: 'success'
                });
                store.setDirectory(valueClone);
            })
            .catch(error => {
                busyStore.removeBusyState(BusyStates.MediaLibrary);
                console.log(error);
                toastStore.showToast({
                    message: `An error occured whilst moving selected items`,
                    icon: 'cross',
                    intent: 'danger'
                });
            });
    }
    
    return (
        <Dialog
            className={ClassNames(["ews-dialog", "ews-dialog--primary"])}
            isOpen={store.currentDialog === MediaLibraryDialogs.MoveItems}
            icon={"add-to-folder"}
            title={"Move Items"}
            onClose={handleClose}
        >
            <div className={Classes.DIALOG_BODY}>
                <div className={styles.DirectoryTreeView}>
                    <Tree
                        className={styles.DirectoryTreeContainer}
                        contents={nodes}
                        onNodeClick={handleNodeClick}
                        onNodeExpand={handleNodeExpand}
                        onNodeCollapse={handleNodeCollapse}
                    />
                </div>
            </div>
            <div className={Classes.DIALOG_FOOTER}>
                <div className='form-action-container'>
                    <Button
                        type='button'
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        intent={Intent.PRIMARY}
                        disabled={!selectedDirectoryId}
                        type='button'
                        onClick={handleSubmit}
                    >{'Move'}</Button>
                </div>
            </div>
        </Dialog>
    )
}

export default observer(MoveItemsDialog);