import {DialogFormProps} from "../../../shared/interfaces/dialog-form-props";
import {observer} from "mobx-react";
import {useRootStore} from "../../../../core/stores/root-store";
import {useEwsMobxContext} from "../../../../core/stores/ews-mobx-store";
import {EditUserRequest, SearchUserRequest, UserListItemResult} from "@ews-turing-models/clientmodels/dist";
import {useCallback, useEffect, useMemo, useState} from "react";
import UserManagementApi from "../../../../core/api/user-management-api";
import {BusyStates} from "../../../../core/utilities/busy-states";
import * as Yup from "yup";
import BusyIndicator from "../../../shared/busy-indicator";
import EwsForm from "../../../shared/ews-form";
import {FormikHelpers, FormikValues} from "formik";
import If from "../../../shared/utilities/if-component";
import {Classes} from "@ewstheme/core";
import EwsGrid from "../../../shared/ews-grid/components/ews-grid";
import EwsGridItem from "../../../shared/ews-grid/components/ews-grid-item";
import EwsReadonlyInput from "../../../shared/ews-form/components/ews-readonly-input";
import EwsInput from "../../../shared/ews-form/components/ews-input";
import EwsPasswordInput from "../../../shared/ews-form/components/ews-password-input";
import EwsCheckboxGroup from "../../../shared/ews-form/components/ews-checkbox-group";
import UserRoleDetails from "./user-role-details";
import EwsCheckbox from "../../../shared/ews-form/components/ews-checkbox";
import EditorDialogActions from "../../../shared/ews-editor-dialog/components/editor-dialog-actions";
import userManagementApi from "../../../../core/api/user-management-api";
import {EwsFormErrorHandler} from "../../../shared/ews-form/handlers/EwsFormErrorHandler";
import EwsInlineNotifications from "../../../shared/ews-inline-notifications";
import {BusyStore} from "../../../../core/stores/busy-store";

const EditUserForm: React.FC<DialogFormProps> = props => {
    const {busyStore, lookupStore, toastStore} = useRootStore();
    const store = useEwsMobxContext<UserListItemResult, SearchUserRequest>();
    const [userData, setUserData] = useState<EditUserRequest>();

    const initUserEdit = useCallback(async () => {
        if (store.selectedItem) {
            var data = await UserManagementApi.getUpdateUserAccount({userId: store.selectedItem.id});
            setUserData(data.model);
        }
    }, [UserManagementApi]);

    useMemo(() => {
        busyStore.setBusyState(BusyStates.EditUserForm);
        initUserEdit().then(() => busyStore.removeBusyState(BusyStates.EditUserForm));
    }, []);

    const validationSchema = Yup.object().shape({
        fullName: Yup.string().required("Fullname is required"),
        confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], "Password must match"),
        roleIds: Yup.array().test('validation-check', 'At least 1 role is required', (value, context) => {
            if (!value || value.length < 1) {
                return false;
            }
            return true;
        })
    });

    const onSubmit = (values: (FormikValues & EditUserRequest), actions: FormikHelpers<FormikValues & EditUserRequest>) => {
        busyStore.setBusyState(BusyStates.UserManagement);
        userManagementApi.postUpdateUserAccount(values)
            .then(results => {
                let valuesClone = Object.assign({}, values);
                store.setLoadedInitial(false);
                props.onCloseAction();
                toastStore.showToast({
                    message: `Account successfully updated for ${valuesClone.fullName}`,
                    intent: "success",
                    icon: "tick"
                });
            })
            .catch(error => {
                EwsFormErrorHandler(error);
            })
            .finally(() => {
                busyStore.removeBusyState(BusyStates.UserManagement)
            });
    }

    if((!userData || !store.selectedItem) && !busyStore.isBusy(BusyStates.EditUserForm)) return (
        <EwsInlineNotifications
            message='Failed to retrieve the selected user account data.'
            title='Error loading data'
            icon='error'
            intent='danger'
            fill />
    )
    
    if(!userData) return null;

    return (
        <BusyIndicator keyName={BusyStates.EditUserForm}>
            <If condition={busyStore.isBusy(BusyStates.EditUserForm) === false}>
                <EwsForm<EditUserRequest>
                    initialValues={userData!}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                >
                    {formProps => (
                        <>
                            <div className={Classes.DIALOG_BODY}>
                                <EwsGrid>
                                    <EwsGridItem span={6}>
                                        <EwsReadonlyInput
                                            label="Email Address"
                                            placeholder="someone@example.com"
                                            subLabel="Email address cannot be changed once a user account has been created."
                                            subLabelPlacement="bottom"
                                            value={store.selectedItem!.emailAddress}
                                        />
                                    </EwsGridItem>

                                    <EwsGridItem span={6}>
                                        <EwsInput
                                            label='Fullname'
                                            placeholder='John Doe'
                                            required={true}
                                            formikName='fullName'
                                        />
                                    </EwsGridItem>
                                    <EwsGridItem span={6}>
                                        <EwsPasswordInput
                                            label='New Password'
                                            required={false}
                                            showSecurityLevelBar={true}
                                            showSecurityLevelDescription={true}
                                            formikName='newPassword'
                                        />
                                    </EwsGridItem>
                                    <EwsGridItem span={6}>
                                        <EwsPasswordInput
                                            label='Confirm New Password'
                                            required={false}
                                            showSecurityLevelBar={false}
                                            showSecurityLevelDescription={false}
                                            skipStrengthValidation
                                            formikName='confirmNewPassword'
                                        />
                                    </EwsGridItem>
                                    <EwsGridItem span={12}>
                                        <EwsCheckboxGroup
                                            label='Roles'
                                            required={true}
                                            inline
                                            columns={3}
                                            options={lookupStore.userRoles?.map(item => {
                                                return {value: item.id, label: item.name}
                                            }) ?? []}
                                            formikName='roleIds'
                                            withDialog
                                            dialogProps={{
                                                title: "User Roles",
                                                dialogBody: <UserRoleDetails/>
                                            }}
                                        />
                                    </EwsGridItem>
                                    <EwsGridItem span={12}>
                                        <EwsCheckbox
                                            label="Is Disabled?"
                                            formikName="isDisabled"
                                        />
                                    </EwsGridItem>
                                </EwsGrid>
                            </div>
                            <div className={Classes.DIALOG_FOOTER}>
                                <EditorDialogActions
                                    onCancelAction={() => {props.onCloseAction()}}
                                    formProps={formProps}
                                />
                            </div>
                        </>
                    )}
                </EwsForm>
            </If>
        </BusyIndicator>
    )

}

export default observer(EditUserForm);