import {observer} from "mobx-react";

export interface EwsThProps extends React.TdHTMLAttributes<HTMLElement> {
    children?: JSX.Element | JSX.Element[] | string;
}

const EwsTh : React.FC<EwsThProps> = ({children, ...rest}) => {
    return (
        <th {...rest}>
            {children}
        </th>
    )
}

export default observer(EwsTh);