import React from "react";
import {Router} from "react-router-dom";

interface EwsRouterProps {
    children: string | JSX.Element | JSX.Element[];
    history: any;
}

const EwsRouter : React.FC<EwsRouterProps> = props => {
    const [state, setState] = React.useState({
        action: props.history.action,
        location: props.history.location,
    });

    React.useLayoutEffect(() => props.history.listen(setState), [props.history]);

    return (
        <Router
            children={props.children}
            location={state.location}
            navigationType={state.action}
            navigator={props.history}
        />
    );
};

export default EwsRouter;