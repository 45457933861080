import { observer } from "mobx-react";
import {ClassNames} from "../../../../core/utilities/react-utils";
import "./ews-grid-item.scss";

interface EwsGridItemProps {
    children: JSX.Element | JSX.Element[];
    span?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
    order?: 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
}

const EwsGridItem : React.FC<EwsGridItemProps> = props => {
    const spanClass = props.span ? `ews-gridcol-${props.span}` : "";

    const orderClass = props.order ? `ews-order-${props.order}` : "";

    return (
        <div className={ClassNames(["ews-gridcol",spanClass, orderClass])}>
            {props.children}
        </div>
    )
}

export default observer(EwsGridItem);