import {observer} from "mobx-react";
import {BlueprintIcons_16Id} from "@ewstheme/icons/lib/esnext/generated/16px/blueprint-icons-16";
import {Button, Colors, Icon, IconSize, MaybeElement} from "@ewstheme/core";
import {ClassNames} from "../../../../core/utilities/react-utils";
import {useRootStore} from "../../../../core/stores/root-store";
import If from "../../utilities/if-component";
import {PreferenceSettings} from "../../../../core/utilities/preference-settings";
import {NavLink, useLocation, useNavigate} from "react-router-dom";

interface EwsSidemenuItemProps {
    text: string;
    icon: BlueprintIcons_16Id | MaybeElement;
    appPath: string;
}

const EwsSidemenuItem : React.FC<EwsSidemenuItemProps> = props => {
    const {sitePersistenceStore} = useRootStore();
    const navigate = useNavigate();
    let location = useLocation();
    
    return (
        <div className={ClassNames(
            [
                "ews-sidebar--item",
                sitePersistenceStore.getPreference(PreferenceSettings.SideBarCollapsed) === true ? "collapsed" : "",
                props.appPath === location.pathname ? "active" : ""
            ])}
             onClick={() => navigate(props.appPath)}
        >
            <Icon icon={props.icon} size={IconSize.LARGE} style={{color: Colors.PURPLE4}}/>
            <If condition={sitePersistenceStore.getPreference(PreferenceSettings.SideBarCollapsed) !== true}>
                <span className={"ews-sidebar--item-text"}>{props.text}</span>
            </If>
        </div>
    )
}

export default observer(EwsSidemenuItem);