import {observer} from "mobx-react";

export interface EwsTbodyProps extends React.HTMLAttributes<HTMLElement> {
    children: JSX.Element | JSX.Element[]
}

const EwsTbody : React.FC<EwsTbodyProps> = ({children, ...rest}) => {
    return (
        <tbody {...rest}>
            {children}
        </tbody>
    )
}

export default observer(EwsTbody);